import { createSelector } from '@reduxjs/toolkit'

export const unsubscribeSelector = state => state.unsubscribeReducer

export const getLoading = createSelector(
  unsubscribeSelector,
  res => res.loading
)

export const getUnsubscribedSuccess = createSelector(
  unsubscribeSelector,
  res => res.unsubscribedSuccess
)

export const getUnsubscribedFailed = createSelector(
  unsubscribeSelector,
  res => res.unsubscribedFailed
)

export const getObfuscatedEmail = createSelector(
  unsubscribeSelector,
  res => res.obfuscatedEmail
)

export const getObfuscatedEmailSuccess = createSelector(
  unsubscribeSelector,
  res => res.getObfuscatedEmailSuccess
)

export const getObfuscatedEmailFailed = createSelector(
  unsubscribeSelector,
  res => res.getObfuscatedEmailFailed
)

export const getAuthToken = createSelector(
  unsubscribeSelector,
  res => res.authToken
)
