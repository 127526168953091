import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { actions } from 'modules/bootstrap/BootstrapReducer'
import * as AuthSelector from 'modules/auth/AuthSelector'
import { actions as authActions } from 'modules/auth/AuthReducer'
import { trackClick } from 'modules/tracking/TrackingSaga'

const { getIsLogin, getAuthVerifying } = AuthSelector
const { openLoginModal } = authActions

const requireAuthPaths = ['/profile']

const Bootstrap = ({ children }) => {
  const dispatch = useDispatch()
  const route = window.location.pathname
  const isLoginUser = useSelector(getIsLogin)
  const isAuthVerifying = useSelector(getAuthVerifying)

  useEffect(() => {
    dispatch(actions.requestBootstrap())
  }, [dispatch])

  useEffect(() => {
    if (!isAuthVerifying && requireAuthPaths.includes(route) && !isLoginUser) {
      dispatch(openLoginModal({ route }))
    }
  }, [dispatch, isAuthVerifying, route, isLoginUser])

  useEffect(() => {
    if (isLoginUser) {
      trackClick({
        title: 'Email/Click/login/success',
        clickType: 'otp-email-click',
        userData: {
          source: 'OTP'
        }
      })
      // dispatch(trackingActions.setTrackedEmailClickLoginSuccess())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginUser])

  return children
}
Bootstrap.propTypes = {
  children: PropTypes.node
}

export default Bootstrap
