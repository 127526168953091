import React from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'react-overlays/Modal'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '../Typography'
import styles from './Modal.module.sass'

const modalStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(22, 26, 46, 0.4)',
  backdropFilter: 'blur(5px)',
  outline: 0
}

class Modal extends React.Component {
  state = {
    showModal: this.props.modalOpen,
    internalUpdate: false
  }
  static displayName = 'Modal'

  static defaultProps = {
    overlayColor: 'rgba(22, 26, 46, 0.1)'
  }

  static propTypes = {
    /** The color of the background overlay shown underneath Modal when opened */
    overlayColor: PropTypes.string,
    /** The max-height of the item */
    maxHeight: PropTypes.number,
    /** Is the modal open? */
    modalOpen: PropTypes.bool,
    /** The type of modal that's going to be used */
    modalType: PropTypes.number,
    /** The component children */
    children: PropTypes.node.isRequired,
    /** Callback when the user closes the modal */
    onDismiss: PropTypes.func,
    /** A CSS modules style object to override default theme */
    altTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /** Does it have backdrop blur? */
    hasBackdropBlur: PropTypes.bool
  }

  static getDerivedStateFromProps({ modalOpen: showModal }, state) {
    if (state.internalUpdate) {
      return { ...state, internalUpdate: false }
    } else if (showModal !== state.showModal) {
      return {
        showModal: showModal
      }
    }
    return null
  }

  decideModal = (modalStyle, showModal, type, themeStyles, other, children) => {
    const closeLabel = ''
    const bottomText = ''
    if (type === 1)
      // Interstitial
      return (
        <ModalContainer
          aria-labelledby="modal-label"
          style={modalStyle}
          show={showModal}
          className={themeStyles.interstitialContainer}
          containerClassName={styles.modalBody}
          role="textbox"
        >
          <div className={themeStyles.interstitial} {...other}>
            <Typography
              type="thumb-label"
              className={themeStyles.closeLabel}
              onClick={this.close}
            >
              {closeLabel}
            </Typography>
            <div className={themeStyles.interstitialContent}>{children}</div>
            <Typography type="greeting" className={themeStyles.bottomText}>
              {bottomText}
            </Typography>
          </div>
        </ModalContainer>
      )
    //Regular Modal
    else
      return (
        <ModalContainer
          aria-labelledby="modal-label"
          style={modalStyle}
          show={showModal}
          onHide={this.close}
          className={themeStyles.container}
          containerClassName={styles.modalBody}
          role="textbox"
        >
          <div className={themeStyles.modal} {...other}>
            <IconButton className={themeStyles.button} onClick={this.close}>
              <CloseIcon />
            </IconButton>
            <div className={themeStyles.content}>{children}</div>
          </div>
        </ModalContainer>
      )
  }

  render = () => {
    const {
      children,
      overlayColor,
      altTheme,
      modalType = 1,
      hasBackdropBlur,
      ...other
    } = this.props
    const { showModal } = this.state
    const themeStyles = { ...styles, ...altTheme }
    return (
      <>
        {this.decideModal(
          modalStyle,
          showModal,
          modalType,
          themeStyles,
          other,
          children,
          hasBackdropBlur
        )}
      </>
    )
  }

  close = () => {
    this.setState({ showModal: false, internalUpdate: true })
    typeof this.props.onDismiss === 'function' && this.props.onDismiss()
  }

  // open = () => this.setState({ showModal: true, internalUpdate: true })
}

export default Modal
