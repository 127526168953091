import { LazyLoadImage } from 'react-lazy-load-image-component'
import PropTypes from 'prop-types'
import styles from './SearchCard.module.sass'

const SearchCard = ({ thumbnail, title, description, link, authors, alt }) => {
  const isHTML = description?.startsWith('<p>')

  return (
    <div className={styles.card}>
      <div>
        <a href={link} target="_blank" rel="noreferrer">
          <LazyLoadImage
            src={thumbnail}
            className={styles.img}
            effect="opacity"
            alt={alt}
          />
        </a>
      </div>
      <div>
        <a href={link} target="_blank" rel="noreferrer">
          <p className={styles.title}>{title}</p>
        </a>
        {/* <p className={styles.description}>{description}</p> */}
        {!isHTML && (
          <p className={styles.description}>
            {description ? description + '...' : ''}
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className={styles.readmore}
            >
              read more
            </a>
          </p>
        )}
        {isHTML && (
          <span
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html:
                description.substring(-3, description.length - 4) +
                '...' +
                `<a href="` +
                link +
                `" style="font-size:12px;line-height:18px;color:#3b78c3;margin-left:5px;text-decoration:underline;cursor:pointer;" target="_blank" rel="noreferrer">read more</a>` +
                '</p>'
            }}
          />
        )}
      </div>
    </div>
  )
}

SearchCard.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  authors: PropTypes.array,
  alt: PropTypes.string
}

export default SearchCard
