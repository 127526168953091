import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormLayout from 'layouts/FormLayout'
import Svg from 'react-inlinesvg'
import Select, { createFilter } from 'react-select'
import { getLoading } from 'modules/auth/AuthSelector'
import { actions } from 'modules/optin/OptinReducer'
import {
  getIsRequesting,
  getOptinProfession,
  getOptinCountry,
  getOriOptinSpecialtyCONTENT
} from 'modules/optin/OptinSelector'
import { actions as userActions } from 'modules/user/UserReducer'
import {
  getIsProcessingRegister,
  getRegisterState,
  getRegisterFailedState
} from 'modules/user/UserSelector'
import { onMenuOpen } from 'utils'
import {
  DropdownIndicator,
  IndicatorSeparator,
  SingleValue,
  style_form as style_select
} from 'components/Filter'
import {
  variables,
  checkField,
  checkSelect,
  resetSelectElement,
  handleSelectNotice,
  resetForm,
  resetInput,
  checkError,
  checkIsSpecialtyRequired
} from './SignUpFunc'
import styles from './SignUp.module.sass'

import config from 'site.config'
const { configuration, sections } = config

const SignUp = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [focusPassword, setFocusPassword] = useState(false)
  const [country, setCountry] = useState({ label: 'Select', value: '' })
  const [errorFirstName, setErrorFirstName] = useState(' ')
  const [errorLastName, setErrorLastName] = useState(' ')
  const [errorEmail, setErrorEmail] = useState('')
  const [errorCountry, setErrorCountry] = useState('')
  const [errorProfession, setErrorProfession] = useState('')
  const [errorSpecialty, setErrorSpecialty] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [professionId, setProfessionId] = useState({
    label: 'Select',
    value: ''
  })
  const [specialtyId, setSpecialtyId] = useState({ label: 'Select', value: '' })
  const isRequesting = useSelector(getIsRequesting)
  const isProcessingRegistration = useSelector(getIsProcessingRegister)
  const countryOptions = useSelector(getOptinCountry)
  const professionOptions = useSelector(getOptinProfession)
  const specialtyOptions = useSelector(getOriOptinSpecialtyCONTENT)
  const oriOptinSpecialtyCONTENT = useSelector(getOriOptinSpecialtyCONTENT)
  const isOtpLoading = useSelector(getLoading)
  const recaptchaKey = configuration.envVars.REACT_APP_PHN_RECAPTCHA_KEY
  const registeredSuccess = useSelector(getRegisterState)
  const registeredFailed = useSelector(getRegisterFailedState)

  const title = sections?.signup?.title

  useEffect(() => {
    dispatch(actions.requestMetadata())
    dispatch(userActions.resetRegisterState())
  }, [dispatch])

  useEffect(() => {
    const recaptchaId = 'recaptcha-key'

    const loadScriptByURL = (id, url) => {
      const scriptExist = document.getElementById(id)
      if (!scriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        document.body.appendChild(script)
      }
    }

    // load the script by passing the URL
    loadScriptByURL(
      recaptchaId,
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`
    )

    return () => {
      document.getElementById(recaptchaId)?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (registeredSuccess)
      resetInput(setCountry, setProfessionId, setSpecialtyId)
  }, [registeredSuccess])

  // handle dropdown change event
  const handleSelectChange = (input, elementId, fieldname, errorFunc) => {
    if (fieldname === 'Country') setCountry(input)
    if (fieldname === 'Profession') setProfessionId(input)
    if (fieldname === 'Specialty') setSpecialtyId(input)

    handleSelectNotice(input, elementId, fieldname, errorFunc)
  }

  const signupPayload = (
    data,
    country,
    profession,
    originalSpecialty,
    specialtyContent
  ) => {
    let payload = {
      ...data,
      country: country.label,
      profession: {
        professionId: profession?.value || 0,
        professionCode: profession?.label || ''
      }
    }
    // use CONTENT specialty to signup payload
    if (originalSpecialty?.value) {
      payload.specialty = {
        specialtyId: specialtyContent?.value || 0,
        specialtyCode: specialtyContent?.label || ''
      }
      payload.specialtyLima = {
        specialtyId: specialtyContent?.value || 0,
        specialtyCode: specialtyContent?.label || ''
      }
    }
    return payload
  }

  const submitForm = token => {
    const {
      form,
      firstName,
      lastName,
      email,
      password,
      profession,
      specialtyContent
    } = variables(
      professionOptions,
      professionId,
      specialtyOptions,
      specialtyId
    )

    if (
      token &&
      country?.value &&
      profession?.value &&
      form.checkValidity() &&
      (specialtyContent?.value ||
        (professionId?.label !== 'Physician' &&
          professionId?.label !== 'Resident'))
    ) {
      const originalSpecialty = oriOptinSpecialtyCONTENT.find(
        spec => spec.value === specialtyContent?.value
      )
      let data = signupPayload(
        { firstName, lastName, email, password },
        country,
        profession,
        originalSpecialty,
        specialtyContent
      )

      if (checkIsSpecialtyRequired(professionId)) {
        if (!originalSpecialty.value) {
          setErrorSpecialty('Your Specialty is required.')
          return false
        }
        resetSelectElement(document.getElementById('inputSpecialty'))
        setErrorSpecialty('')
      }

      dispatch(userActions.requestRegisterUser(data))

      resetForm(
        setErrorFirstName,
        setErrorLastName,
        setErrorEmail,
        setErrorPassword,
        setErrorCountry,
        setErrorProfession
      )
      if (
        professionId.label === 'Physician' ||
        professionId.label === 'Resident'
      ) {
        resetSelectElement(document.getElementById('inputSpecialty'))
        setErrorSpecialty('')
      }
      return
    }

    checkError(
      country,
      setErrorFirstName,
      setErrorLastName,
      setErrorEmail,
      setErrorPassword,
      setErrorCountry
    )
    checkSelect(
      professionId,
      'inputProfession',
      'Profession',
      setErrorProfession
    )

    if (checkIsSpecialtyRequired(professionId)) {
      checkSelect(specialtyId, 'inputSpecialty', 'Specialty', setErrorSpecialty)
    } else {
      resetSelectElement(document.getElementById('inputSpecialty'))
      setErrorSpecialty('')
    }
  }

  return (
    <FormLayout
      loading={isProcessingRegistration || isOtpLoading}
      disableLottie={true}
    >
      <div className={styles.box}>
        <h3>{title}</h3>
        <p>
          Unlock exclusive access to premier educational programs and
          opportunities tailored for your practice. By signing up, you&apos;ll
          receive:
        </p>
        <ul>
          <li>CME Tracker to view and download your CME credits</li>
          <li>
            Personalized newsletters with offers to enhance your knowledge and
            skills
          </li>
          <li>
            Invitations to participate in cutting-edge education that impacts
            patient care
          </li>
          <li>Opportunities to engage with industry experts and peers.</li>
        </ul>
        <form className={styles.form}>
          <div className={styles.fieldset}>
            <label htmlFor="inputFirstName">
              First Name <span style={{ color: '#c00' }}>*</span>
            </label>
            <input
              type="text"
              id="inputFirstName"
              className={styles.focus}
              required
              maxLength="60"
              onBlur={() =>
                checkField('inputFirstName', 'First Name', setErrorFirstName)
              }
            />
            <div className={styles.err_span}>{errorFirstName}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputLastName">
              Last Name <span style={{ color: '#c00' }}>*</span>
            </label>
            <input
              type="text"
              id="inputLastName"
              className={styles.focus}
              required
              maxLength="60"
              onBlur={() =>
                checkField('inputLastName', 'Last Name', setErrorLastName)
              }
            />
            <div className={styles.err_span}>{errorLastName}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputEmail">
              Email <span style={{ color: '#c00' }}>*</span>
            </label>
            <input
              type="email"
              id="inputEmail"
              className={styles.focus}
              required
              maxLength="60"
              onBlur={() => checkField('inputEmail', 'Email', setErrorEmail)}
            />
            <div className={styles.err_span}>{errorEmail}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputPassword">
              Password <span style={{ color: '#c00' }}>*</span>
            </label>
            <input
              type="password"
              id="inputPassword"
              className={styles.focus}
              required
              maxLength="20"
              minLength="6"
              pattern="[A-Za-z0-9$!$%^&_~\)\(*\-]{6,20}"
              onFocus={() => {
                setFocusPassword(true)
                setErrorPassword('')
              }}
              onBlur={() => {
                setFocusPassword(false)
                checkField('inputPassword', 'Password', setErrorPassword)
              }}
            />
            {focusPassword && (
              <span className={styles.helpertext}>
                Minimum 6 alphanumeric characters
              </span>
            )}
            <div className={styles.err_span}>{errorPassword}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputCountry">
              Country <span style={{ color: '#c00' }}>*</span>
            </label>
            <Select
              className={styles.dropdown}
              classNamePrefix={'dropdown'}
              id="inputCountry"
              value={country}
              options={countryOptions.map(i => ({
                label: i.label,
                value: i.value
              }))}
              onChange={input =>
                handleSelectChange(
                  input,
                  'inputCountry',
                  'Country',
                  setErrorCountry
                )
              }
              onBlur={() =>
                checkSelect(country, 'inputCountry', 'Country', setErrorCountry)
              }
              onMenuOpen={() => onMenuOpen('dropdown')}
              filterOption={createFilter({ matchFrom: 'start' })}
              placeholder="Select"
              isSearchable={true}
              isClearable={false}
              isLoading={isRequesting}
              components={{
                DropdownIndicator,
                IndicatorSeparator,
                SingleValue
              }}
              styles={style_select}
              zIndex={119}
              required
            />
            <div className={styles.err_span}>{errorCountry}</div>
          </div>
          <div className={styles.fieldset}>
            <label htmlFor="inputProfession">
              Profession <span style={{ color: '#c00' }}>*</span>
            </label>
            <Select
              className={styles.dropdown}
              classNamePrefix={'dropdown'}
              id="inputProfession"
              value={professionId}
              options={professionOptions.map(i => ({
                label: i.label,
                value: i.value
              }))}
              onChange={input => {
                handleSelectChange(
                  input,
                  'inputProfession',
                  'Profession',
                  setErrorProfession
                )
                if (input.label !== 'Physician' && input.label !== 'Resident')
                  setSpecialtyId({ label: 'Select', value: '' })
              }}
              onBlur={() => {
                checkSelect(
                  professionId,
                  'inputProfession',
                  'Profession',
                  setErrorProfession
                )
              }}
              onMenuOpen={() => onMenuOpen('dropdown')}
              filterOption={createFilter({ matchFrom: 'start' })}
              placeholder="Select"
              isSearchable={true}
              isClearable={false}
              isLoading={isRequesting}
              components={{
                DropdownIndicator,
                IndicatorSeparator,
                SingleValue
              }}
              styles={style_select}
              zIndex={119}
              required
            />
            <div className={styles.err_span}>{errorProfession}</div>
          </div>
          {professionId?.label !== 'Select' && (
            <div className={styles.fieldset}>
              <label htmlFor="inputSpecialty">
                Specialty{' '}
                {(professionId.label === 'Physician' ||
                  professionId.label === 'Resident') && (
                  <span style={{ color: '#c00' }}>*</span>
                )}
              </label>
              <Select
                className={styles.dropdown}
                classNamePrefix={'dropdown'}
                id="inputSpecialty"
                value={specialtyId}
                options={specialtyOptions.map(i => ({
                  label: i.label,
                  value: i.value
                }))}
                onChange={input =>
                  handleSelectChange(
                    input,
                    'inputSpecialty',
                    'Specialty',
                    setErrorSpecialty
                  )
                }
                onBlur={() =>
                  checkSelect(
                    specialtyId,
                    'inputSpecialty',
                    'Specialty',
                    setErrorSpecialty
                  )
                }
                onMenuOpen={() => onMenuOpen('dropdown')}
                filterOption={createFilter({ matchFrom: 'start' })}
                placeholder="Select"
                isSearchable={true}
                isClearable={false}
                isLoading={isRequesting}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator,
                  SingleValue
                }}
                styles={style_select}
                zIndex={119}
                required={
                  professionId.label === 'Physician' ||
                  professionId.label === 'Resident'
                }
              />
              <div className={styles.err_span}>{errorSpecialty}</div>
            </div>
          )}
          <div className={styles.fieldset}>
            <button
              onClick={e => {
                e.preventDefault()
                window.grecaptcha.ready(() => {
                  try {
                    window.grecaptcha
                      .execute(recaptchaKey, { action: 'signup' })
                      .then(token => submitForm(token))
                  } catch (e) {}
                })
              }}
            >
              Create My Account
            </button>
            <p style={{ color: '#444', fontSize: '14px', fontStyle: 'italic' }}>
              * Required
            </p>
            {registeredSuccess && (
              <div className={styles.submittedSuccessBox}>
                Thank you. Your registration was successful.
              </div>
            )}
            {registeredFailed && (
              <div className={styles.submittedFailedBox}>
                An error occurred and we were not able to proceed. Try again.
              </div>
            )}
          </div>
        </form>
        <button
          className={styles.back}
          onClick={() => history.goBack()}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') history.goBack()
          }}
        >
          <p
            style={{
              color: '#979797',
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: '17px'
            }}
          >
            <Svg
              src="/img/chevron-left.svg"
              height={12}
              width={7}
              fill={'#979797'}
              style={{ marginRight: '4.3px' }}
            />
            Back
          </p>
        </button>
      </div>
    </FormLayout>
  )
}

export default SignUp
