import { createSelector } from '@reduxjs/toolkit'

export const contactUsSelector = state => state.contactUsReducer

export const getLoading = createSelector(contactUsSelector, res => res.loading)

export const getSubmitted = createSelector(
  contactUsSelector,
  res => res.submitted
)

export const getSubmittedSuccess = createSelector(
  contactUsSelector,
  res => res.submittedSuccess
)

export const getSubmittedFailed = createSelector(
  contactUsSelector,
  res => res.submittedFailed
)
