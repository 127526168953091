import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import rootReducer from './reducers'
import rootSaga from './sagas'

const createStore = (history, container) => {
  const { resolve } = container // Get Services resolver
  const sagaMiddleware = createSagaMiddleware()
  const routerMiddleware = createRouterMiddleware(history)
  const storeOptions = {
    reducer: rootReducer(history),
    middleware: [sagaMiddleware, routerMiddleware]
  }
  const store = configureStore(storeOptions)
  sagaMiddleware.run(rootSaga, resolve)
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
      const newRootReducer = require('./reducers').default
      store.replaceReducer(newRootReducer)
    })
    module.hot.accept('./sagas', () => {
      let sagaTask = sagaMiddleware.run(function*() {
        yield rootSaga()
      })
      const getNewSagas = require('./sagas').default
      sagaTask.cancel()
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(function* replacedSaga(action) {
          yield getNewSagas()
        })
      })
    })
  }
  return store
}

export default createStore
