import { useState } from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Footer from 'components/Footer'
import RailLeft from 'components/RailLeft'
import PageLoader from 'components/PageLoader'

import styles from './MainLayout.module.sass'

const MainLayout = ({ children, loading }) => {
  const crypto = window.crypto || window.msCrypto
  let random = new Uint32Array(1)

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div className={styles.box}>
        <Header menuOpen={menuOpen} />
        <div className={styles.gap}></div>
        <div
          role="button"
          tabIndex={0}
          data-testid="result"
          className={styles.content}
          onClick={() => setMenuOpen(crypto.getRandomValues(random)[0])}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ')
              setMenuOpen(crypto.getRandomValues(random)[0])
          }}
        >
          <RailLeft />
          {children}
        </div>
        {loading && <PageLoader />}
      </div>
      <Footer onClick={() => setMenuOpen(crypto.getRandomValues(random)[0])} />
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
}

export default MainLayout
