import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Svg from 'react-inlinesvg'
import cx from 'classnames'
import FormLayout from 'layouts/FormLayout'
import { actions } from './ContactUsReducer'
import * as AuthSelector from 'modules/auth/AuthSelector'
import styles from './ContactUs.module.sass'

import {
  getLoading,
  getSubmitted,
  getSubmittedSuccess,
  getSubmittedFailed
} from './ContactUsSelector'
import { variables, checkField, resetForm, checkError } from './ContactUsFunc'
import config from 'site.config'

const { getIsLogin } = AuthSelector
const {
  configuration: { envVars }
} = config

const ContactUs = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoginUser = useSelector(getIsLogin)
  const isSubmittingForm = useSelector(getLoading)
  const isSubmittedSuccess = useSelector(getSubmittedSuccess)
  const isSubmittedFailed = useSelector(getSubmittedFailed)
  const submitted = useSelector(getSubmitted)
  const [errorName, setErrorName] = useState(' ')
  const [errorEmail, setErrorEmail] = useState(' ')
  const [errorMessage, setErrorMessage] = useState('')
  const recaptchaKey = envVars.REACT_APP_PHN_RECAPTCHA_KEY

  useEffect(() => {
    dispatch(actions.resetSubmittedState())
  }, [dispatch])

  const submitForm = token => {
    let { form, name, email, comments } = variables()
    const recaptchRequire = !isLoginUser && token
    const isValidate = recaptchRequire || isLoginUser
    if (name && email && comments && isValidate && form.checkValidity()) {
      let data = {
        brandCode: process.env.REACT_APP_AUTH_BRANDNAME,
        brandType: 'contactUs',
        device: navigator.userAgent,
        fields: {
          email: email,
          firstName: name,
          lastName: ' '
        },
        lastAction: ' ',
        message: comments,
        piwikSiteId: parseInt(
          envVars?.REACT_APP_PIWIK_SITEID ?? process.env.REACT_APP_PIWIK_SITEID
        ),
        idhBrandId: parseInt(process.env.REACT_APP_IDH_BRANDID),
        reCaptcha: token || '',
        isAuth: isLoginUser
      }
      dispatch(actions.submitContactUsForm(data))
      resetForm()
    } else {
      checkError(setErrorName, setErrorEmail, setErrorMessage)
    }
  }

  useEffect(() => {
    if (isLoginUser) return // Avoid Recaptcha for auth users
    const recaptchaId = 'recaptcha-key'

    const loadScriptByURL = (id, url) => {
      const scriptExist = document.getElementById(id)
      if (!scriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        document.body.appendChild(script)
      }
    }

    // load the script by passing the URL
    loadScriptByURL(
      recaptchaId,
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`
    )

    return () => {
      document.getElementById(recaptchaId)?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginUser])

  useEffect(() => {
    if (submitted) resetForm()
  }, [submitted])

  return (
    <FormLayout loading={isSubmittingForm} disableLottie={true}>
      <div className={styles.box}>
        <h3>WE VALUE YOUR FEEDBACK!</h3>
        {
          <>
            <p>
              Your insights and experiences are crucial to us. Whether it&apos;s
              a suggestion, a compliment, or a concern, or offer to collaborate,
              we want to hear from you.
            </p>
            <form className={styles.form}>
              <div className={styles.group}>
                <label htmlFor="inputFirstName">
                  Name <span style={{ color: '#c00' }}>*</span>
                </label>
                <input
                  type="text"
                  required
                  className={cx('form-control', styles.input)}
                  id="inputFirstName"
                  name="submitted[name]"
                  size="60"
                  maxLength="128"
                  disabled={isSubmittingForm}
                  onBlur={() =>
                    checkField('inputFirstName', 'Name', setErrorName)
                  }
                />
                <div className={styles.err_span}>{errorName}</div>
              </div>
              <div className={styles.group}>
                <label htmlFor="inputEmail">
                  Email <span style={{ color: '#c00' }}>*</span>
                  <input
                    type="email"
                    required
                    className={cx('form-control', styles.input)}
                    id="inputEmail"
                    name="submitted[email]"
                    size="60"
                    maxLength="128"
                    disabled={isSubmittingForm}
                    onBlur={() =>
                      checkField('inputEmail', 'Email', setErrorEmail)
                    }
                  />
                </label>
                <div className={styles.err_span}>{errorEmail}</div>
              </div>
              <div className={styles.group}>
                <label htmlFor="inputMessage">
                  Message <span style={{ color: '#c00' }}>*</span>
                  <textarea
                    required
                    className={cx(
                      'form-control',
                      'form-control-md',
                      styles.input
                    )}
                    style={{ height: '80px' }}
                    id="inputMessage"
                    name="submitted[message]"
                    cols="60"
                    rows="3"
                    disabled={isSubmittingForm}
                    onBlur={() =>
                      checkField('inputMessage', 'Message', setErrorMessage)
                    }
                  />
                </label>
                <div className={styles.err_span}>{errorMessage}</div>
              </div>
              <button
                className={styles.btn}
                type="button"
                onClick={() => {
                  if (isLoginUser) {
                    submitForm()
                    return
                  }
                  window.grecaptcha.ready(() => {
                    try {
                      window.grecaptcha
                        .execute(recaptchaKey, { action: 'contactUs' })
                        .then(token => submitForm(token))
                    } catch (e) {}
                  })
                }}
                disabled={isSubmittingForm}
              >
                Submit
              </button>
              <p
                style={{ color: '#444', fontSize: '14px', fontStyle: 'italic' }}
              >
                * Required
              </p>
              {isSubmittedSuccess && (
                <div className={styles.submittedSuccessBox}>
                  Thank you. Your feedback has been received.
                </div>
              )}
              {isSubmittedFailed && (
                <div className={styles.submittedFailedBox}>
                  An error occured. We were not able to proceed.
                </div>
              )}
            </form>

            <button
              className={styles.back}
              onClick={() => history.goBack()}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') history.goBack()
              }}
            >
              <p
                style={{
                  color: '#979797',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: '17px'
                }}
              >
                <Svg
                  src="/img/chevron-left.svg"
                  height={12}
                  width={7}
                  fill={'#979797'}
                  style={{ marginRight: '4.3px' }}
                />
                Back
              </p>
            </button>
          </>
        }
      </div>
    </FormLayout>
  )
}

export default ContactUs
