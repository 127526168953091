import PropTypes from 'prop-types'

export const TitleProvider = ({ children }) => {
  return <>{children}</>
}
TitleProvider.propTypes = {
  children: PropTypes.node
}

export default TitleProvider
