import { useSelector } from 'react-redux'

import MainLayout from 'layouts/MainLayout'
import Rail from 'components/Rail'
import News from 'components/News'
import Ad from 'components/Ad'
import Interest from 'components/Interest'
import SearchResult from 'components/SearchResult'
import Article from 'components/Article'
import Curriculum from 'components/Curriculum'
import Podcast from 'components/Podcast'
import Events from 'components/Events'

import stylesRC from 'components/RailCenter.module.sass'
import stylesRR from 'components/RailRight.module.sass'

import { getSearchTerm } from 'modules/home/HomeSelector'

const Home = () => {
  const searchTerm = useSelector(getSearchTerm)
  const isArticlePage = window.location.pathname.includes('/article/')
  const isCurriculaPage = window.location.pathname.includes('/curricula')
  const isPodcastsPage = window.location.pathname.includes('/podcasts')
  const isEventsPage = window.location.pathname.includes('/live-events')

  return (
    <MainLayout>
      <Rail styles={stylesRC}>
        {searchTerm?.length > 0 && <SearchResult />}
        {isArticlePage && !searchTerm?.length && <Article />}
        {isCurriculaPage && !searchTerm?.length && <Curriculum />}
        {isPodcastsPage && !searchTerm?.length && <Podcast />}
        {isEventsPage && !searchTerm?.length && <Events />}
        {!isArticlePage &&
          !isCurriculaPage &&
          !isPodcastsPage &&
          !isEventsPage &&
          !searchTerm?.length && (
            <>
              <Ad />
              <Interest />
            </>
          )}
      </Rail>
      {!searchTerm?.length && (
        <Rail styles={stylesRR}>
          <News />
        </Rail>
      )}
    </MainLayout>
  )
}

export default Home
