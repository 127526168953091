/**
 * Must use old require format because this is used by react-app-rewired overrides
 */
const { asClass, asFunction } = require('awilix')

module.exports = [
  { name: 'ClarityService', registerAs: asFunction },
  { name: 'ConfigParser', registerAs: asFunction },
  { name: 'ConfigService', registerAs: asClass },
  { name: 'CookieService', registerAs: asFunction },
  { name: 'ParamsService', registerAs: asFunction },
  { name: 'TrackingService', registerAs: asFunction },
  { name: 'SessionService', registerAs: asClass },
  { name: 'APIService', registerAs: asFunction },
  { name: 'AuthService', registerAs: asFunction },
  { name: 'DeviceIdService', registerAs: asFunction }
]
