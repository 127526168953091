import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from './UserReducer'
import { actions as loginActions } from 'modules/auth/AuthReducer'
import config from 'site.config'

const {
  configuration: { envVars }
} = config

export function* registerUser(services, { payload }) {
  const cookieService = services('CookieService')
  const apiService = services('APIService')

  try {
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const idhBrandId = Number(
      envVars?.REACT_APP_AUTH_BRANDID ?? process.env.REACT_APP_AUTH_BRANDID
    )
    // parse the auth token from Cookie
    const authToken = JSON.parse(cookieService.get('authToken'))

    // additional header with bearer token
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }

    const result = yield call(
      [apiService, 'postWithHeaders'],
      baseUrl + '/v2/user/optin',
      {
        idhBrandId,
        fields: payload
      },
      additionalHeader
    )
    yield all([
      put(actions.requestRegisterUserSuccess(result)),
      put(loginActions.loginWithEmailRequest({ email: payload.email }))
      // put(actions.resetRegisterState())
    ])
  } catch (error) {
    yield put(actions.requestRegisterUserFail())
  }
}

export function* getProfile(services) {
  const cookieService = services('CookieService')
  const authToken = JSON.parse(cookieService.get('authToken'))
  const apiService = services('APIService')

  try {
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url =
      baseUrl +
      '/v2/user/profile?idhBrandId=' +
      (envVars?.REACT_APP_AUTH_BRANDID ?? process.env.REACT_APP_AUTH_BRANDID)
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'getWithHeaders'],
      url,
      {},
      additionalHeader
    )

    yield put(actions.requestUserProfileSuccess(result))
  } catch (e) {
    yield put(actions.requestUserProfileFail())
  }
}

export function* saveProfile(services, { payload }) {
  const cookieService = services('CookieService')
  const authToken = JSON.parse(cookieService.get('authToken'))
  const apiService = services('APIService')

  try {
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const url =
      baseUrl +
      '/v2/user/profile?idhBrandId=' +
      (envVars?.REACT_APP_AUTH_BRANDID ?? process.env.REACT_APP_AUTH_BRANDID)

    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      payload,
      additionalHeader
    )

    yield put(actions.requestUpdateProfileSuccess(result))
    yield put(loginActions.updateProfile(result))
  } catch (e) {
    yield put(actions.requestUpdateProfileFail())
  }
}

export default function* watchUser(services) {
  yield all([
    takeEvery(actions.requestRegisterUser().type, registerUser, services),
    takeEvery(actions.requestUserProfile().type, getProfile, services),
    takeEvery(actions.requestUpdateProfile().type, saveProfile, services)
  ])
}
