import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import loadPolyfills from 'polyfills'
import 'styles/style.sass'
import './index.css'

const render = () => {
  const App = require('./App').default
  ReactDOM.render(<App />, document.getElementById('root'))
}

loadPolyfills().then(() => render())
serviceWorker.unregister()

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', render)
}
