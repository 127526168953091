import styles from './SignUp.module.sass'

export const variables = (
  professionOptions,
  professionId,
  specialtyOptions,
  specialtyId
) => {
  const form = document.querySelector('form')
  const firstName = document.getElementById('inputFirstName').value
  const lastName = document.getElementById('inputLastName').value
  const email = document.getElementById('inputEmail').value
  const password = document.getElementById('inputPassword').value
  const profession = professionOptions.find(
    prof => prof.value === Number(professionId?.value)
  )
  const specialtyContent = specialtyOptions.find(
    spec => spec.value === Number(specialtyId?.value)
  )

  return {
    form,
    firstName,
    lastName,
    email,
    password,
    profession,
    specialtyContent
  }
}

export const setError = ele => {
  ele.classList.add(styles.inputF)
  ele.classList.remove(styles.inputG)
}

export const setGood = ele => {
  ele.classList.add(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const resetElement = ele => {
  ele.classList.remove(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const resetSelectElement = ele => {
  ele?.classList.remove(styles.selectWithError)
  ele?.classList.remove(styles.selectGood)
}

export const checkField = (elementId, fieldname, errorFunc) => {
  const ele = document.getElementById(elementId)
  if (!ele.checkValidity()) {
    errorFunc(`Your ${fieldname} is required.`)
    setError(ele)
  } else {
    errorFunc('')
    setGood(ele)
  }
}

export const checkSelect = (selectValue, elementId, fieldname, errorFunc) => {
  const eleValue = selectValue?.value || null
  const element = document.getElementById(elementId)
  if (element) {
    if (eleValue == null) {
      errorFunc(`Your ${fieldname} is required.`)
      element.classList.add(styles.selectWithError)
      element.classList.remove(styles.selectGood)
    } else {
      errorFunc('')
      element.classList.add(styles.selectGood)
      element.classList.remove(styles.selectWithError)
    }
  }
}

export const handleSelectNotice = (input, elementId, fieldname, errorFunc) => {
  const element = document.getElementById(elementId)
  // put css change here because the react-select handles setState
  // slower than the css to detect faulty input.
  // react-select also doesn't support ontouch (mobile events)
  if (input.value === '') {
    errorFunc(`Your ${fieldname} is required.`)
    element.classList.add(styles.selectWithError)
    element.classList.remove(styles.selectGood)
  } else {
    errorFunc('')
    element.classList.add(styles.selectGood)
    element.classList.remove(styles.selectWithError)
  }
}

export const resetForm = (
  setErrorFirstName,
  setErrorLastName,
  setErrorEmail,
  setErrorPassword,
  setErrorCountry,
  setErrorProfession
) => {
  // removes the css for good/fault input
  resetElement(document.getElementById('inputFirstName'))
  resetElement(document.getElementById('inputEmail'))
  resetElement(document.getElementById('inputLastName'))
  resetElement(document.getElementById('inputPassword'))
  resetSelectElement(document.getElementById('inputCountry'))
  resetSelectElement(document.getElementById('inputProfession'))

  // removes the error message
  setErrorFirstName('')
  setErrorLastName('')
  setErrorEmail('')
  setErrorPassword('')
  setErrorCountry('')
  setErrorProfession('')
}

export const resetInput = (setCountry, setProfessionId, setSpecialtyId) => {
  document.getElementById('inputFirstName').value = ''
  document.getElementById('inputLastName').value = ''
  document.getElementById('inputEmail').value = ''
  document.getElementById('inputPassword').value = ''
  setCountry({ label: 'Select', value: '' })
  setProfessionId({ label: 'Select', value: '' })
  setSpecialtyId({ label: 'Select', value: '' })
}

export const checkError = (
  country,
  setErrorFirstName,
  setErrorLastName,
  setErrorEmail,
  setErrorPassword,
  setErrorCountry
) => {
  checkField('inputFirstName', 'First Name', setErrorFirstName)
  checkField('inputLastName', 'Last Name', setErrorLastName)
  checkField('inputEmail', 'Email', setErrorEmail)
  checkField('inputPassword', 'Password', setErrorPassword)
  checkSelect(country, 'inputCountry', 'Country', setErrorCountry)
  // checkSelect(professionId, 'inputProfession', 'Profession', setErrorProfession)
  // checkSelect(specialtyId, 'inputSpecialty', 'Specialty', setErrorSpecialty)
}

export const checkIsSpecialtyRequired = profession => {
  const professionLabel = profession?.label || ''
  return professionLabel === 'Physician' || professionLabel === 'Resident'
}
