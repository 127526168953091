import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestMetadata = createAction('METADATA__REQUEST')
export const successRequest = createAction('METADATA__REQUEST__SUCCESS')
export const errorRequest = createAction('REQUEST__ERROR')

export const actions = {
  requestMetadata,
  successRequest,
  errorRequest
}

const optinReducer = createReducer(
  {
    profession: [],
    country: [],
    specialty: [],
    specialtyLIMAtoCONTENT: [],
    specialtyCONTENT: [],
    mapSpecialty: [],
    mapSpecialtyCONTENT: [],
    error: false,
    requesting: false
  },
  {
    [requestMetadata]: state => {
      state.requesting = true
      state.error = false
    },
    [successRequest]: (state, { payload }) => {
      state.profession = payload.optionProfession
      state.country = payload.optionCountry
      state.specialty = payload.optionSpecialty
      state.specialtyLIMAtoCONTENT = payload.optionSpecialtyLIMAtoCONTENT
      state.specialtyCONTENT = payload.optionSpecialtyCONTENT
      state.mapSpecialtyCONTENT = payload.optionMapSpecialtyCONTENT
      state.mapSpecialty = payload.optionMapSpecialty
      state.originalSpecialtyCONTENT = payload.originalSpecialtyCONTENT
      state.originalMapSpecialtyContent = payload.originalMapSpecialtyContent
      state.error = false
      state.requesting = false
    },
    [errorRequest]: state => {
      state.error = true
      state.requesting = false
    }
  }
)

export default optinReducer
