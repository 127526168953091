import { takeEvery, all, put, call, select } from 'redux-saga/effects'
import { actions } from 'modules/auth/AuthReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as optinActions } from 'modules/optin/OptinReducer'
import { LOCATION_CHANGE } from 'connected-react-router'
import { get, parseInt } from 'lodash'
import { getTemporaryToken } from './AuthSelector'
import { setTracking } from '../bootstrap/BootstrapSaga'
import { getSiteConfig } from 'utils'
import config from 'site.config'
import { deviceIdFull } from 'modules/deviceId/DeviceIdSaga'

const { deviceID } = config.configuration
const {
  configuration: { envVars, pathRedirectAfterOtpAuth }
} = config

export function* initialize(services) {
  try {
    yield call(deviceIdFull, services, deviceID?.useCacheOnRefresh || false)
    const tempToken = yield select(getTemporaryToken)
    if (tempToken) {
      yield call(validateOTP, services, { payload: { token: tempToken } })
    } else {
      // change requestAuth to get auth token from v2/auth/token/device
      yield call(requestAuth, services)
    }
    yield put(optinActions.requestMetadata())
    yield call(setTracking, services)
  } catch (error) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'Fail to perform Authentication.',
        duration: 2000
      })
    )
  }
  try {
    // yield call(setClarityIdentity, services)
    if (window.clarity === undefined) {
      console.warn('clarity not found')
      return
    }

    const sessionService = services('SessionService')
    const clarityConfig = getSiteConfig('configuration.tracking.clarity', {})
    if (clarityConfig?.identifiers) {
      const customId = sessionService
        .getFromCache(clarityConfig.identifiers['custom-id'], '')
        ?.toString()
      const customSessionId = sessionService
        .getFromCache(clarityConfig.identifiers['custom-session-id'], '')
        ?.toString()
      return window.clarity('identify', customId, customSessionId)
    }
  } catch (err) {}
}

export function* setClarityIdentity(services) {
  const clarityService = services('ClarityService')
  yield call([clarityService, 'setCustomIds'])
}

export function* requestAuth(services) {
  const authService = services('AuthService')
  const cookieService = services('CookieService')
  const sessionService = services('SessionService')
  const phoenix_url =
    envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
  const siteId = parseInt(
    envVars?.REACT_APP_PHNX_SITEID ?? process.env.REACT_APP_PHNX_SITEID
  )

  const response = yield call(
    [authService, 'getAuthToken'],
    phoenix_url,
    siteId,
    cookieService,
    sessionService
  )
  if (response === false) {
    const refreshed = yield call(
      [authService, 'refreshAuthToken'],
      phoenix_url,
      siteId,
      cookieService
    )
    // UPDATE TO THE COOKIE
    yield all([
      call([cookieService, 'set'], 'authToken', refreshed),
      put(actions.updateToken(refreshed))
    ])
  } else {
    // UPDATE TO THE COOKIE
    let token = response?.tokens ?? response
    yield all([
      call([cookieService, 'set'], 'authToken', token),
      put(actions.successAuth(response))
    ])
  }
  return response
}

export function* triggerOTP(services, { payload }) {
  try {
    const cookieService = services('CookieService')
    // const trackingService = services('TrackingService')
    const authToken = JSON.parse(cookieService.get('authToken'))
    const authService = services('AuthService')
    const siteId = parseInt(
      envVars?.REACT_APP_PHNX_SITEID ?? process.env.REACT_APP_PHNX_SITEID
    )

    const result = yield call(
      [authService, 'triggerOtpRequest'],
      {
        siteId,
        brandCode:
          envVars?.REACT_APP_AUTH_BRANDNAME ??
          process.env.REACT_APP_AUTH_BRANDNAME,
        idhEmail: payload.email
      },
      authToken
    )

    yield put(actions.loginWithEmailRequestSuccess(result))
  } catch (e) {}
}
export function* setPath(services, { payload }) {
  const { location, action } = payload
  if (
    action === 'POP' &&
    (location.search.includes('?otp=') || location.search.includes('&otp='))
  ) {
    yield put(
      actions.updateTemporaryToken({
        token: location.search
      })
    )
  }
}

export function* triggerLoginWithPassword(services, { payload }) {
  const cookieService = services('CookieService')
  const authToken = JSON.parse(cookieService.get('authToken'))
  // const { redirectRoute } = yield select(authSelector, ['getRedirectRoute'])
  try {
    const authService = services('AuthService')
    const sessionService = services('SessionService')
    const trackingService = services('TrackingService')
    const result = yield call(
      [authService, 'performLoginWithPassword'],
      {
        idhBrandId:
          envVars?.REACT_APP_AUTH_BRANDID ?? process.env.REACT_APP_AUTH_BRANDID,
        brandCode:
          envVars?.REACT_APP_AUTH_BRANDNAME ??
          process.env.REACT_APP_AUTH_BRANDNAME,
        email: payload.email,
        password: payload.password
      },
      authToken
    )
    let token = result?.tokens ?? result
    if (result) {
      let partyId = get(result, 'info.user.partyId', '')
      let partyEmailId = get(result, 'info.user.partyEmailId', '')
      let professionId = get(result, 'info.user.professionId', '')
      let specialtyId = get(result, 'info.user.specialtyId', '')
      let countryCode = get(result, 'info.user.countryCode', '')

      yield all([
        call([cookieService, 'set'], 'authToken', token),
        call([sessionService, 'saveToCache'], 'MemberID', partyId),
        call([sessionService, 'saveToCache'], 'EmailID', partyEmailId),
        call([sessionService, 'saveToCache'], 'ProfessionID', professionId),
        call([sessionService, 'saveToCache'], 'SpecialtyID', specialtyId),
        call([sessionService, 'saveToCache'], 'CountryCode', countryCode),
        call([sessionService, 'saveToCache'], 'PartySource', ''),
        call([trackingService, 'resetVisitCustomVariables'])
      ])

      yield all([
        put(actions.successAuth(result)),
        put(actions.loginWithPasswordRequestSuccess(result))
      ])
      payload.history.push(pathRedirectAfterOtpAuth)
    } else {
      yield put(
        actions.loginWithPasswordRequestFail(
          'Failed to log in. Please check your email/password and try again.'
        )
      )
    }
  } catch (e) {}
}
export function* validateOTP(services, { payload }) {
  const newToken = {
    access: payload.token
  }
  try {
    const authService = services('AuthService')
    const cookieService = services('CookieService')
    const sessionService = services('SessionService')
    const trackingService = services('TrackingService')
    const phoenix_url =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const siteId = parseInt(
      envVars?.REACT_APP_PHNX_SITEID ?? process.env.REACT_APP_PHNX_SITEID
    )
    let result = yield call(
      [authService, 'verifyTokenWithPhoenix'],
      phoenix_url,
      siteId,
      newToken,
      'otp',
      true
    )
    let token = result?.tokens ?? result
    if (result) {
      yield put(actions.successAuth(result))
      let partyId = get(result, 'info.user.partyId', '')
      let partyEmailId = get(result, 'info.user.partyEmailId', '')
      let professionId = get(result, 'info.user.professionId', '')
      let specialtyId = get(result, 'info.user.specialtyId', '')
      let countryCode = get(result, 'info.user.countryCode', '')

      yield all([
        call([cookieService, 'set'], 'authToken', token),
        call([sessionService, 'saveToCache'], 'MemberID', partyId),
        call([sessionService, 'saveToCache'], 'EmailID', partyEmailId),
        call([sessionService, 'saveToCache'], 'ProfessionID', professionId),
        call([sessionService, 'saveToCache'], 'SpecialtyID', specialtyId),
        call([sessionService, 'saveToCache'], 'CountryCode', countryCode),
        call([sessionService, 'saveToCache'], 'PartySource', ''),
        call([trackingService, 'resetVisitCustomVariables'])
      ])
      yield call(setTracking, services)
      // payload.history.push(pathRedirectAfterOtpAuth || '/')
    } else {
      yield all([
        put(actions.errorAuth(result)),
        put(
          notificationActions.notificationEnqueue({
            message: 'Login Error',
            duration: 3000
          })
        )
      ])
    }
    // Failed unauthenticate
    // yield put(actions.loginWithEmailRequestSuccess(result))
  } catch (e) {}
}

export function* requestNemlToken(services, { payload }) {
  const cookieService = services('CookieService')
  const authToken = JSON.parse(cookieService.get('authToken'))
  try {
    const authService = services('AuthService')
    const phoenix_url =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    let result = yield call(
      [authService, 'getNemlToken'],
      phoenix_url,
      payload,
      authToken
    )
    let token = result?.tokens ?? result
    if (result) {
      yield call([cookieService, 'set'], 'nemlToken', token)
      yield put(actions.successNemlAuth(result))
    } else {
      yield call([cookieService, 'set'], 'nemlToken', null)
      yield all([
        put(actions.errorNemlAuth(result)),
        put(
          notificationActions.notificationEnqueue({
            message: 'Request Error',
            duration: 3000
          })
        )
      ])
    }
    // Failed unauthenticate
    // yield put(actions.loginWithEmailRequestSuccess(result))
  } catch (e) {}
}

export function* logoutUser(services, { payload }) {
  const trackingService = services('TrackingService')
  const cookieService = services('CookieService')
  cookieService.remove('authToken')
  cookieService.remove('nemlToken')
  const SessionService = services('SessionService')
  SessionService.deleteFromCache('MemberID')
  SessionService.deleteFromCache('EmailID')
  SessionService.deleteFromCache('ProfessionID')
  SessionService.deleteFromCache('SpecialtyID')
  SessionService.deleteFromCache('deviceId')
  SessionService.deleteFromCache('PSLDeviceID')
  SessionService.deleteFromCache('CountryCode')
  const clarityConfig = getSiteConfig('configuration.tracking.clarity', {})

  if (clarityConfig?.clearOnLogout) {
    // const clarityService = services('ClarityService')
    // yield call([ClarityService, 'resetCustomIds'])

    if (window.clarity === undefined) {
      console.warn('clarity not found')
      return
    }

    if (clarityConfig?.identifiers) return window.clarity('identify', '', '')
  }

  yield all([
    call([cookieService, 'remove'], 'authToken'),
    call([cookieService, 'remove'], 'nemlToken'),
    call([SessionService, 'deleteFromCache'], 'MemberID'),
    call([SessionService, 'deleteFromCache'], 'EmailID'),
    call([SessionService, 'deleteFromCache'], 'ProfessionID'),
    call([SessionService, 'deleteFromCache'], 'SpecialtyID'),
    call(requestAuth, services),
    call([trackingService, 'resetVisitCustomVariables'])
  ])
  payload.history.push('/')
}

export default function* watchLogin(services) {
  yield all([
    takeEvery(actions.loginWithEmailRequest, triggerOTP, services),
    takeEvery(
      actions.loginWithPasswordRequest,
      triggerLoginWithPassword,
      services
    ),
    takeEvery(actions.validateOtpRequest, validateOTP, services),
    takeEvery(actions.requestNemlToken, requestNemlToken, services),
    takeEvery(LOCATION_CHANGE, setPath, services),
    takeEvery(actions.requestToken().type, initialize, services),
    takeEvery(actions.invalidateToken().type, logoutUser, services)
  ])
}
