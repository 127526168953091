import styles from './ContactUs.module.sass'

export const variables = () => {
  const form = document.querySelector('form')
  const name = document.getElementById('inputFirstName').value
  const email = document.getElementById('inputEmail').value
  const comments = document.getElementById('inputMessage').value

  return { form, name, email, comments }
}

export const setError = ele => {
  ele.classList.add(styles.inputF)
  ele.classList.remove(styles.inputG)
}

export const setGood = ele => {
  ele.classList.add(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const resetElement = ele => {
  ele.value = ''
  ele.classList.remove(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const checkField = (elementId, fieldname, errorFunc) => {
  const ele = document.getElementById(elementId)
  if (!ele.checkValidity()) {
    errorFunc(`Your ${fieldname} is required.`)
    setError(ele)
  } else {
    errorFunc('')
    setGood(ele)
  }
}

export const resetForm = () => {
  resetElement(document.getElementById('inputFirstName'))
  resetElement(document.getElementById('inputEmail'))
  resetElement(document.getElementById('inputMessage'))
}

export const checkError = (setErrorName, setErrorEmail, setErrorMessage) => {
  if (!document.getElementById('inputFirstName').checkValidity()) {
    setErrorName('Your Name is required.')
    setError(document.getElementById('inputFirstName'))
  }
  if (!document.getElementById('inputEmail').checkValidity()) {
    setErrorEmail('Your Email is required.')
    setError(document.getElementById('inputEmail'))
  }
  if (!document.getElementById('inputMessage').checkValidity()) {
    setErrorMessage('Your Message is required.')
    setError(document.getElementById('inputMessage'))
  }
}
