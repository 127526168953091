import { createReducer, createAction } from '@reduxjs/toolkit'
import { generateRandomString } from 'utils'

const generateUid = () => generateRandomString().substring(0, 9)

export const notificationEnqueue = createAction('NOTIFICATION_ENQUEUE')
export const notificationDequeue = createAction('NOTIFICATION_DEQUEUE')
export const notificationClearAll = createAction('NOTIFICATION_CLEAR_ALL')
export const notificationPop = createAction('NOTIFICATION_POP')

export const actions = {
  notificationEnqueue,
  notificationDequeue,
  notificationClearAll,
  notificationPop
}

const notificationReducer = createReducer(
  {
    notifications: []
  },
  {
    [notificationEnqueue]: (
      state,
      { payload: { message, duration = 3000, onDismiss } }
    ) => {
      const uid = generateUid()
      state.notifications = [
        { uid, message, duration, onDismiss },
        ...state.notifications
      ]
    },
    [notificationDequeue]: (state, { payload: uid }) => {
      const index = state.notifications.findIndex(
        notification => notification.uid === uid
      )
      if (index >= 0) {
        const notificationsClone = state.notifications.slice()
        notificationsClone.splice(index, 1)
        state.notifications = notificationsClone
      }
    },
    [notificationClearAll]: state => {
      state.notifications = []
    },
    [notificationPop]: state => {
      state.notifications = state.notifications.slice(1)
    }
  }
)

export default notificationReducer
