import { takeEvery, all, call, put } from 'redux-saga/effects'
import { LOCATION_CHANGE, replace } from 'connected-react-router'
import queryString from 'query-string'
import config from 'site.config.json'
// import { basename } from 'constants/index'

const {
  configuration: { tracking }
} = config

const projectNumber = tracking.variables?.projectNumber
const collection = tracking.variables?.collection

/**
 * Sends a click event to Piwik.
 */
export function* trackClick(services, clickObj) {
  const trackingService = services('TrackingService')
  yield call([trackingService, 'track'], `click/${clickObj.type}`, {
    ProjectNumber: projectNumber,
    ClickType: clickObj.type,
    ObjectID: clickObj.objectid,
    Custom: clickObj.custom,
    Collection: collection
  })
}

/**
 * Sends an external tracking event as click to Piwik.
 * Not in use for now.
 *
 export function* trackExternal(services, url) {
  const Tracking = services('Tracking')
  const SessionService = services('Session')
  yield call([Tracking, 'track'], `exit`, {
    ProjectNumber: projectNumber,
    Source: SessionService.getFromCache('Source', ''),
    DestURL: url,
    CampaignID: SessionService.getFromCache('CampaignID', ''),
    PromoCode: SessionService.getFromCache('Promocode', '796'),
    Collection: collection
  })
}*/

export function* locationChange(services, { payload }) {
  const Tracking = services('TrackingService')
  const SessionService = services('SessionService')
  const {
    location: { pathname, search },
    action
  } = payload
  // Check external link access
  const parsedParams = queryString.parse(search)
  const redirectUrl = parsedParams.url
  const title = parsedParams.title
  const replaceUrl = parsedParams.replace

  if (pathname === '/' && (action === 'PUSH' || action === 'POP')) {
    SessionService.deleteFromCache('SearchSpecialty')
    SessionService.deleteFromCache('SearchTopic')
    SessionService.deleteFromCache('SearchCredit')
    SessionService.deleteFromCache('SearchTerm')
  }

  // Store initial params
  search && services('ParamsService').saveToCache(parsedParams)

  clearSearchParam(SessionService, parsedParams)

  // Remove any query in case of payload strings in the URL by replacing location
  if (search) {
    yield put(replace({ pathname, search: '', hash: '' }))
  }
  if (redirectUrl) {
    // External navigation, with 'click' tracking
    const listingType = parsedParams.type
    const url = decodeURIComponent(redirectUrl)
    if (replaceUrl && replaceUrl === '1') {
      window.location.replace(url)
    } else {
      window.open(url)
    }
    yield call(trackClick, services, {
      type: listingType,
      objectid: title,
      custom: url
    })
  } else if (action !== 'REPLACE') {
    // Don't track replacements
    // log a click for that internal link, if found
    if (pathname.includes('/article')) {
      // Article click
      const section = pathname.split('/')[1]
      yield call(trackClick, services, {
        type: section,
        objectid: title,
        custom: pathname
      })
    }
    // fire a pageview for that internal link
    yield call([Tracking, 'track'], `view${pathname}`, {
      ProjectNumber: projectNumber,
      PromoCode: SessionService.getFromCache('Promocode', '796'),
      CampaignID: SessionService.getFromCache('CampaignID', ''),
      Collection: collection,
      Language: SessionService.getFromCache('Language', ''),
      Source: SessionService.getFromCache('Source', ''),
      interactionId: SessionService.getFromCache(
        tracking.clarity.identifiers['custom-session-id'],
        ''
      )?.toString(),
      durationVisitId: SessionService.getFromCache('durationVisitId', ''),
      AudienceID: SessionService.getFromCache('AudienceID', '')
    })
  }
}

function clearSearchParam(SessionService, parsedParams) {
  // Clear old search params
  if (
    parsedParams.SearchSpecialty ||
    parsedParams.SearchTopic ||
    parsedParams.SearchCredit ||
    parsedParams.SearchTerm
  ) {
    if (!parsedParams.SearchSpecialty)
      SessionService.deleteFromCache('SearchSpecialty')
    if (!parsedParams.SearchTopic) SessionService.deleteFromCache('SearchTopic')
    if (!parsedParams.SearchCredit)
      SessionService.deleteFromCache('SearchCredit')
    if (!parsedParams.SearchTerm) SessionService.deleteFromCache('SearchTerm')
  }
}

export default function* watchTracking(services) {
  yield all([
    takeEvery('TRACK_CLICK', trackClick, services),
    takeEvery(LOCATION_CHANGE, locationChange, services)
  ])
}
