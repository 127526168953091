import { Switch, Route } from 'react-router-dom'
import ScrollToTop from 'ScrollToTop'

import Home from 'modules/home'
import CMETracker from 'modules/cme-tracker'
import AboutUs from 'static/AboutUs'
import Partner from 'static/Partner'
import Terms from 'static/Terms'
import Privacy from 'static/Privacy'
import PrivacyCorp from 'static/PrivacyCorp'
import PrivacyCorpEmp from 'static/PrivacyCorpEmp'
import PrivacyCorpEU from 'static/PrivacyCorpEU'
import PrivacyCorpCCPA from 'static/PrivacyCorpCCPA'
import PrivacyCCPA from 'static/PrivacyCCPA'
import PrivacyEU from 'static/PrivacyEU'
import PrivacyBusiness from 'static/PrivacyBusiness'
import Cookie from 'static/Cookie'
import SignUp from 'modules/signup'
import ContactUs from 'modules/contactus/ContactUs'
import UserProfile from 'modules/userProfile'
import Unsubscribe from 'modules/unsubscribe'
import NotFound from 'static/NotFound'

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route path="/" default exact component={Home} />
        <Route path="/article/*" default component={Home} />
        <Route path="/curricula" default component={Home} />
        <Route path="/podcasts" default component={Home} />
        <Route path="/live-events" default component={Home} />
        <Route path="/cme-tracker" default exact component={CMETracker} />
        <Route path="/aboutus" default exact component={AboutUs} />
        <Route path="/about-us" default exact component={AboutUs} />
        <Route path="/partnership" default exact component={Partner} />
        <Route path="/partner-with-us" default exact component={Partner} />
        <Route path="/terms-of-use" exact component={Terms} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/corp-privacy" exact component={PrivacyCorp} />
        <Route path="/corp-emp-privacy" exact component={PrivacyCorpEmp} />
        <Route path="/corp-eu" exact component={PrivacyCorpEU} />
        <Route path="/corp-eu-privacy" exact component={PrivacyCorpEU} />
        <Route path="/corp-ccpa" exact component={PrivacyCorpCCPA} />
        <Route path="/corp-ccpa-privacy" exact component={PrivacyCorpCCPA} />
        <Route path="/ccpa-privacy" exact component={PrivacyCCPA} />
        <Route path="/eu-privacy" exact component={PrivacyEU} />
        <Route
          path="/businesscontactprivacynotice"
          exact
          component={PrivacyBusiness}
        />
        <Route path="/cookie" exact component={Cookie} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/feedback" exact component={ContactUs} />
        <Route path="/contactus" exact component={ContactUs} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/profile" exact component={UserProfile} />
        <Route path="/unsubscribe" exact component={Unsubscribe} />
        <Route path="*" component={NotFound} />
      </Switch>
    </ScrollToTop>
  )
}

export default Routes
