import { createReducer, createAction } from '@reduxjs/toolkit'
const submitContactUsForm = createAction('SUBMIT_CONTACT_US_FORM')
const submitFeedbackForm = createAction('SUBMIT_FEEDBACK_FORM')
const submitContactUsFormSuccess = createAction(
  'SUBMIT_CONTACT_US_FORM_SUCCESS'
)
const submitContactUsFormFail = createAction('SUBMIT_CONTACT_US_FORM_FAIL')
const resetSubmittedState = createAction('RESET_SUBMITTED_STATE')

export const actions = {
  submitContactUsForm,
  submitFeedbackForm,
  submitContactUsFormSuccess,
  submitContactUsFormFail,
  resetSubmittedState
}

const contactUsReducer = createReducer(
  {
    loading: false,
    submitted: false,
    submittedSuccess: false,
    submittedFailed: false
  },
  {
    [submitContactUsForm]: state => {
      state.loading = true
    },
    [submitFeedbackForm]: state => {
      state.loading = true
      state.submitted = false
      state.submittedSuccess = false
      state.submittedFailed = false
    },
    [submitContactUsFormSuccess]: (state, { payload }) => {
      state.loading = false
      state.submitted = true
      state.submittedSuccess = true
      state.submittedFailed = false
    },
    [submitContactUsFormFail]: state => {
      state.loading = false
      state.submitted = false
      state.submittedSuccess = false
      state.submittedFailed = true
    },
    [resetSubmittedState]: state => {
      state.submitted = false
      state.submittedSuccess = false
      state.submittedFailed = false
    }
  }
)

export default contactUsReducer
