import { createSelector } from '@reduxjs/toolkit'

export const userSelector = state => state.userReducer

export const getUserProfile = createSelector(userSelector, user => user.profile)

export const getIsFetchingUserProfile = createSelector(
  userSelector,
  user => user.fetchingUserProfile
)

export const getIsUpdatingProfile = createSelector(
  userSelector,
  user => user.updatingUserProfile
)

export const getIsProcessingRegister = createSelector(
  userSelector,
  user => user.processingRegister
)
export const getRegisterState = createSelector(
  userSelector,
  user => user.registeredSuccess
)
export const getRegisterFailedState = createSelector(
  userSelector,
  user => user.registeredFailed
)
export const getUpdateUserProfileSuccess = createSelector(
  userSelector,
  user => user.updateUserProfileSuccess
)
export const getUpdateUserProfileFailed = createSelector(
  userSelector,
  user => user.updateUserProfileFailed
)
