const ConfigParser = () => ({
  filterExpiredListings(section) {
    if (section.list) {
      const currentDate = Math.floor(Date.now() / 1000)
      const list = section.list.filter(listing => {
        const expirationDate =
          listing.expiry && listing.expiry.toString().length === 13
            ? Math.ceil(listing.expiry / 1000)
            : listing.expiry
        return !(listing.expiry && currentDate > expirationDate)
      })
      return { list }
    } else {
      return section
    }
  },
  parseManualListings(config) {
    const sections = {}
    for (let key in config.sections) {
      sections[key] = this.filterExpiredListings(config.sections[key])
    }
    return { ...config, sections }
  },
  parseConfig(config) {
    const parsedConfig = { ...config }
    return this.parseManualListings(parsedConfig)
  }
})

export default ConfigParser
