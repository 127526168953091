import { createSelector } from '@reduxjs/toolkit'

export const optinSelector = state => state.optinReducer

export const getOptinProfession = createSelector(
  optinSelector,
  optin => optin.profession
)

export const getOptinCountry = createSelector(
  optinSelector,
  optin => optin.country
)

export const getOptinSpecialty = createSelector(
  optinSelector,
  optin => optin.specialty
)

export const getOptinSpecialtyLIMAtoCONTENT = createSelector(
  optinSelector,
  optin => optin.specialtyLIMAtoCONTENT
)

export const getOptinMapSpecialty = createSelector(
  optinSelector,
  optin => optin.mapSpecialty
)
// for optin specialty dropdown
export const getOptinMapSpecialtyCONTENT = createSelector(
  optinSelector,
  optin => optin.mapSpecialtyCONTENT
)
// original optin specialty from API
export const getOriOptinMapSpecialtyCONTENT = createSelector(
  optinSelector,
  optin => optin.originalMapSpecialtyContent
)

// for profile form specialty dropdown
export const getOptinSpecialtyCONTENT = createSelector(
  optinSelector,
  optin => optin.specialtyCONTENT
)
// original profile specialty from API
export const getOriOptinSpecialtyCONTENT = createSelector(
  optinSelector,
  optin => optin.originalSpecialtyCONTENT
)

export const getIsRequesting = createSelector(
  optinSelector,
  optin => optin.requesting
)
