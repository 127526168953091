import { createReducer, createAction } from '@reduxjs/toolkit'

import Session from 'services/SessionService'

export const requestHome = createAction('REQUEST_HOME')
export const requestHomeSuccess = createAction('REQUEST_HOME_SUCCESS')
export const requestHomeFail = createAction('REQUEST_HOME_FAIL')
export const requestNews = createAction('REQUEST_NEWS')
export const requestNewsSuccess = createAction('REQUEST_NEWS_SUCCESS')
export const requestNewsFail = createAction('REQUEST_NEWS_FAIL')
export const requestInterest = createAction('REQUEST_INTEREST')
export const requestInterestSuccess = createAction('REQUEST_INTEREST_SUCCESS')
export const requestInterestFail = createAction('REQUEST_INTEREST_FAIL')
export const requestArticle = createAction('REQUEST_ARTICLE')
export const requestArticleSuccess = createAction('REQUEST_ARTICLE_SUCCESS')
export const requestArticleFail = createAction('REQUEST_ARTICLE_FAIL')
export const requestCurricula = createAction('REQUEST_CURRICULA')
export const requestCurriculaSuccess = createAction('REQUEST_CURRICULA_SUCCESS')
export const requestCurriculaFail = createAction('REQUEST_CURRICULA_FAIL')
export const requestPodcasts = createAction('REQUEST_PODCASTS')
export const requestPodcastsSuccess = createAction('REQUEST_PODCASTS_SUCCESS')
export const requestPodcastsFail = createAction('REQUEST_PODCASTS_FAIL')
export const requestEvents = createAction('REQUEST_EVENTS')
export const requestEventsSuccess = createAction('REQUEST_EVENTS_SUCCESS')
export const requestEventsFail = createAction('REQUEST_EVENTS_FAIL')
export const requestSearch = createAction('REQUEST_SEARCH')
export const requestSearchSuccess = createAction('REQUEST_SEARCH_SUCCESS')
export const requestSearchFail = createAction('REQUEST_SEARCH_FAIL')
export const requestAd = createAction('REQUEST_AD')
export const requestAdSuccess = createAction('REQUEST_AD_SUCCESS')
export const requestAdFail = createAction('REQUEST_AD_FAIL')
export const setSearchTerm = createAction('SET_SEARCH_TERM')
export const setSearchPanel = createAction('SET_SEARCH_PANEL')
export const setSpecialtyInput = createAction('SET_SPECIALTY_INPUT')

export const actions = {
  requestHome,
  requestHomeSuccess,
  requestHomeFail,
  requestNews,
  requestNewsSuccess,
  requestNewsFail,
  requestInterest,
  requestInterestSuccess,
  requestInterestFail,
  requestArticle,
  requestArticleSuccess,
  requestArticleFail,
  requestCurricula,
  requestCurriculaSuccess,
  requestCurriculaFail,
  requestPodcasts,
  requestPodcastsSuccess,
  requestPodcastsFail,
  requestEvents,
  requestEventsSuccess,
  requestEventsFail,
  requestSearch,
  requestSearchSuccess,
  requestSearchFail,
  requestAd,
  requestAdSuccess,
  requestAdFail,
  setSearchTerm,
  setSearchPanel,
  setSpecialtyInput
}

const homeReducer = createReducer(
  {
    loading: false,
    loadingSpecialty: false,
    loadingNews: false,
    loadingInterest: false,
    loadingArticle: false,
    article: {},
    loadingCurricula: true,
    curricula: [],
    loadingPodcasts: true,
    podcasts: [],
    loadingEvents: true,
    events: [],
    results: [],
    searchTerm: '',
    searchPanel: false,
    search: {
      data: [],
      loading: false,
      pages: 0,
      total: 0,
      currentPage: 1
    },
    news: {
      data: [],
      loading: false,
      pages: 0,
      total: 0,
      currentPage: 1
    },
    interest: [],
    specialtyInput: new Session().getFromCache('AOM', {
      label: 'General/Family Medicine',
      value: 55,
      value2: 'General Medicine'
    }),
    ad: {
      data: [],
      loading: false
    }
  },
  {
    [requestHome]: state => {
      state.loading = true
    },
    [requestHomeSuccess]: (state, { payload }) => {
      state.loading = false
      state.loadingSpecialty = false
      state.results = [...state.results, ...payload.results]
      state.pagination = payload
    },
    [requestHomeFail]: state => {
      state.loading = false
      state.loadingSpecialty = false
    },
    // News
    [requestNews]: state => {
      state.news.loading = true
      state.news.data = []
    },
    [requestNewsSuccess]: (state, { payload }) => {
      state.news.loading = false
      state.news.data = [...payload.results]
      state.news.currentPage = payload.currentPage
      state.news.pages = payload.pages
      state.news.total = payload.total
    },
    [requestNewsFail]: state => {
      state.news.loading = false
      state.news.data = []
    },
    // Interest
    [requestInterest]: state => {
      state.loading = true
      state.loadingInterest = true
    },
    [requestInterestSuccess]: (state, { payload }) => {
      state.loading = false
      state.loadingInterest = false
      state.interest = [...payload.results]
    },
    [requestInterestFail]: state => {
      state.loading = false
      state.loadingInterest = false
    },
    // Article
    [requestArticle]: state => {
      state.loading = true
      state.loadingArticle = true
    },
    [requestArticleSuccess]: (state, { payload }) => {
      state.loading = false
      state.loadingArticle = false
      state.article = payload?.results[0] || {}
    },
    [requestArticleFail]: state => {
      state.loading = false
      state.loadingArticle = false
    },
    // Curricula
    [requestCurricula]: state => {
      state.loadingCurricula = true
    },
    [requestCurriculaSuccess]: (state, { payload }) => {
      state.loadingCurricula = false
      state.curricula = payload.curricula
    },
    [requestCurriculaFail]: state => {
      state.loadingCurricula = false
    },
    // Podcasts
    [requestPodcasts]: state => {
      state.loadingPodcasts = true
    },
    [requestPodcastsSuccess]: (state, { payload }) => {
      state.loadingPodcasts = false
      state.podcasts = payload.podcasts
    },
    [requestPodcastsFail]: state => {
      state.loadingPodcasts = false
    },
    // Events
    [requestEvents]: state => {
      state.loadingEvents = true
    },
    [requestEventsSuccess]: (state, { payload }) => {
      state.loadingEvents = false
      state.events = payload.events
    },
    [requestEventsFail]: state => {
      state.loadingEvents = false
    },
    // Search
    [requestSearch]: state => {
      state.search.loading = true
      state.search.data = []
    },
    [requestSearchSuccess]: (state, { payload }) => {
      state.search.loading = false
      state.search.data = [...payload.results]
      state.search.currentPage = payload.currentPage
      state.search.pages = payload.pages
      state.search.total = payload.total
    },
    [requestSearchFail]: state => {
      state.search.loading = false
      state.search.data = []
    },
    [setSearchTerm]: (state, { payload }) => {
      state.searchTerm = payload
    },
    [setSearchPanel]: (state, { payload }) => {
      state.searchPanel = payload
    },
    // Ads
    [requestAd]: (state, { payload }) => {
      state.ad.loading = true
    },
    [requestAdSuccess]: (state, { payload }) => {
      state.ad.loading = false
      let newAd =
        typeof payload.req[0] === 'string'
          ? JSON.parse(payload.req[0])
          : payload.req[0]
      newAd = { ...newAd, ...payload.extra }
      state.ad.data = [...state.ad.data, newAd]
    },
    [requestAdFail]: state => {
      state.ad.loading = false
    },
    // Inputs
    [setSpecialtyInput]: (state, { payload }) => {
      state.loadingSpecialty = false
      state.specialtyInput = payload
      new Session().saveToCache('AOM', payload)
    }
  }
)

export default homeReducer
