function formQuery(payload, query, isOpenSearch) {
  // AND Operator
  // Specialty
  // if (payload.specialty) {
  //   query.searchTerms.must['tags.specialties'] = payload.specialty
  // } else {
  //   delete query.searchTerms.must['tags.specialties']
  // }
  query.searchTerms.should['tags.specialties'] =
    payload.specialty ||
    query.searchTerms.should['tags.specialties'] ||
    'General Medicine'

  // OR Operator
  // if (payload.topic) {
  //   query = handleTopic(payload, query)
  // } else {
  //   delete query.searchTerms.should
  // }
  return query
}

export { formQuery }
