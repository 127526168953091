import StaticFileLayout from 'layouts/StaticFileLayout'

const PrivacyBusiness = () => {
  const title = 'Business Contact Privacy Notice'
  const filename = 'PrivacyBusiness'

  return <StaticFileLayout title={title} filename={filename} />
}

export default PrivacyBusiness
