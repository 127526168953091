import { createReducer, createAction } from '@reduxjs/toolkit'
import SessionService from 'services/SessionService'

export const requestRegisterUser = createAction('REGISTER_USER')
export const requestRegisterUserSuccess = createAction('REGISTER_USER_SUCCESS')
export const requestRegisterUserFail = createAction('REGISTER_USER_FAIL')
export const requestUserProfile = createAction('REQUEST_USER_PROFILE')
export const requestUserProfileSuccess = createAction(
  'REQUEST_USER_PROFILE_SUCCESS'
)
export const requestUserProfileFail = createAction('REQUEST_USER_PROFILE_FAIL')
export const requestUpdateProfile = createAction('REQUEST_UPDATE_PROFILE')
export const requestUpdateProfileSuccess = createAction(
  'REQUEST_UPDATE_PROFILE_SUCCESS'
)
export const requestUpdateProfileFail = createAction(
  'REQUEST_UPDATE_PROFILE_FAIL'
)
export const resetRegisterState = createAction('RESET_REGISTER_REQUEST_STATE')
export const setRegisterStateSuccess = createAction(
  'RESET_REGISTER_REQUEST_STATE_SUCCESS'
)

export const actions = {
  requestRegisterUser,
  requestRegisterUserSuccess,
  requestRegisterUserFail,
  requestUserProfile,
  requestUserProfileSuccess,
  requestUserProfileFail,
  requestUpdateProfile,
  requestUpdateProfileSuccess,
  requestUpdateProfileFail,
  resetRegisterState,
  setRegisterStateSuccess
}

const userReducer = createReducer(
  {
    processingRegister: false,
    fetchingUserProfile: false,
    updatingUserProfile: false,
    registeredSuccess: false,
    registeredFailed: false,
    updateUserProfileSuccess: false,
    updateUserProfileFailed: false,
    profile: {}
  },
  {
    [requestRegisterUser]: state => {
      state.processingRegister = true
      state.registeredFailed = false
      state.registeredSuccess = false
    },
    [requestRegisterUserSuccess]: (state, { payload }) => {
      state.processingRegister = false
      state.registeredSuccess = true
      state.registeredFailed = false
      state.profile = {
        ...state.profile,
        ...payload
      }
    },
    [requestRegisterUserFail]: state => {
      state.processingRegister = false
      state.registeredSuccess = false
      state.registeredFailed = true
    },
    [requestUserProfile]: state => {
      state.fetchingUserProfile = true
      state.updateUserProfileFailed = false
      state.updateUserProfileSuccess = false
    },
    [requestUserProfileSuccess]: (state, { payload }) => {
      state.profile = {
        profession: payload.profession?.professionCode,
        professionId: payload.profession?.professionId,
        country: payload.address?.country,
        postalCode: payload.address?.postalCode,
        specialtyLima: payload.specialtyLima?.specialtyCode,
        specialtyIdLima: payload.specialtyLima?.specialtyId,
        specialty: payload.specialty?.specialtyCode,
        specialtyId: payload.specialty?.specialtyId,
        firstName: payload.firstName,
        lastName: payload.lastName
      }
      new SessionService().saveToCache(
        'CountryCode',
        payload.address?.countryCode
      )
      new SessionService().saveToCache(
        'ProfessionID',
        state.profile.professionId
      )
      new SessionService().saveToCache('SpecialtyID', state.profile.specialtyId)
      state.fetchingUserProfile = false
    },
    [requestUserProfileFail]: state => {
      state.fetchingUserProfile = false
    },
    [requestUpdateProfile]: state => {
      state.updatingUserProfile = true
      state.updateUserProfileFailed = false
      state.updateUserProfileSuccess = false
    },
    [requestUpdateProfileSuccess]: (state, { payload }) => {
      state.profile = {
        profession: payload.profession?.professionCode,
        professionId: payload.profession?.professionId,
        country: payload.address?.country,
        countryCode: payload.address?.countryCode,
        postalCode: payload.address?.postalCode,
        specialtyLima: payload.specialtyLima?.specialtyCode,
        specialtyIdLima: payload.specialtyLima?.specialtyId,
        specialty: payload.specialty?.specialtyCode,
        specialtyId: payload.specialty?.specialtyId,
        firstName: payload.firstName,
        lastName: payload.lastName
      }
      new SessionService().saveToCache(
        'CountryCode',
        payload.address?.countryCode
      )
      new SessionService().saveToCache(
        'ProfessionID',
        state.profile.professionId
      )
      new SessionService().saveToCache('SpecialtyID', state.profile.specialtyId)
      state.updatingUserProfile = false
      state.updateUserProfileFailed = false
      state.updateUserProfileSuccess = true
    },
    [requestUpdateProfileFail]: state => {
      state.updatingUserProfile = false
      state.updateUserProfileFailed = true
      state.updateUserProfileSuccess = false
    },
    [resetRegisterState]: state => {
      state.processingRegister = false
      state.registeredSuccess = false
      state.registeredFailed = false
      state.profile = {}
    }
  }
)

export default userReducer
