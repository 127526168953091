import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styles from './Footer.module.sass'
import { actions } from 'modules/home/HomeReducer'
import { getFooterSectionConfig } from 'modules/bootstrap/BootstrapSelector'
import config from 'site.config'

const {
  sections: { footer }
} = config

const Footer = () => {
  const dispatch = useDispatch()
  let [links, setLinks] = useState(footer.links)
  const breakpoint = 1040

  const footer_section = useSelector(getFooterSectionConfig)
  useEffect(() => {
    setLinks(footer_section?.links || footer.links)
  }, [footer_section])

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const menu = [
    {
      title: 'Curricula',
      icon: 'curricula-icon.png',
      url: '/curricula'
    },
    {
      title: 'Podcasts',
      icon: 'podcast-icon.png',
      url: '/podcasts'
    },
    {
      title: 'Live Events',
      icon: 'live-events-icon.png',
      url: '/live-events'
    },
    {
      title: 'News Feed',
      icon: 'news-feed-icon.png',
      url: '/#news'
    }
  ]

  return (
    <footer className={styles.bg}>
      <div className={styles.box}>
        <p className={[styles.psocial]}>
          {footer.social.title}
          {footer.social.links.map(({ title, icon, url }) => (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              key={title}
              aria-label={'Visit ' + title}
            >
              <img
                src={require('images/' + icon)}
                className={styles.imgSocial}
                alt={title}
              />
            </a>
          ))}
        </p>
        <div className={styles.footer}>
          {width < breakpoint
            ? links?.slice(0, 2).map(({ title }) => (
                <p key={title} className={styles.pcopy}>
                  {title}
                </p>
              ))
            : null}
          <p className={styles.pcopy}>
            {links
              ?.slice(width < breakpoint ? 2 : 0)
              .map(({ url, title }) => {
                if (!url)
                  return (
                    <span
                      className={styles.a}
                      key={title}
                      style={{ textDecoration: 'none', color: '#08487d' }}
                    >
                      {title}
                    </span>
                  )
                const isExternalLink =
                  url.startsWith('http') || url.startsWith('//')
                return isExternalLink ? (
                  <a
                    href={url}
                    key={url}
                    className={styles.a}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {title}
                  </a>
                ) : (
                  <Link to={url} key={url} className={styles.a}>
                    {title}
                  </Link>
                )
              })
              .reduce((prev, curr) => [prev, ` | `, curr])}
          </p>
        </div>
      </div>
      <div className={styles.mbox}>
        {menu.map(({ title, icon, url }) => {
          const html = (
            <>
              <LazyLoadImage
                alt={title}
                src={require('images/' + icon)}
                className={styles.micon}
                height={20}
              />
              <p className={styles.mtext}>{title}</p>
            </>
          )
          return (
            <Link
              to={url}
              className={styles.mbutton}
              key={title}
              onClick={() => {
                dispatch(actions.setSearchTerm(''))
                dispatch(actions.setSearchPanel(false))
              }}
            >
              {html}
            </Link>
          )
        })}
      </div>
    </footer>
  )
}
Footer.propTypes = {}

export default Footer
