/**
 * Constants used by the TrackingService.
 */
const customVarTotal = 10

const piwikCustomVars = {
  MemberID: '',
  EmailID: '',
  SpecialtyID: '',
  CountryID: '',
  qsession: '',
  ProfessionID: '',
  AOMID: '',
  AudienceID: '',
  PSLDeviceID: '',
  reCaptchaScore: '',
  PartySource: ''
}

export { piwikCustomVars, customVarTotal }
