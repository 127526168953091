import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { padEnd } from 'lodash'
import LoadingDots from 'components/LoadingDots'

import { dateFormat } from 'utils'
import * as creditSelector from './CMETrackerSelector'
import styles from './CreditList.module.sass'

const {
  getLoading,
  getCertList,
  getInProgressActivities,
  getInProgressActivitiesLoading
} = creditSelector

const CreditList = ({ year, shouldLoadData, loading }) => {
  const completedActivities = useSelector(getCertList)
  const inProgressActivities = useSelector(getInProgressActivities)
  const loadingCompletedActivities = useSelector(getLoading)
  const loadingInProgressActivities = useSelector(
    getInProgressActivitiesLoading
  )

  const panels = useMemo(
    () => [
      {
        title: 'Completed Activities',
        key: 'completed',
        activities: completedActivities
          ? completedActivities
              .filter(f => {
                if (year?.value === 'all') return true
                else {
                  let d = new Date(f.created * 1000)
                  return d.getFullYear() === +year?.value
                }
              })
              .sort((a, b) => b.created - a.created)
          : [],
        loading: loadingCompletedActivities
      },
      {
        title: 'In-Progress Activities',
        key: 'inProgress',
        activities: inProgressActivities
          ? [...inProgressActivities].sort(
              (a, b) => b.last_visited - a.last_visited
            )
          : [],
        loading: loadingInProgressActivities
      }
    ],
    [
      completedActivities,
      inProgressActivities,
      loadingCompletedActivities,
      loadingInProgressActivities,
      year
    ]
  )

  return panels.map(({ title, activities }) => {
    const isCompleted = title === 'Completed Activities'

    return (
      <div className={styles.container} key={title}>
        {!isCompleted && (
          <h2 className={styles.h2}>COMPLETE THESE ACTIVITIES FOR CREDIT</h2>
        )}
        <div className={styles.panelContent}>
          <div className={styles.header}>
            <div className={styles.titleCol}>Activity Title</div>
            <div className={styles.completionCol}>
              {isCompleted ? 'Completion/Credit' : 'Last Viewed/Credit'}
            </div>
          </div>
          {loading ? (
            <LoadingDots />
          ) : (
            <div className={styles.body}>
              {activities.length > 0 ? (
                activities
                  // .sort((a, b) => b.created - a.created)
                  .map(act => (
                    <div key={act.program} className={styles.row}>
                      <div className={styles.titleCol}>
                        <a
                          href={act.certificate_uri}
                          target="_blank"
                          rel="noreferrer"
                          dangerouslySetInnerHTML={{
                            __html: act.activity_title
                          }}
                        ></a>
                      </div>
                      <div
                        className={styles.completionCol}
                        style={{ color: 'black', paddingLeft: 10 }}
                      >
                        <p
                          style={{
                            fontSize: '16px',
                            lineHeight: '18px',
                            marginBottom: '3px'
                          }}
                        >
                          {dateFormat(
                            Number(
                              padEnd(
                                String(
                                  isCompleted ? act.created : act.last_visited
                                ),
                                13,
                                0
                              )
                            ),
                            'MMMM D, YYYY'
                          )}
                        </p>
                        <p>
                          {act.credits} {act.credit_type}
                        </p>
                      </div>
                    </div>
                  ))
              ) : (
                <div className={styles.noresult}>
                  {isCompleted
                    ? 'No completed activities'
                    : 'No in-progress activities'}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  })
}

CreditList.propTypes = {
  /**
   * NEML Token to call API
   */
  nemlToken: PropTypes.object,
  /**
   * Selected year
   */
  year: PropTypes.object,
  /**
   * Whether is loading
   */
  loading: PropTypes.bool,
  /**
   * Check if should load data
   */
  shouldLoadData: PropTypes.bool
}

export default CreditList
