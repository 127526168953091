import { createReducer, createAction } from '@reduxjs/toolkit'

export const requestToken = createAction('AUTH__REQUEST')
export const successAuth = createAction('AUTH__REQUEST__SUCCESS')
export const errorAuth = createAction('AUTH__ERROR')
export const updateToken = createAction('AUTH__UPDATE_TOKEN')
export const updateProfile = createAction('AUTH__UPDATE_PROFILE')

export const loginWithEmailRequest = createAction('LOGIN_WITH_EMAIL_REQUEST')
export const loginWithEmailRequestSuccess = createAction(
  'LOGIN_WITH_EMAIL_REQUEST_SUCCESS'
)
export const loginWithEmailRequestFail = createAction(
  'LOGIN_WITH_EMAIL_REQUEST_FAIL'
)
export const loginWithPasswordRequest = createAction(
  'LOGIN_WITH_PASSWORD_REQUEST'
)
export const loginWithPasswordRequestSuccess = createAction(
  'LOGIN_WITH_PASSWORD_REQUEST_SUCCESS'
)
export const loginWithPasswordRequestFail = createAction(
  'LOGIN_WITH_PASSWORD_REQUEST_FAIL'
)
export const openLoginModal = createAction('OPEN_LOGIN_MODAL')
export const closeLoginModal = createAction('CLOSE_LOGIN_MODAL')
export const updateTemporaryToken = createAction('UPDATE_TEMPORARY_TOKEN')
export const validateOtpRequest = createAction('VALIDATE_OTP_REQUEST')

export const requestNemlToken = createAction('AUTH_NEML_REQUEST')
export const successNemlAuth = createAction('AUTH_NEML_REQUEST_SUCCESS')
export const errorNemlAuth = createAction('AUTH_NEML_ERROR')

export const invalidateToken = createAction('AUTH__LOGOUT')

export const actions = {
  requestToken,
  successAuth,
  errorAuth,
  updateToken,
  updateProfile,
  loginWithEmailRequest,
  loginWithEmailRequestSuccess,
  loginWithEmailRequestFail,
  loginWithPasswordRequest,
  loginWithPasswordRequestSuccess,
  loginWithPasswordRequestFail,
  openLoginModal,
  closeLoginModal,
  updateTemporaryToken,
  validateOtpRequest,
  requestNemlToken,
  successNemlAuth,
  errorNemlAuth,
  invalidateToken
}
export const stages = {
  UNINITIALIZATED: 'UNINITIALIZATED',
  REQUESTING: 'REQUESTING',
  INITIALIZED: 'INITIALIZED',
  FAILED: 'FAILED'
}

const authReducer = createReducer(
  {
    authToken: null,
    nemlToken: null,
    verifying: true,
    verified: false,
    error: false,
    profile: null,
    loading: false,
    pendingOtp: false,
    openModal: false,
    temporaryToken: null,
    verifyingOtp: false,
    redirectRoute: '',
    loginErrMsg: ''
  },
  {
    [requestToken]: state => {
      state.verifying = true
      state.verified = false
      state.error = false
      state.authToken = null
      state.profile = null
    },
    [successAuth]: (state, { payload }) => {
      let formattedProfile = payload.tokens ? payload : null
      if (!formattedProfile && payload.access)
        formattedProfile = {
          tokens: payload
        }

      state.verifying = false
      state.verified = true
      state.error = false
      state.profile = formattedProfile
      state.verifyingOtp = false
      state.loggedOut = false
    },
    [errorAuth]: state => {
      state.verifying = false
      state.error = true
      state.verified = false
      state.verifyingOtp = false
    },
    [updateToken]: (state, { payload }) => {
      state.error = true
      state.verified = false
      state.profile = payload
        ? {
            ...state.profile,
            tokens: payload
          }
        : null
    },
    [updateProfile]: (state, { payload }) => {
      if (payload.profession) {
        state.profile.info.user.professionId = payload.profession.professionId
        state.profile.info.user.profession = payload.profession.professionCode
      }
      if (payload.specialty) {
        state.profile.info.user.specialtyId = payload.specialty.specialtyId
        state.profile.info.user.specialty = payload.specialty.specialtyCode
      }
    },
    [loginWithEmailRequest]: state => {
      state.loading = true
    },
    [loginWithEmailRequestSuccess]: state => {
      state.loading = false
      state.pendingOtp = true
      state.openModal = true
    },
    [loginWithEmailRequestFail]: state => {
      state.loading = false
      state.pendingOtp = false
      state.verifyingOtp = false
    },
    [loginWithPasswordRequest]: state => {
      state.loading = true
      state.loginErrMsg = ''
    },
    [loginWithPasswordRequestSuccess]: state => {
      state.loading = false
      state.openModal = false
      state.loginErrMsg = ''
      state.loggedOut = false
    },
    [loginWithPasswordRequestFail]: (state, msg) => {
      state.loading = false
      state.openModal = true
      state.loginErrMsg = msg.payload
    },
    [openLoginModal]: (state, { payload }) => {
      state.openModal = true
      state.redirectRoute = payload.route
    },
    [closeLoginModal]: state => {
      state.loading = false
      state.openModal = false
      state.pendingOtp = false
      state.verifyingOtp = false
      state.loginErrMsg = ''
    },
    [updateTemporaryToken]: (state, { payload }) => {
      const token = payload.token.substring(payload.token.indexOf('otp=') + 4)
      state.temporaryToken = token
    },
    [validateOtpRequest]: (state, { payload }) => {
      state.temporaryToken = null
      state.verifyingOtp = true
    },
    [requestNemlToken]: state => {
      state.loading = true
      state.nemlToken = null
    },
    [successNemlAuth]: (state, { payload }) => {
      state.nemlToken = payload
      state.loading = false
    },
    [errorNemlAuth]: state => {
      state.nemlToken = null
      state.loading = false
    },
    [invalidateToken]: state => {}
  }
)

export default authReducer
