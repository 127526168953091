import { createReducer, createAction } from '@reduxjs/toolkit'

const submitContactForm = createAction('SUBMIT_CONTACT_FORM')
const submitFeedbackForm = createAction('SUBMIT_FEEDBACK_FORM')
const submitContactFormSuccess = createAction('SUBMIT_CONTACT_FORM_SUCCESS')
const submitContactFormFail = createAction('SUBMIT_CONTACT_FORM_FAIL')
const resetContactFormState = createAction('RESET_CONTACT_FORM_STATE')

export const actions = {
  submitContactForm,
  submitFeedbackForm,
  submitContactFormSuccess,
  submitContactFormFail,
  resetContactFormState
}

const contactFormReducer = createReducer(
  {
    loading: false,
    submitted: false,
    submittedSuccess: false,
    submittedFailed: false
  },
  {
    [submitContactForm]: state => {
      state.loading = true
    },
    [submitFeedbackForm]: state => {
      state.loading = true
      state.submitted = false
      state.submittedSuccess = false
      state.submittedFailed = false
    },
    [submitContactFormSuccess]: (state, { payload }) => {
      state.loading = false
      state.submitted = true
      state.submittedSuccess = true
      state.submittedFailed = false
    },
    [submitContactFormFail]: state => {
      state.loading = false
      state.submitted = false
      state.submittedSuccess = false
      state.submittedFailed = true
    },
    [resetContactFormState]: state => {
      state.submitted = false
      state.submittedSuccess = false
      state.submittedFailed = false
    }
  }
)

export default contactFormReducer
