import StaticFileLayout from 'layouts/StaticFileLayout'

const PrivacyEU = () => {
  const title = 'EU Privacy Policy'
  const filename = 'PrivacyEU'

  return <StaticFileLayout title={title} filename={filename} />
}

export default PrivacyEU
