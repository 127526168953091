import StaticFileLayout from 'layouts/StaticFileLayout'

const CookiePolicy = () => {
  const title = 'Cookie Policy'
  const filename = 'Cookie'

  return <StaticFileLayout title={title} filename={filename} />
}

export default CookiePolicy
