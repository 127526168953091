import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from './UnsubscribeReducer'
import config from 'site.config'

const {
  configuration: { envVars }
} = config

export function* performUnsubscribe(services, { payload }) {
  const baseUrl = envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
  const data = payload.payload
  try {
    const apiService = services('APIService')
    const subUrl = '/v2/user/unsubscribe'
    const url = baseUrl + subUrl
    const additionalHeader = {
      Authorization: `Bearer ${payload.authToken.access}`
    }
    yield call([apiService, 'postWithHeaders'], url, data, additionalHeader)
    yield put(actions.actionUnsubscribedSuccess())
  } catch (e) {
    yield put(actions.actionUnsubscribedFailed())
  }
}

export function* getObfuscatedEmail(services, { payload }) {
  const apiService = services('APIService')
  const sessionService = services('SessionService')
  const baseUrl = envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
  const subUrl = '/v2/user/unsubscribe/email'
  const apiUrl = baseUrl + subUrl

  // try to get MemberID and EmailID from cookie and get obfuscated email
  const memberId = sessionService.getFromCache('MemberID', '')
  const emailId = sessionService.getFromCache('EmailID', '')

  if (memberId !== '' && emailId !== '') {
    try {
      // get auth token with memberid and emailid
      const authToken = yield call(
        [services('AuthService'), 'getUserTokenFromPhoenix'],
        baseUrl,
        parseInt(
          envVars?.REACT_APP_PHNX_SITEID ?? process.env.REACT_APP_PHNX_SITEID
        ),
        {
          MemberID: parseInt(memberId),
          EmailID: parseInt(emailId)
        }
      )

      const additionalHeader = {
        Authorization: `Bearer ${authToken.access}`
      }
      // fetch obfuscated email with new auth token
      const result = yield call(
        [apiService, 'getWithHeaders'],
        apiUrl,
        {},
        additionalHeader
      )
      result.authToken = authToken
      yield put(actions.actionGetObfuscatedEmailSuccess(result))
    } catch (e) {
      yield put(actions.actionGetObfuscatedEmailFailed())
    }
  } else {
    // if memberid and/or emailid is empty
    yield put(actions.actionGetObfuscatedEmailFailed())
  }
}

export default function* watchUnsubscribe(services) {
  yield all([
    takeEvery(actions.actionPerformUnsubscribe, performUnsubscribe, services),
    takeEvery(actions.actionGetObfuscatedEmail, getObfuscatedEmail, services)
  ])
}
