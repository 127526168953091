import { piwikCustomVars, customVarTotal } from '../constants'
import { setCustomVariable } from '@psl/piwik'
import Session from './SessionService'
import { transformTrackingUrl } from 'utils'

let newVisit = true
let initialized = false
let _piwikCustomVars = piwikCustomVars
let SessionService = new Session()
let deferred = []

const TrackingService = () => ({
  /**
   * Singleton service instance.
   *
   * @type {TrackingService}
   */
  initializeTrackingVars() {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.PIWIK_DEV_ENABLED === 'false'
    ) {
      return
    }
    _piwikCustomVars = piwikCustomVars
    // this.setTrackerVars()
    this.runDeferred()
  },

  /**
   * Function that initialize the tracking service
   *
   * @param {srtring} url
   * @param {number} siteId
   * @param {string} scriptName
   * @param {string} scriptUrl
   * @param {boolean} secure
   */
  initializeTrackingScript({
    url,
    siteId,
    scriptName,
    scriptUrl = '',
    secure = false
  }) {
    window._paq = window._paq || []

    url = transformTrackingUrl(url, secure)

    window._paq.push(['setSiteId', siteId])
    window._paq.push(['setTrackerUrl', url])

    const scriptElement = document.createElement('script')
    const refElement = document.getElementsByTagName('script')[0]
    scriptElement.type = 'text/javascript'
    scriptElement.defer = true
    scriptElement.async = true
    scriptElement.src = '' + (scriptUrl || url) + scriptName
    refElement.parentNode.insertBefore(scriptElement, refElement)

    initialized = true
    if (!initialized) throw new Error('Piwik not initialized')
    let i = 1
    for (const key in _piwikCustomVars) {
      const cachedValue = SessionService.getFromCache(key, 0)
      setCustomVariable(i++, key, cachedValue, 'visit')
    }
    this.runDeferred()
  },

  resetVisitCustomVariables() {
    let i = 1
    for (const key in _piwikCustomVars) {
      const cachedValue = SessionService.getFromCache(key, 0)
      setCustomVariable(i++, key, cachedValue, 'visit')
    }
  },

  /**
   * Sets the custom tracker vars from cached session values up to the maximum `customVarTotal`
   * Only sets tracker vars once
   *
   * @returns void
   */
  // setTrackerVars() {
  // },

  /**
   * Tracks an action to Piwik server.
   * Used by the tracking methods below.
   *
   * @param action
   * @param params – should never be more than 10 at a time
   * @param newVisit – set only once per curricula load
   * @returns void
   */
  track(action, params = {}) {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_PIWIK_DEV_ENABLED === 'false'
    ) {
      return
    }
    if (!initialized) {
      return this.deferTrack({ action, params })
    }
    if (newVisit) {
      this.forceNewVisit()
      newVisit = false
    } else {
      this.cleanForceNewVisit()
    }

    // Clear old 'page' values.
    let i = 1
    for (i; i <= customVarTotal; i++) {
      setCustomVariable(i, '', '', 'page')
    }

    // Push new 'page' values.
    i = 1
    for (let [key, value] of Object.entries(params)) {
      if (key !== '') {
        setCustomVariable(i++, key, value, 'page')
      }
      if (i > customVarTotal) {
        break
      }
    }

    this.updateUrl(window.location.href)
    // Custom dimension for user agent
    this.updateDimension(1, navigator.userAgent)
    // disable media tracking
    this.removeMediaAnalyticsPlayer()
    this.disableMediaAnalytics()

    // Track page view
    this.trackPageview(action)
  },

  /**
   * remove certain player from the tracking functionality
   * Ref: https://developer.matomo.org/guides/media-analytics/reference
   */
  disableMediaAnalytics() {
    window['_paq'].push(['MediaAnalytics::disableMediaAnalytics'])
  },

  /**
   * remove certain player from the tracking functionality
   * Ref: https://developer.matomo.org/guides/media-analytics/reference
   */
  removeMediaAnalyticsPlayer() {
    window['_paq'].push(['MediaAnalytics::removePlayer', 'jwplayer'])
    window['_paq'].push(['MediaAnalytics::removePlayer', 'html5'])
  },

  forceNewVisit() {
    if (!initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['appendToTrackingUrl', 'new_visit=1'])
    window._paq.push(['deleteCookies'])
  },

  cleanForceNewVisit() {
    if (!initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window._paq.push(['appendToTrackingUrl', ''])
  },

  trackEvent(action) {
    if (!initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['trackEvent', action])
  },

  updateUrl(url) {
    if (!initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['setCustomUrl', url])
  },

  updateDimension(id, userAgent) {
    if (!initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['setCustomDimension', id, userAgent])
  },

  trackPageview(action) {
    if (!initialized) throw new Error('Piwik not initialized')
    // This is a direct usage of the Piwik API.
    // We can move this to @psl/Piwik lib
    // Ref: https://developer.matomo.org/guides/tracking-javascript#configuration-of-the-tracker-object
    window['_paq'].push(['trackPageView', action])
  },

  /**
   * Defers an action to be tracked after initialization
   *
   * @param action
   * @param params – should never be more than 10 at a time
   * @returns the number of actions to be tracked after intialization
   */
  deferTrack({ action, params }) {
    return deferred.push({ action, params })
  },

  /**
   * Runs all deferred tracking actions in the `this.deferred` array
   *
   * @returns void
   */
  runDeferred() {
    deferred.forEach(({ action, params }) => {
      this.track(action, params)
    })
  }
})

export default TrackingService
