import { createContext, Component } from 'react'
import PropTypes from 'prop-types'

export const context = createContext(i => i)

const { Provider } = context

export function createServiceProvider({ resolve }) {
  function ServiceProvider({ children }) {
    return <Provider value={resolve}>{children}</Provider>
  }
  ServiceProvider.propTypes = {
    children: PropTypes.node
  }
  return ServiceProvider
}

export const withServices = mapServicesToProps => WrappedComponent => {
  class WithServices extends Component {
    static contextType = context
    render() {
      const services = mapServicesToProps(this.context)
      return <WrappedComponent services={services} {...this.props} />
    }
  }
  const wrappedDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  WithServices.displayName = `WithServices(${wrappedDisplayName})`
  return WithServices
}
