const profileMapping = [
  {
    label: 'Abdominal Radiology',
    value: 1
  },
  {
    label: 'Abdominal Surgery',
    value: 2
  },
  {
    label: 'Addiction Medicine',
    value: 3
  },
  {
    label: 'Addiction Psychiatry',
    value: 4
  },
  {
    label: 'Adolescent Medicine',
    value: 5
  },
  {
    label: 'Adult Reconstructive Orthopaedics',
    value: 6
  },
  {
    label: 'Aerospace Medicine',
    value: 7
  },
  {
    label: 'Allergy',
    value: 8
  },
  {
    label: 'Allergy/Immunology',
    value: 9
  },
  {
    label: 'Anatomic & Clinical Pathology',
    value: 10
  },
  {
    label: 'Anatomic Pathology',
    value: 11
  },
  {
    label: 'Anesthesiology',
    value: 12
  },
  {
    label: 'Bariatric Surgery',
    value: 13
  },
  {
    label: 'Blood Banking/Transfusion Medicine',
    value: 14
  },
  {
    label: 'Bone Marrow Transplantation',
    value: 15
  },
  {
    label: 'Cardiac Electrophysiology',
    value: 16
  },
  {
    label: 'Cardiac Surgery',
    value: 17
  },
  {
    label: 'Cardiology',
    value: 18
  },
  {
    label: 'Cardiothoracic Surgery',
    value: 19
  },
  {
    label: 'Chemical Pathology',
    value: 20
  },
  {
    label: 'Clinical & Lab Immunology',
    value: 21
  },
  {
    label: 'Clinical Biochemical Genetics',
    value: 22
  },
  {
    label: 'Clinical Cytogenetics',
    value: 23
  },
  {
    label: 'Clinical Genetics',
    value: 24
  },
  {
    label: 'Clinical Molecular Genetics',
    value: 25
  },
  {
    label: 'Clinical Neurophysiology',
    value: 26
  },
  {
    label: 'Clinical Pathology',
    value: 27
  },
  {
    label: 'Clinical Pharmacology',
    value: 28
  },
  {
    label: 'Colorectal Surgery',
    value: 29
  },
  {
    label: 'Community Medicine',
    value: 30
  },
  {
    label: 'Cosmetic Dermatology',
    value: 31
  },
  {
    label: 'Craniofacial Surgery',
    value: 32
  },
  {
    label: 'Critical Care Medicine',
    value: 33
  },
  {
    label: 'Critical Care Surgery',
    value: 34
  },
  {
    label: 'Cytopathology',
    value: 35
  },
  {
    label: 'Dermatologic Surgery',
    value: 36
  },
  {
    label: 'Dermatology',
    value: 37
  },
  {
    label: 'Dermatopathology',
    value: 38
  },
  {
    label: 'Developmental-Behavioral Pediatrics',
    value: 39
  },
  {
    label: 'Diabetes',
    value: 40
  },
  {
    label: 'Diagnostic Radiology',
    value: 41
  },
  {
    label: 'Electroencephalography',
    value: 42
  },
  {
    label: 'Electrophysiology',
    value: 43
  },
  {
    label: 'Emergency Medicine',
    value: 44
  },
  {
    label: 'Endocrinology',
    value: 45
  },
  {
    label: 'Epvalueemiology',
    value: 46
  },
  {
    label: 'Experimental Pathology',
    value: 47
  },
  {
    label: 'Family Practice',
    value: 48
  },
  {
    label: 'Foot & Ankle Orthopedics',
    value: 49
  },
  {
    label: 'Forensic Medicine',
    value: 50
  },
  {
    label: 'Forensic Pathology',
    value: 51
  },
  {
    label: 'Forensic Psychiatry',
    value: 52
  },
  {
    label: 'Gastroenterology',
    value: 53
  },
  {
    label: 'General Pathology',
    value: 54
  },
  {
    label: 'Primary Care',
    value: 55
  },
  {
    label: 'General Surgery',
    value: 56
  },
  {
    label: 'Genetics',
    value: 57
  },
  {
    label: 'Genitourinary Disorders',
    value: 58
  },
  {
    label: 'Geriatric Medicine',
    value: 59
  },
  {
    label: 'Geriatric Psychiatry',
    value: 60
  },
  {
    label: 'Geriatrics',
    value: 61
  },
  {
    label: 'Glaucoma Ophthalmology',
    value: 62
  },
  {
    label: 'Gynecological Oncology',
    value: 63
  },
  {
    label: 'Gynecological Surgery',
    value: 64
  },
  {
    label: 'Gynecological Urology',
    value: 65
  },
  {
    label: 'Gynecology',
    value: 66
  },
  {
    label: 'Hand Surgery',
    value: 67
  },
  {
    label: 'Head & Neck Surgery',
    value: 68
  },
  {
    label: 'Hematology',
    value: 69
  },
  {
    label: 'Hematology-Oncology',
    value: 70
  },
  {
    label: 'Hepatology',
    value: 71
  },
  {
    label: 'Hospitalist',
    value: 73
  },
  {
    label: 'Immunology',
    value: 74
  },
  {
    label: 'Industrial Medicine',
    value: 75
  },
  {
    label: 'Infectious Disease',
    value: 76
  },
  {
    label: 'Intensive Care',
    value: 77
  },
  {
    label: 'Internal Medicine',
    value: 78
  },
  {
    label: 'Interventional Cardiology',
    value: 79
  },
  {
    label: 'Laser Surgery',
    value: 80
  },
  {
    label: 'Legal Medicine',
    value: 81
  },
  {
    label: 'Maternal & Fetal Medicine',
    value: 82
  },
  {
    label: 'Medical Genetics',
    value: 83
  },
  {
    label: 'Medical Management',
    value: 84
  },
  {
    label: 'Medical Microbiology',
    value: 85
  },
  {
    label: 'Medical Physiology',
    value: 86
  },
  {
    label: 'Medical Toxicology',
    value: 87
  },
  {
    label: 'Metabolism',
    value: 88
  },
  {
    label: 'Military Medicine',
    value: 89
  },
  {
    label: 'Molecular Genetic Pathology',
    value: 90
  },
  {
    label: 'Neonatal-Perinatal Medicine',
    value: 91
  },
  {
    label: 'Nephrology',
    value: 92
  },
  {
    label: 'Neurodevelopmental Disabilities',
    value: 93
  },
  {
    label: 'Neurology',
    value: 94
  },
  {
    label: 'Neurology & Psychiatry',
    value: 95
  },
  {
    label: 'Neuroradiology',
    value: 96
  },
  {
    label: 'Rehabilitation Neurology',
    value: 97
  },
  {
    label: 'Neuropathology',
    value: 98
  },
  {
    label: 'Neuropsychology',
    value: 99
  },
  {
    label: 'Neuroradiology',
    value: 100
  },
  {
    label: 'Neurosurgery',
    value: 101
  },
  {
    label: 'Nuclear Cardiology',
    value: 102
  },
  {
    label: 'Nuclear Medicine',
    value: 103
  },
  {
    label: 'Nuclear Radiology',
    value: 104
  },
  {
    label: 'Obstetrics',
    value: 105
  },
  {
    label: 'Obstetrics/Gynecology',
    value: 106
  },
  {
    label: 'Occupational Medicine',
    value: 107
  },
  {
    label: 'Oncology',
    value: 108
  },
  {
    label: 'Ophthalmology',
    value: 109
  },
  {
    label: 'Oral & Maxillofacial Surgery',
    value: 110
  },
  {
    label: 'Oral Pathology',
    value: 111
  },
  {
    label: 'Orthopedic Surgery',
    value: 112
  },
  {
    label: 'Orthopedic Surgery of the Spine',
    value: 113
  },
  {
    label: 'Orthopedic Trauma',
    value: 114
  },
  {
    label: 'Orthopedics',
    value: 115
  },
  {
    label: 'Osteopathic Manipulative Medicine',
    value: 116
  },
  {
    label: 'Otolaryngology',
    value: 117
  },
  {
    label: 'Otology',
    value: 118
  },
  {
    label: 'Pain Medicine',
    value: 119
  },
  {
    label: 'Palliative Medicine',
    value: 120
  },
  {
    label: 'Parasitology',
    value: 121
  },
  {
    label: "Parkinson's Disease",
    value: 122
  },
  {
    label: 'Pathology',
    value: 123
  },
  {
    label: 'Pediatric & Adolescent Psychiatry',
    value: 124
  },
  {
    label: 'Pediatric Allergy',
    value: 125
  },
  {
    label: 'Pediatric Anesthesiology',
    value: 126
  },
  {
    label: 'Pediatric Cardiology',
    value: 127
  },
  {
    label: 'Pediatric Cardiothoracic Surgery',
    value: 128
  },
  {
    label: 'Pediatric Critical Care Medicine',
    value: 129
  },
  {
    label: 'Pediatric Dermatology',
    value: 130
  },
  {
    label: 'Pediatric Emergency Medicine',
    value: 131
  },
  {
    label: 'Pediatric Endocrinology',
    value: 132
  },
  {
    label: 'Pediatric Gastroenterology',
    value: 133
  },
  {
    label: 'Pediatric General Surgery',
    value: 134
  },
  {
    label: 'Pediatric Hematology',
    value: 135
  },
  {
    label: 'Pediatric Hematology-Oncology',
    value: 136
  },
  {
    label: 'Pediatric Infectious Diseases',
    value: 137
  },
  {
    label: 'Pediatric Nephrology',
    value: 138
  },
  {
    label: 'Pediatric Neurology',
    value: 139
  },
  {
    label: 'Pediatric Oncology',
    value: 140
  },
  {
    label: 'Pediatric Ophthalmology',
    value: 141
  },
  {
    label: 'Pediatric Orthopedics',
    value: 142
  },
  {
    label: 'Pediatric Otolaryngology',
    value: 143
  },
  {
    label: 'Pediatric Pathology',
    value: 144
  },
  {
    label: 'Pediatric Psychiatry',
    value: 145
  },
  {
    label: 'Pediatric Pulmonology',
    value: 146
  },
  {
    label: 'Pediatric Radiology',
    value: 147
  },
  {
    label: 'Pediatric Rehabilitation Medicine',
    value: 148
  },
  {
    label: 'Pediatric Rheumatology',
    value: 149
  },
  {
    label: 'Pediatric Surgery',
    value: 150
  },
  {
    label: 'Pediatric Urology',
    value: 151
  },
  {
    label: 'Pediatrics',
    value: 152
  },
  {
    label: 'Perinatology',
    value: 153
  },
  {
    label: 'Pharmaceutical Medicine',
    value: 154
  },
  {
    label: 'Physical Medicine & Rehabilitation',
    value: 155
  },
  {
    label: 'Plastic Surgery',
    value: 156
  },
  {
    label: 'Preventive Medicine',
    value: 157
  },
  {
    label: 'Proctology',
    value: 158
  },
  {
    label: 'Psychiatry',
    value: 159
  },
  {
    label: 'Psychosomatic Medicine',
    value: 160
  },
  {
    label: 'Public Health Medicine',
    value: 161
  },
  {
    label: 'Radiation Oncology',
    value: 162
  },
  {
    label: 'Radiological Physics',
    value: 163
  },
  {
    label: 'Radiology',
    value: 164
  },
  {
    label: 'Reproductive Endocrinology',
    value: 165
  },
  {
    label: 'Research',
    value: 166
  },
  {
    label: 'Pulmonology',
    value: 167
  },
  {
    label: 'Rheumatology',
    value: 168
  },
  {
    label: 'Selective Pathology',
    value: 169
  },
  {
    label: 'Sleep Medicine',
    value: 170
  },
  {
    label: 'Spinal Cord Injury',
    value: 171
  },
  {
    label: 'Sports Medicine',
    value: 172
  },
  {
    label: 'Surgical Oncology',
    value: 173
  },
  {
    label: 'Thoracic Surgery',
    value: 174
  },
  {
    label: 'Transplantation Surgery',
    value: 175
  },
  {
    label: 'Trauma Surgery',
    value: 176
  },
  {
    label: 'Tropical Diseases',
    value: 177
  },
  {
    label: 'Tuberculosis',
    value: 178
  },
  {
    label: 'Undersea Medicine & Hyperbaric Medicine',
    value: 179
  },
  {
    label: 'Unknown',
    value: 180
  },
  {
    label: 'Urology',
    value: 181
  },
  {
    label: 'Vascular & Interventional Radiology',
    value: 182
  },
  {
    label: 'Vascular Medicine',
    value: 183
  },
  {
    label: 'Vascular Neurology',
    value: 184
  },
  {
    label: 'Vascular Surgery',
    value: 185
  },
  {
    label: 'Virology',
    value: 186
  },
  {
    label: 'Vitreoretinal Medicine',
    value: 187
  },
  {
    label: 'Academic Pharmacist',
    value: 188
  },
  {
    label: 'Ambulatory Care Pharmacist',
    value: 189
  },
  {
    label: 'Biochemistry',
    value: 190
  },
  {
    label: 'Biology',
    value: 191
  },
  {
    label: 'Biomedicine',
    value: 192
  },
  {
    label: 'Certified Diabetes Educator',
    value: 193
  },
  {
    label: 'Certified Nurse Mvaluewife',
    value: 194
  },
  {
    label: 'Certified Nursing Assistant',
    value: 195
  },
  {
    label: 'Certified Registered Nurse Anaesthetist',
    value: 196
  },
  {
    label: 'Chiropodiatry',
    value: 197
  },
  {
    label: 'Chiropracter',
    value: 198
  },
  {
    label: 'Clinical & Lab Dermatological Immunology',
    value: 199
  },
  {
    label: 'Clinical Chemistry',
    value: 200
  },
  {
    label: 'Clinical Nurse Specialist',
    value: 201
  },
  {
    label: 'Coroner',
    value: 202
  },
  {
    label: 'Endodontics',
    value: 203
  },
  {
    label: 'General Dentistry',
    value: 204
  },
  {
    label: 'Physician Assistant',
    value: 205
  },
  {
    label: 'Government Pharmacist',
    value: 206
  },
  {
    label: 'Hematologic Malignancies',
    value: 207
  },
  {
    label: 'Home Care Pharmacist',
    value: 208
  },
  {
    label: 'Homeopathy',
    value: 209
  },
  {
    label: 'Hospice Pharmacist',
    value: 210
  },
  {
    label: 'Hospital Pharmacist',
    value: 211
  },
  {
    label: 'Independent Community Pharmacist',
    value: 212
  },
  {
    label: 'Industrial Pharmacist',
    value: 213
  },
  {
    label: 'Licensed Practical Nurse',
    value: 214
  },
  {
    label: 'Licensed Vocational Nurse',
    value: 215
  },
  {
    label: 'Long Term Care Pharmacist',
    value: 216
  },
  {
    label: 'Managed Care Pharmacist',
    value: 217
  },
  {
    label: 'Medical Assistant',
    value: 218
  },
  {
    label: 'Medical Management / Administration',
    value: 219
  },
  {
    label: 'Medical Research Science',
    value: 220
  },
  {
    label: 'Medical Student',
    value: 221
  },
  {
    label: 'Medical Technician',
    value: 222
  },
  {
    label: 'Military Pharmacist',
    value: 223
  },
  {
    label: 'Nurse Practitioners',
    value: 224
  },
  {
    label: 'Nutritionist / Dietician',
    value: 225
  },
  {
    label: 'Oncology Nursing',
    value: 226
  },
  {
    label: 'Oncology Pharmacist',
    value: 227
  },
  {
    label: 'Optometry',
    value: 228
  },
  {
    label: 'Orthodontics',
    value: 229
  },
  {
    label: 'Pediatric Dentistry',
    value: 230
  },
  {
    label: 'Periodontics',
    value: 231
  },
  {
    label: 'Pharmacist',
    value: 232
  },
  {
    label: 'Podiatry',
    value: 233
  },
  {
    label: 'Prosthodontics',
    value: 234
  },
  {
    label: 'Psychoanalysis',
    value: 235
  },
  {
    label: 'Psychology',
    value: 236
  },
  {
    label: 'Psychotherapy',
    value: 237
  },
  {
    label: 'Registered Nurse',
    value: 238
  },
  {
    label: 'Retail/Community Pharmacist',
    value: 239
  },
  {
    label: 'Veterinarian',
    value: 240
  },
  {
    label: 'Veterinary Pharmacist',
    value: 241
  }
]

export default profileMapping
