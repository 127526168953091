import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from './DeviceIdReducer'
import { isEmpty } from 'utils'
import config from 'site.config'

const { deviceID, deviceIdEndpoint } = config.configuration

/**
 * Retrieves the deviceID
 * @param services
 * @param useCache
 */
export function* getDeviceId(services, useCache) {
  try {
    const SessionService = services('SessionService')
    const deviceService = services('DeviceIdService')
    const [userID] = yield all([
      // GET USER ID FOR PROFILE
      call([SessionService, 'getFromCache'], 'uuid', ''),
      // LOAD DEVICE-ID SCRIPT
      call([deviceService, 'initialize'], { deviceIdEndpoint })
    ])
    // GET DEVICE-ID
    const { deviceId, relevantId } = yield call(
      [deviceService, 'getDeviceId'],
      {
        ...deviceID,
        onlineId: userID,
        useCache
      }
    )
    yield call([SessionService, 'saveToCache'], 'deviceId', relevantId)
    yield call([SessionService, 'saveToCache'], 'PSLDeviceID', deviceId)
  } catch (e) {
    console.error(e)
  } finally {
    yield put(actions.deviceIdReady())
  }
}

/**
 * - initial deviceID process for AUTH
 * - to make it faster eval if device id exists in cookies or local
 * - If deviceId/id_key does not exists - init script and get one
 * @param services
 * @param useCache
 */
export function* deviceIdFull(services, useCache) {
  try {
    const SessionService = services('SessionService')
    const [deviceIdCache, deviceCookie] = yield all([
      call([SessionService, 'getFromCache'], 'PSLDeviceID'),
      call([SessionService, 'getFromCache'], 'deviceId')
    ])

    //IF DEVICE-ID NOT IN COOKIES, REQUEST NEW ONE
    if (isEmpty(deviceIdCache)) {
      if (isEmpty(deviceCookie)) {
        yield call(getDeviceId, services, useCache)
      } else {
        //IF DEVICE-ID IN COOKIES, UPDATE CACHE ID_KEY
        yield call([SessionService, 'saveToCache'], { deviceId: deviceCookie })
        yield call([SessionService, 'saveToCache'], {
          PSLDeviceID: deviceIdCache
        })
      }
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * @param services
 */
export default function* watchDevice(services) {
  yield all([takeEvery('DEVICEID_GET', getDeviceId, services)])
}
