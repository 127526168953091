import PropTypes, { any } from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actions } from 'modules/home/HomeReducer'

function HeaderLink({ styles, logo, alt, tagline }) {
  const dispatch = useDispatch()

  return (
    <Link
      to="/"
      className={styles?.a}
      onClick={() => dispatch(actions.setSearchTerm(''))}
    >
      <img
        className={styles?.img}
        src={require('images/' + logo.src)}
        alt={alt}
      />
      <p style={{ textAlign: 'center' }}>{tagline}</p>
    </Link>
  )
}

HeaderLink.propTypes = {
  styles: any,
  logo: any,
  alt: PropTypes.string,
  tagline: PropTypes.string
}

export default HeaderLink
