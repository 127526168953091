import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'

import notificationReducer from 'modules/notification/NotificationReducer'
import bootstrapReducer from 'modules/bootstrap/BootstrapReducer'
import authReducer from 'modules/auth/AuthReducer'
import optinReducer from 'modules/optin/OptinReducer'
import contactFormReducer from 'modules/contactform/ContactFormReducer'
import contactUsReducer from 'modules/contactus/ContactUsReducer'
import homeReducer from 'modules/home/HomeReducer'
import cmeTrackerReducer from 'modules/cme-tracker/CMETrackerReducer'
import userReducer from 'modules/user/UserReducer'
import unsubscribeReducer from 'modules/unsubscribe/UnsubscribeReducer'
import deviceIdReducer from 'modules/deviceId/DeviceIdReducer'

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    notificationReducer,
    bootstrapReducer,
    authReducer,
    optinReducer,
    homeReducer,
    cmeTrackerReducer,
    userReducer,
    contactFormReducer,
    contactUsReducer,
    unsubscribeReducer,
    deviceIdReducer
  })

export default rootReducer
