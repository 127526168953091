import axios from 'axios'
import config from 'site.config'

const {
  configuration: { envVars }
} = config

const AuthService = () => ({
  async getAuthToken(endpoint, siteId, cookieService, sessionService) {
    let token = this.getAuthFromCookie(cookieService)
    let deviceId = sessionService.getFromCache('deviceId', '')
    if (!token) {
      // no device id info available, perform normal auth fetch
      if (deviceId === '') {
        token = await this.getTokenFromPhoenix(endpoint, siteId)
      } else {
        token = await this.getTokenFromPhoenixUsingDeviceId(
          endpoint,
          siteId,
          deviceId
        )

        let partySource = token?.partySource ?? null
        if (partySource !== null) {
          sessionService.saveToCache('PartySource', partySource)
          sessionService.saveToCache('MemberID', token.partyId)
        }

        token = await this.verifyTokenWithPhoenix(
          endpoint,
          siteId,
          token,
          'access',
          true
        )
      }
    } else {
      // always
      token = await this.verifyTokenWithPhoenix(
        endpoint,
        siteId,
        token,
        'access',
        true
      )
    }
    return token
  },

  refreshAuthToken(endpoint, siteId, cookieService) {
    let token = this.getAuthFromCookie(cookieService)
    token = this.refreshTokenWithPhoenix(endpoint, token)
    return token
  },

  getAuthFromCookie(cookieService) {
    const token = cookieService.get('authToken') || null
    if (token != null) {
      return JSON.parse(token)
    }
  },

  async getTokenFromPhoenix(endpoint, siteId) {
    const url = `${endpoint}/v2/auth/token`
    // Request option
    let axiosOptions = {
      url,
      method: 'post',
      data: { siteId: siteId, loggedIn: true },
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      // Perform API request
      const response = await axios(axiosOptions)
      return response.data
    } catch (error) {
      return false
    }
  },

  async getTokenFromPhoenixUsingDeviceId(endpoint, siteId, deviceId) {
    const url = `${endpoint}/v2/auth/token/device`
    const language = deviceId?.LangUser || navigator.language
    // Request option
    let axiosOptions = {
      url,
      method: 'post',
      data: {
        siteId: siteId,
        idhBrandId: parseInt(process.env.REACT_APP_IDH_BRANDID),
        deviceId,
        countryCode: deviceId?.Country || navigator.language,
        languageCode: language.split('-')[0]
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      // Perform API request
      const response = await axios(axiosOptions)
      return response.data
    } catch (error) {
      return false
    }
  },

  async verifyTokenWithPhoenix(
    endpoint,
    siteId,
    token,
    type = 'access',
    full_response = false
  ) {
    try {
      const url = `${endpoint}/v2/auth/token/verify`
      // Request option
      let axiosOptions = {
        url,
        method: 'post',
        data: { siteId: siteId, type: type, token: `${token.access}` },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access}`
        }
      }
      // Perform API request
      const response = await axios(axiosOptions)
      if (full_response) {
        return response.data
      } else {
        return response.data.tokens
      }
    } catch (error) {
      return false
    }
  },

  async refreshTokenWithPhoenix(endpoint, token) {
    try {
      if (!token.refresh) {
        throw new Error('no token generated')
      }
      const url = `${endpoint}/v2/auth/token/refresh`
      // Request option
      let axiosOptions = {
        url,
        method: 'post',
        data: { token: token.refresh },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.refresh}`
        }
      }
      // Perform API request
      const response = await axios(axiosOptions)

      return response.data
    } catch (error) {
      return false
    }
  },

  async getUserTokenFromPhoenix(endpoint, siteId, user) {
    try {
      const url = `${endpoint}/v2/auth/token`
      // Request option
      let axiosOptions = {
        url,
        method: 'post',
        data: {
          siteId: siteId,
          partyId: user.MemberID,
          partyEmailId: user.EmailID,
          role: 'access',
          loggedIn: true
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }
      // Perform API request
      const response = await axios(axiosOptions)
      return response.data
    } catch (error) {
      return false
    }
  },

  // triggerOtp(payload, token) {
  //   let result = this.triggerOtpRequest(payload, token)
  //   return result
  // },

  async triggerOtpRequest(payload, token) {
    try {
      // Get user token
      const baseUrl =
        envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
      const url = baseUrl + '/v2/user/otp'
      if (token.access) {
        // Request option
        let axiosOptions = {
          url,
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access}`
          },
          data: {
            ...payload
          }
        }
        // Perform API request
        const response = await axios(axiosOptions)
        return response.data
        // return response.data?.obfuscatedEmail
      }
    } catch (error) {
      return error.response?.data || 'Trigger OTP Error'
    }
  },
  async getNemlToken(endpoint, payload, token) {
    try {
      const url = `${endpoint}/v2/auth/token`
      // Request option
      let axiosOptions = {
        url,
        method: 'post',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access}`
        }
      }
      // Perform API request
      const response = await axios(axiosOptions)
      return response.data
    } catch (error) {
      return false
    }
  },
  async performLoginWithPassword(payload, token) {
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    try {
      const url = `${baseUrl}/v2/user/login`
      // Request option
      let axiosOptions = {
        url,
        method: 'post',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access}`
        }
      }
      // Perform API request
      const response = await axios(axiosOptions)
      return response.data
    } catch (error) {
      return false
    }
  }
})

export default AuthService
