import { createSelector } from '@reduxjs/toolkit'

export const cmeTrackerSelector = state => state.cmeTrackerReducer

export const getLoading = createSelector(cmeTrackerSelector, res => res.loading)

export const getCertList = createSelector(
  cmeTrackerSelector,
  res => res.certificates
)

export const getStatementLoading = createSelector(
  cmeTrackerSelector,
  res => res.loadingStatementList
)

export const getStatementList = createSelector(
  cmeTrackerSelector,
  res => res.statementList
)

export const getSuggestedPrograms = createSelector(
  cmeTrackerSelector,
  res => res.suggestedPrograms
)

export const getInProgressActivities = createSelector(
  cmeTrackerSelector,
  res => res.inProgressActivities
)

export const getInProgressActivitiesLoading = createSelector(
  cmeTrackerSelector,
  res => res.loadingInProgressActivities
)
