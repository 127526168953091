import profileMapping from './profile-mapping'
import optinMapping from './optin-mapping'

const getProfileSpecialtyById = id => {
  return profileMapping.filter(a => a.value === id)[0]
}

const getProfileSpecialtyIdByLabel = label => {
  const spec = profileMapping.filter(
    a => a.label.toLowerCase() === label.toLowerCase()
  )
  return spec.length ? spec[0].value : null
}

const getOptinSpecialtyById = id => {
  return optinMapping.filter(a => a.value === id)[0]
}

const getProfileSpecialties = () => {
  return profileMapping
}

const getOptinSpecialties = () => {
  return optinMapping
}

export {
  getOptinSpecialtyById,
  getProfileSpecialtyById,
  getProfileSpecialtyIdByLabel,
  getProfileSpecialties,
  getOptinSpecialties
}
