import moment from 'moment'
import unserialize from 'locutus/php/var/unserialize'
import { forEach, get as getObjectProperty } from 'lodash'
import siteConfig from 'site.config'

const generateRandomString = () => {
  const array = new Uint8Array(16)
  crypto.getRandomValues(array)
  return Array.from(array, byte => byte.toString(36)).join('')
}

/**
 * Date formatting using moment.js
 * @param {*} date – a timestamp
 */
const dateFormat = (date, format = 'MMM D, YYYY') => {
  return moment(date).format(format)
}

/**
 * Gets a particular value,
 * if undefined, null or error it returns a default value.
 * Use this function to get Object values safely.
 *
 * ie: const myVar = getValue(() => someObject.someProp.SomeChildProp, defaultsTo);
 *
 * @param value (any) - The value to get
 * @param defaultsTo (any) - The value to defaults to
 * @return any
 */
const getValue = (value, defaultsTo) => {
  let safeValue

  try {
    safeValue = value()
  } catch (e) {
    return defaultsTo
  }

  return isDefined(safeValue) && safeValue !== null ? safeValue : defaultsTo
}

/**
 * Checks if a value is defined
 *
 * @param value (any) - The value to check for
 * @return boolean
 */
const isDefined = value => {
  return typeof value !== 'undefined'
}

/**
 * Checks if a value is empty
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isEmpty = value => {
  return (
    !isDefined(value) ||
    value === '' ||
    value === false ||
    value === 0 ||
    isEmptyObject(value) ||
    value === null
  )
}

/**
 * Checks if obj is an empty Object
 *
 * @param obj (object) - The object to test
 * @return boolean
 */
const isEmptyObject = obj => {
  if (typeof obj != 'object') {
    return false
  }

  return getValue(() => Object.keys(obj).length, 0) <= 0
}

/**
 * Checks if value is an integer
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isInteger = value => {
  return Number.isInteger(value)
}

/**
 * A function that converts an unserialized PHP string.
 *
 * @param {string} text
 *
 * @returns the object with the corresponding properties.
 */
const unserializeText = text => {
  return unserialize(text)
}

const downloadCsvFromData = (headers, data, fileName) => {
  const csvRows = []
  const headerValues = headers.map(header => header)
  csvRows.push(headerValues.join(',')) // Push into array as comma separated values
  forEach(data, singleRow => {
    let row = headerValues.map(key => {
      const escaped = ('' + singleRow[key]).replace(/"/g, '\\"') // To replace the unwanted quotes.
      return `"${escaped}"`
    })
    csvRows.push(row)
  })
  let csvData = csvRows.join('\n')
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', fileName + '.csv')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * A function that helps completing tracking URLs
 *
 * @param {string} url
 * @param {string} secure
 * @returns {string} the url modificated if needed
 */
const transformTrackingUrl = (url, secure = false) => {
  if (url.indexOf('//') === -1) {
    const protocol = secure ? 'https' : 'http'
    url = `${protocol}://${url}/`
  }
  if (url.indexOf('.php') === -1) {
    url = url + 'piwik.php'
  }
  return url
}

/**
 * Gets a particular value,
 * if undefined, null or error it returns a default value.
 * Use this function to get Object values safely.
 *
 * ie: const myVar = getValue(() => someObject.someProp.SomeChildProp, defaultsTo);
 *
 * @param value (any) - The value to get
 * @param defaultsTo (any) - The value to defaults to
 * @return any
 */
const getSafeValue = (value, defaultsTo) => {
  return !isEmpty(value) && value !== null ? value : defaultsTo
}

/**
 * Decode JWT token
 *
 * @param token (string) - JWT token
 * @return any
 */
const decodeJWT = token => {
  const base64Payload = token.split('.')[1]
  const payload = window.atob(base64Payload)
  return JSON.parse(payload.toString())
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const transform = (obj, predicate) => {
  return Object.keys(obj).reduce((memo, key) => {
    if (predicate(obj[key], key)) {
      memo[key] = obj[key]
    }
    return memo
  }, {})
}
// Omit removed in Lodash 5
const omit = (obj, items) => transform(obj, (v, key) => !items.includes(key))
const pick = (obj, items) => transform(obj, (v, key) => items.includes(key))

const onMenuOpen = className => {
  setTimeout(() => {
    const selectedEl = document.getElementsByClassName(
      `${className}__option--is-selected`
    )[0]
    selectedEl?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    })
  }, 15)
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

const getRandomInt = (min, max) => {
  // Ensure min and max are integers and min is less than max
  min = Math.ceil(min)
  max = Math.floor(max)

  // Create an array to hold a single random byte
  const array = new Uint8Array(1)
  crypto.getRandomValues(array)

  // Convert the byte to a float between 0 and 1 (inclusive of 0, exclusive of 1)
  const randomFloat = array[0] / 256

  // Scale and map the random float to the desired range
  return Math.floor(randomFloat * (max - min + 1)) + min
}

const getSiteConfig = (path, defaultValue) => {
  return getObjectProperty(siteConfig, path, defaultValue)
}

/**
 * A function that will return a previous timestamp from a given timestamp.
 * Used for queries to FB-ES.
 *
 * @param {*} date – given timestamp
 * @param {*} number – amount of {frame}
 * @param {*} unit – 'Y', 'M', 'W', 'D'
 * @returns a timestamp
 */
const priorDate = (date, number, unit) => {
  return moment(date)
    .subtract(parseInt(number), unit)
    .unix()
}
/**
 * @returns the 'to' and 'from' values for the queries
 * @param frame – should be in the form '{number}{unit}', e.g. '6M', '2W'
 */
function getTimeRange(frame) {
  frame = frame.split(/([DWMY])/gi)
  let to = Date.now()
  const from = priorDate(to, frame[0], frame[1])
  to = Math.round(to / 1000)
  return { to, from }
}

function getProfileInfo(profile) {
  const partyId = getObjectProperty(profile, 'info.user.partyId', '')
  const partyEmailId = getObjectProperty(profile, 'info.user.partyEmailId', '')
  const professionId = getObjectProperty(profile, 'info.user.professionId', '')
  const specialtyId = getObjectProperty(profile, 'info.user.specialtyId', '')
  const countryCode = getObjectProperty(profile, 'info.user.countryCode', '')
  return { partyId, partyEmailId, professionId, specialtyId, countryCode }
}

export {
  generateRandomString,
  dateFormat,
  isInteger,
  isEmptyObject,
  isEmpty,
  isDefined,
  transformTrackingUrl,
  getValue,
  getSafeValue,
  unserializeText,
  downloadCsvFromData,
  decodeJWT,
  monthNames,
  weekdays,
  omit,
  pick,
  onMenuOpen,
  delay,
  getRandomInt,
  getSiteConfig,
  priorDate,
  getTimeRange,
  getProfileInfo
}
