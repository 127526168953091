import { all } from 'redux-saga/effects'
import { default as bootstrapSaga } from 'modules/bootstrap/BootstrapSaga'
import { default as authSaga } from 'modules/auth/AuthSaga'
import { default as trackingSaga } from 'modules/tracking/TrackingSaga'
import { default as optinSaga } from 'modules/optin/OptinSaga'
import { default as homeSaga } from 'modules/home/HomeSaga'
import { default as cmetrackerSaga } from 'modules/cme-tracker/CMETrackerSaga'
import { default as userSaga } from 'modules/user/UserSaga'
import { default as contactFormSaga } from 'modules/contactform/ContactFormSaga'
import { default as contactUsSaga } from 'modules/contactus/ContactUsSaga'
import { default as unsubscribeSaga } from 'modules/unsubscribe/UnsubscribeSaga'
import { default as deviceIdSaga } from 'modules/deviceId/DeviceIdSaga'

export default function* root(services) {
  yield all([
    bootstrapSaga(services),
    authSaga(services),
    trackingSaga(services),
    optinSaga(services),
    homeSaga(services),
    cmetrackerSaga(services),
    userSaga(services),
    contactFormSaga(services),
    contactUsSaga(services),
    unsubscribeSaga(services),
    deviceIdSaga(services)
  ])
}
