import StaticFileLayout from 'layouts/StaticFileLayout'

const PrivacyPolicy = () => {
  const title = 'Corporate EU Privacy Policy'
  const filename = 'PrivacyCorpEU'

  return <StaticFileLayout title={title} filename={filename} />
}

export default PrivacyPolicy
