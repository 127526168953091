import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import LoadingDots from 'components/LoadingDots'
import AdCard from './AdCard'
import NewsCard from './NewsCard'
import styles from './News.module.sass'

import { actions } from 'modules/home/HomeReducer'
import { getSpecialtyInput, getNews } from 'modules/home/HomeSelector'

import { getRandomInt } from 'utils'
import config from 'site.config'

const {
  configuration: { newsFeed }
} = config
const pageSize = newsFeed.query.maxResults

const News = props => {
  const dispatch = useDispatch()
  const { hash } = useLocation()
  const specialtyInput = useSelector(getSpecialtyInput)
  const isArticlePage = window.location.pathname.includes('/article/')
  const newsRef = useRef(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [news, setNews] = useState([])
  const {
    data,
    loading: isLoading,
    pages,
    total,
    currentPage: currentFetchedPage
  } = useSelector(getNews)
  const isLastPage = currentPage === pages
  const isLastRecord = news.length === total

  useEffect(() => {
    const isCurrentPageDataFetched = currentFetchedPage === currentPage
    const isLatestDataAddedToList = news.length < currentPage * pageSize

    if (
      !isLoading &&
      isCurrentPageDataFetched &&
      isLatestDataAddedToList &&
      !isLastRecord
    ) {
      if (currentPage === 1) setNews([...data])
      else setNews([...news, ...data])
    }
  }, [news, currentPage, currentFetchedPage, data, isLastRecord, isLoading])

  useEffect(() => {
    if (currentPage === -999) return
    if (currentPage === 1) return
    let payload = {
      specialty: specialtyInput?.value2 || 'General Medicine',
      currentPage
    }
    dispatch(actions.requestNews(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage])

  useEffect(() => {
    setCurrentPage(-999)
    setCurrentPage(1)
    setNews([])
    let payload = {
      specialty: specialtyInput?.value2 || 'General Medicine',
      currentPage: 1
    }
    dispatch(actions.requestNews(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialtyInput])

  useEffect(() => {
    window.onscroll = () => {
      const bottomOfWindow =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.body.offsetHeight
      if (!isLoading && bottomOfWindow && !isLastPage && currentPage < pages) {
        setCurrentPage(currentPage + 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isLastPage, isLoading])

  useEffect(() => {
    if (hash === '#news' && newsRef.current) {
      newsRef.current.style.scrollMargin = '165px'
      newsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  })

  return (
    <div className={styles.container} ref={newsRef}>
      <a
        href="#news"
        id="news"
        className={styles.anchor}
        style={{ height: '0' }}
      >
        a
      </a>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 className={styles.h2}>LATEST NEWS</h2>
      </div>
      {news
        ?.filter(
          f => !isArticlePage || !window.location.pathname.includes(f.human_url)
        )
        .map(
          ({ id, title, teaser, type, human_url, extra_fields, tags }, i) => {
            let badgeText = ''
            if (type === 'abstracts')
              badgeText = tags.journal?.name || 'Abstract'
            if (type === 'dispatch')
              badgeText = tags?.conference?.length
                ? `${tags.conference[0]} ${tags.congress_year[0]}`
                : 'NTK Dispatch'
            if (type === 'news') badgeText = extra_fields.source || 'News'

            return (
              <div key={id}>
                {
                  <NewsCard
                    key={id}
                    badgeText={badgeText}
                    badgeImg={null}
                    category={type}
                    title={title}
                    description={teaser}
                    url={human_url}
                  />
                }
                {(i - 1) % 4 === 0 && isArticlePage && (
                  <AdCard
                    type="feed"
                    anchor={i}
                    badge={true}
                    random={getRandomInt(10000, 99999).toString() + i}
                  />
                )}
              </div>
            )
          }
        )}
      {isLoading && <LoadingDots />}
      {!isLoading && !news?.length && (
        <p className={styles.noresult}>No news for selected specialty</p>
      )}
    </div>
  )
}

News.propTypes = {}

export default News
