import styles from './Profile.module.sass'

export const variables = (professionOptions, professionId) => {
  const form = document.querySelector('form')
  const firstName = document.getElementById('inputFirstName').value
  const lastName = document.getElementById('inputLastName').value
  const postalCode = document.getElementById('inputPostalCode').value
  const password = document.getElementById('inputPassword').value
  const confirmPassword = document.getElementById('inputConfirmPassword').value
  const profession = professionOptions.find(
    prof => prof.value === Number(professionId?.value || 0)
  )

  return {
    form,
    firstName,
    lastName,
    postalCode,
    password,
    confirmPassword,
    profession
  }
}

export const fillInput = (
  profile,
  countryOptions,
  professionOptions,
  specialtyOptions,
  setCountry,
  setProfessionId,
  setSpecialtyId
) => {
  if (!document.getElementById('inputFirstName')) return

  document.getElementById('inputFirstName').value = profile.firstName || ''
  document.getElementById('inputLastName').value = profile.lastName || ''
  document.getElementById('inputPostalCode').value = profile.postalCode || ''
  setCountry(
    countryOptions.filter(country => country.value === profile.country)[0] || {
      value: '',
      label: 'Select'
    }
  )
  setProfessionId(
    professionOptions.filter(
      prof => prof.value === profile.professionId
    )[0] || { value: '', label: 'Select' }
  )
  setSpecialtyId(
    specialtyOptions.filter(spec => spec.value === profile.specialtyId)[0] || {
      value: '',
      label: 'Select'
    }
  )
}

export const specialtyMap = (
  professionId,
  specialtyId,
  specialtyOptions,
  oriOptinSpecialtyCONTENT
) => {
  let spe = null
  const specialty = specialtyOptions.find(
    spec => spec.value === Number(specialtyId?.value || 0)
  )
  const originalSpecialty = oriOptinSpecialtyCONTENT.find(
    spec => spec.value === specialty?.value
  )
  const selectedSpecialty = originalSpecialty?.label || ''
  if (selectedSpecialty !== '') {
    spe = {
      specialtyId: originalSpecialty.value,
      specialtyCode: originalSpecialty.label
    }
  }
  return spe
}

export const setError = ele => {
  ele.classList.add(styles.inputF)
  ele.classList.remove(styles.inputG)
}

export const setGood = ele => {
  ele.classList.add(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const resetElement = ele => {
  ele.classList.remove(styles.inputG)
  ele.classList.remove(styles.inputF)
}

export const resetSelectElement = ele => {
  ele.classList.remove(styles.selectWithError)
  ele.classList.remove(styles.selectGood)
}

export const checkPassword = (confirmPassword, setErrorPassword) => {
  const newPassword = document.getElementById('inputPassword').value
  if (confirmPassword !== newPassword) {
    setErrorPassword(`New password and confirm password must match.`)
    setError(document.getElementById('inputPassword'))
  } else if (confirmPassword.length > 0 && confirmPassword.length < 6) {
    setErrorPassword('Password must be at least 6 characters long.')
    setError(document.getElementById('inputPassword'))
  } else {
    setErrorPassword('')
    setGood(document.getElementById('inputPassword'))
  }
}

export const checkField = (elementId, fieldname, errorFunc) => {
  const ele = document.getElementById(elementId)
  if (!ele.checkValidity()) {
    errorFunc(`Your ${fieldname} is required.`)
    setError(ele)
  } else {
    errorFunc('')
    setGood(ele)
  }
}

export const checkSelect = (selectValue, elementId, fieldname, errorFunc) => {
  const eleValue = selectValue?.value || null
  const element = document.getElementById(elementId)
  if (element) {
    if (eleValue == null) {
      errorFunc(`Your ${fieldname} is required.`)
      element.classList.add(styles.selectWithError)
      element.classList.remove(styles.selectGood)
    } else {
      errorFunc('')
      element.classList.add(styles.selectGood)
      element.classList.remove(styles.selectWithError)
    }
  }
}

export const handleSelectNotice = (input, elementId, fieldname, errorFunc) => {
  const element = document.getElementById(elementId)
  // put css change here because the react-select handles setState
  // slower than the css to detect faulty input.
  // react-select also doesn't support ontouch (mobile events)
  if (input.value === '') {
    errorFunc(`Your ${fieldname} is required.`)
    element.classList.add(styles.selectWithError)
    element.classList.remove(styles.selectGood)
  } else {
    errorFunc('')
    element.classList.add(styles.selectGood)
    element.classList.remove(styles.selectWithError)
  }
}

export const resetForm = (
  setErrorFirstName,
  setErrorLastName,
  setErrorPostalCode,
  setErrorPassword,
  setErrorCountry,
  setErrorProfession,
  setErrorSpecialty
) => {
  // removes the css for good/fault input
  resetElement(document.getElementById('inputFirstName'))
  resetElement(document.getElementById('inputPostalCode'))
  resetElement(document.getElementById('inputLastName'))
  resetElement(document.getElementById('inputPassword'))
  resetSelectElement(document.getElementById('inputCountry'))
  resetSelectElement(document.getElementById('inputProfession'))
  resetSelectElement(document.getElementById('inputSpecialty'))

  // removes the error message
  setErrorFirstName('')
  setErrorLastName('')
  setErrorPostalCode('')
  setErrorPassword('')
  setErrorCountry('')
  setErrorProfession('')
  setErrorSpecialty('')
}

export const checkError = (
  country,
  setErrorFirstName,
  setErrorLastName,
  setErrorPostalCode,
  setErrorPassword,
  setErrorCountry
) => {
  checkField('inputFirstName', 'First Name', setErrorFirstName)
  checkField('inputLastName', 'Last Name', setErrorLastName)
  checkField('inputPostalCode', 'Postal Code', setErrorPostalCode)
  checkField('inputPassword', 'Password', setErrorPassword)
  checkSelect(country, 'inputCountry', 'Country', setErrorCountry)
}

export const checkIsSpecialtyRequired = profession => {
  const professionLabel = profession?.label || ''
  return professionLabel === 'Physician' || professionLabel === 'Resident'
}
