import PropTypes from 'prop-types'
import styles from './Badge.module.sass'

const Badge = ({ text, icon = '/webclip.png' }) => {
  return (
    <div data-testid="badge" style={{ display: 'flex', alignItems: 'center' }}>
      <img src={icon} className={styles.icon} alt="badge-icon" />
      <div className={styles.badge}>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  )
}

Badge.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string
}

export default Badge
