import FormLayout from 'layouts/FormLayout'
import styles from './NotFound.module.sass'

const Partner = () => (
  <FormLayout loading={false} disableLottie={true}>
    <div className={styles.box}>
      <h1 style={{ textAlign: 'center' }}>PARTNER WITH US</h1>
      <div className={styles.content}>
        <p>Partner with us text.</p>
      </div>
    </div>
  </FormLayout>
)

export default Partner
