import { CircularProgress } from 'components/imported/Progress'
import styles from './PageLoader.module.sass'

const PageLoader = () => {
  return (
    <div className={styles.loadingMask}>
      <CircularProgress
        strokeColor="#3b78c3"
        strokeBackgroundColor="#CECECE"
        fillColor="transparent"
        className={styles.circularProgress}
      />
    </div>
  )
}

export default PageLoader
