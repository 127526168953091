import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

ScrollToTop.propTypes = {
  history: PropTypes.any,
  children: PropTypes.node
}

export default withRouter(ScrollToTop)
