import { Link } from 'react-router-dom'
import FormLayout from 'layouts/FormLayout'
import styles from './NotFound.module.sass'

const NotFound = () => (
  <FormLayout loading={false} disableLottie={false}>
    <div className={styles.box}>
      <h1>Something went wrong...</h1>
      <h2>Page not found</h2>
      <div className={styles.content}>
        <p>
          This is because it has moved or is no longer available. Please check
          that you have entered the correct URL.
        </p>
        <p>
          For more information on Answers in CME please visit our&nbsp;
          <Link to="/aboutus">about us</Link>
          &nbsp; page to learn more.
        </p>
        <p>
          Or visit our&nbsp;
          <Link to="/">homepage</Link>.
        </p>
      </div>
    </div>
  </FormLayout>
)

export default NotFound
