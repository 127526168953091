import Bootstrap from 'modules/bootstrap'
import { ConnectedRouter } from 'connected-react-router'
import Notification from 'modules/notification/Notification'
import history from './history'
import { createServiceProvider } from 'services/ServiceContext'
import { Provider } from 'react-redux'
import createStore from './store'
import createContainer from 'services'
import Routes from 'Routes'
import styles from 'App.module.sass'
import Title from './modules/title/TitleContainer'
import Login from './modules/auth/Auth'
const container = createContainer()
const store = createStore(history, container)

container.registerStore(store)
const ServiceProvider = createServiceProvider(container)
function App() {
  return (
    <Provider store={store}>
      <ServiceProvider>
        <Bootstrap>
          <ConnectedRouter className={styles.container} history={history}>
            <Title>
              <Login />
              <Routes />
            </Title>
          </ConnectedRouter>
          <Notification />
        </Bootstrap>
      </ServiceProvider>
    </Provider>
  )
}

export default App
