import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from 'modules/bootstrap/BootstrapReducer'
import { actions as notificationActions } from 'modules/notification/NotificationReducer'
import { actions as authActions } from 'modules/auth/AuthReducer'
import config from 'site.config'

const {
  configuration: { envVars }
} = config

export function* initialize(services) {
  yield put(authActions.requestToken())
  yield put(actions.requestConfig())
  yield put(actions.requestNotifications())
  yield put(actions.successBootstrap())
}

export function* setTracking(services) {
  try {
    const Tracking = services('TrackingService')
    // Initialize Piwik
    const siteId =
      envVars?.REACT_APP_PIWIK_SITEID ?? process.env.REACT_APP_PIWIK_SITEID
    const url = envVars?.REACT_APP_PIWIK_URL ?? process.env.REACT_APP_PIWIK_URL
    const scriptName = 'pwk.js'
    yield call([Tracking, 'initializeTrackingScript'], {
      url,
      siteId,
      secure: true,
      scriptName,
      scriptUrl: url
    })
  } catch (error) {
    throw new Error(error)
  }
}

export function* requestConfig(services) {
  try {
    const ConfigService = services('ConfigService')
    const ConfigParser = services('ConfigParser')

    const data = yield call([ConfigService, 'request'])
    const currentConfig = ConfigParser.parseConfig(data)
    yield put(actions.successConfig(currentConfig))
  } catch (error) {
    yield put(
      notificationActions.notificationEnqueue({
        message: 'There was a problem fetching the configuration.',
        duration: 2000
      })
    )
    yield put(actions.errorConfig())
  }
}

export function* requestNotifications(services) {
  try {
    const Cookies = services('CookieService')

    const hasOptedIn = Cookies.get('opt')
    if (hasOptedIn !== 'y') {
      yield put(
        notificationActions.notificationEnqueue({
          message:
            'We use cookies to improve your experience. By using this website, you accept cookies or have set your browser or device to not accept cookies.',
          duration: 31536000,
          onDismiss: () => {
            Cookies.set('opt', 'y', { expires: 30 })
          }
        })
      )
    }
    yield put(actions.successNotifications())
  } catch (error) {
    yield put(actions.errorNotifications())
  }
}

export default function* watchBootstrap(services) {
  yield all([
    takeEvery(actions.requestBootstrap().type, initialize, services),
    takeEvery(actions.requestConfig().type, requestConfig, services),
    takeEvery(
      actions.requestNotifications().type,
      requestNotifications,
      services
    )
  ])
}
