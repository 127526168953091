import StaticFileLayout from 'layouts/StaticFileLayout'

const PrivacyCCPA = () => {
  const title = 'CCPA Privacy Policy'
  const filename = 'PrivacyCCPA'

  return <StaticFileLayout title={title} filename={filename} />
}

export default PrivacyCCPA
