import { createReducer, createAction } from '@reduxjs/toolkit'
import { forOwn } from 'lodash'
import { unserializeText, dateFormat } from 'utils'

export const requestCertList = createAction('REQUEST_CERT')
export const requestCertListSuccess = createAction('REQUEST_CERT_SUCCESS')
export const requestCertListFail = createAction('REQUEST_CERT_FAIL')
export const requestStateBoardList = createAction('REQUEST_STATE_BOARD')
export const requestStateBoardListSuccess = createAction(
  'REQUEST_STATEBOARD_SUCCESS'
)
export const requestStateBoardListFail = createAction('REQUEST_STATEBOARD_FAIL')
export const requestSuggestedPrograms = createAction(
  'REQUEST_SUGGESTED_PROGRAMS'
)
export const requestSuggestedProgramsSuccess = createAction(
  'REQUEST_SUGGESTED_PROGRAMS_SUCCESS'
)
export const requestSuggestedProgramsFail = createAction(
  'REQUEST_SUGGESTED_PROGRAMS_FAIL'
)
export const requestInProgressActivities = createAction(
  'REQUEST_IN_PROGRESS_ACTIVITIES'
)
export const requestInProgressActivitiesSuccess = createAction(
  'REQUEST_IN_PROGRESS_ACTIVITIES_SUCCESS'
)
export const requestInProgressActivitiesFail = createAction(
  'REQUEST_IN_PROGRESS_ACTIVITIES_FAIL'
)

export const actions = {
  requestCertList,
  requestCertListSuccess,
  requestCertListFail,
  requestStateBoardList,
  requestStateBoardListSuccess,
  requestStateBoardListFail,
  requestSuggestedPrograms,
  requestSuggestedProgramsSuccess,
  requestSuggestedProgramsFail,
  requestInProgressActivities,
  requestInProgressActivitiesSuccess,
  requestInProgressActivitiesFail
}

const cmeTrackerReducer = createReducer(
  {
    certificates: [],
    loading: false,
    loadingStatementList: false,
    statementList: [],
    suggestedPrograms: {
      data: [],
      loading: false,
      pages: 0,
      total: 0,
      currentPage: 1
    },
    inProgressActivities: [],
    loadingInProgressActivities: false
  },
  {
    [requestCertList]: state => {
      state.loading = true
      state.certificates = []
    },
    [requestCertListSuccess]: (state, { payload }) => {
      state.loading = false
      state.certificates = payload
        .filter(f => f.certificate_uri?.includes('answersincme.com'))
        .map(m => ({
          ...m,
          credit_type: m.credit_type?.replace('Credits', '').toUpperCase()
        }))
    },
    [requestCertListFail]: state => {
      state.loading = false
      state.certificates = []
    },
    [requestStateBoardList]: state => {
      state.loadingStatementList = true
      state.statementList = []
    },
    [requestStateBoardListSuccess]: (state, { payload }) => {
      let list = []
      forOwn(payload, (singleState, key) => {
        let websiteList = []
        if (singleState.do_boardname && singleState.do_url) {
          websiteList.push({
            label: singleState.do_boardname,
            link: singleState.do_url,
            requirement: 'DO: ' + singleState.DO
          })
        }
        if (singleState.md_boardname && singleState.md_url) {
          websiteList.push({
            label: singleState.md_boardname,
            link: singleState.md_url,
            requirement: 'MD: ' + singleState.MD
          })
        }
        websiteList[0].label = `Visit State ${websiteList[0].label} Website`
        let newStruc = {
          name: singleState.name,
          website: websiteList,
          code: key
        }
        list.push(newStruc)
      })
      state.loadingStatementList = false
      state.statementList = list
    },
    [requestStateBoardListFail]: state => {
      state.loadingStatementList = false
      state.statementList = []
    },
    [requestSuggestedPrograms]: state => {
      state.suggestedPrograms.loading = true
      state.suggestedPrograms.data = []
    },
    [requestSuggestedProgramsSuccess]: (state, { payload }) => {
      state.suggestedPrograms.data = payload.results.map(
        ({ title, extra_fields, publication_date }) => {
          const authors = unserializeText(extra_fields.authors) || []
          return {
            id: extra_fields.project_number,
            date: dateFormat(
              new Date(Number(publication_date + '000')),
              'MMMM D, YYYY'
            ),
            title: title,
            authors: authors.map(({ FirstName, Designation }) =>
              Designation?.length
                ? `${FirstName}, ${Designation}`
                : `${FirstName}`
            ),
            type: extra_fields.service_type || 'Video',
            credit: extra_fields.dgme_credits,
            credit_type: extra_fields.dgme_credit_type,
            publishers: extra_fields.publishers,
            image:
              extra_fields.thumbnail ||
              extra_fields.icon_image ||
              'https://c.answersincme.com/assets/images/aicme-default-thumbnail.jpg',
            imageFallback:
              extra_fields.publisher_logo_url ||
              extra_fields.accreditor_logo_url,
            url: extra_fields.article_url,
            expiration_date: extra_fields.expiration_date
          }
        }
      )
      state.suggestedPrograms.loading = false
      state.suggestedPrograms.currentPage = payload.currentPage
      state.suggestedPrograms.pages = payload.pages
      state.suggestedPrograms.total = payload.total
    },
    [requestSuggestedProgramsFail]: state => {
      state.suggestedPrograms.loading = false
      state.suggestedPrograms.data = []
    },
    [requestInProgressActivities]: state => {
      state.inProgressActivities = []
      state.loadingInProgressActivities = true
    },
    [requestInProgressActivitiesSuccess]: (
      state,
      { payload: { result, MemberID, EmailID } }
    ) => {
      state.inProgressActivities = result
        .filter(act => {
          const data = act.data.visited[0]
          return data.programInfo.programUrl.includes('answersincme.com')
        })
        .filter(act => {
          const data = act.data.visited[0]
          const last = Number(data.last)
          const first = Number(data.first)
          return first !== -1 && last !== -1
        })
        .map(act => {
          const data = act.data.visited[0]
          return {
            id: act.id,
            activity_title: data.programInfo.title,
            last_visited: data.last,
            certificate_uri: `${
              data.programInfo.programUrl
            }?MemberID=${MemberID || ''}&EmailID=${EmailID || ''}`
          }
        })
      state.loadingInProgressActivities = false
    },
    [requestInProgressActivitiesFail]: state => {
      state.inProgressActivities = []
      state.loadingInProgressActivities = false
    }
  }
)

export default cmeTrackerReducer
