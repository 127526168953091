import axios from 'axios'

const APIservice = () => ({
  /**
   * A global function to call FB-ES.
   * All content requests should use this service.
   *
   * @param {string} endpoint
   * @param {*} data
   */
  async get(endpoint, data) {
    const url = `${endpoint}`
    // Get SSM Params
    let axiosOptions = {
      url,
      method: 'post',
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    // Perform API request.
    const response = await axios(axiosOptions)
    return response?.data
  },
  async getWithoutAnyHeaders(endpoint) {
    const url = `${endpoint}`
    // Get SSM Params
    let axiosOptions = {
      url,
      method: 'get'
    }
    // Perform API request.
    const response = await axios(axiosOptions)
    return response?.data
  },
  async getWithHeaders(endpoint, data, headers) {
    const url = `${endpoint}`
    // Get SSM Params
    let axiosOptions = {
      url,
      method: 'get',
      data: JSON.stringify(data),
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    }
    // Perform API request.
    const response = await axios(axiosOptions)
    return response?.data
  },
  async postWithHeaders(endpoint, data, headers) {
    const url = `${endpoint}`
    // Get SSM Params
    let axiosOptions = {
      url,
      method: 'post',
      data: JSON.stringify(data),
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      }
    }
    // Perform API request.
    const response = await axios(axiosOptions)
    return response?.data
  }
})

export default APIservice
