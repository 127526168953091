const optinMapping = [
  {
    label: 'Allergy/Immunology',
    value: 9
  },
  {
    label: 'Anesthesiology',
    value: 12
  },
  {
    label: 'Cardiology',
    value: 18
  },
  {
    label: 'Dermatology',
    value: 37
  },
  {
    label: 'Emergency Medicine',
    value: 44
  },
  {
    label: 'Endocrinology',
    value: 45
  },
  {
    label: 'Gastroenterology',
    value: 53
  },
  {
    label: 'GPs/PCPs',
    value: 55
  },
  {
    label: 'Geriatrics',
    value: 61
  },
  {
    label: 'Hematology',
    value: 69
  },
  {
    label: 'Infectious Disease, incl HIV/Avalues',
    value: 76
  },
  {
    label: 'Internal Medicine',
    value: 78
  },
  {
    label: 'Nephrology',
    value: 92
  },
  {
    label: 'Neurology',
    value: 94
  },
  {
    label: 'Obstetrics/Gynecology',
    value: 106
  },
  {
    label: 'Oncology',
    value: 108
  },
  {
    label: 'Ophthalmology',
    value: 109
  },
  {
    label: 'Orthopedics',
    value: 115
  },
  {
    label: 'Otolaryngology',
    value: 117
  },
  {
    label: 'Pediatrics',
    value: 152
  },
  {
    label: 'Pathology',
    value: 123
  },
  {
    label: 'Psychiatry',
    value: 159
  },
  {
    label: 'Pulmonary/Respiratory Medicine',
    value: 167
  },
  {
    label: 'Radiology',
    value: 164
  },
  {
    label: 'Rheumatology',
    value: 168
  },
  {
    label: 'Urology',
    value: 181
  }
]

export default optinMapping
