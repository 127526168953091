import { takeEvery, all, put, call } from 'redux-saga/effects'
import { actions } from './ContactUsReducer'
import config from 'site.config'

const {
  configuration: { envVars, tracking }
} = config

export function* postContactUsform(services, { payload }) {
  yield put(actions.submitFeedbackForm())
  const cookieService = services('CookieService')
  const sessionService = services('SessionService')
  const authToken = JSON.parse(cookieService.get('authToken'))
  try {
    const apiService = services('APIService')
    const baseUrl =
      envVars?.REACT_APP_PHNX_API ?? process.env.REACT_APP_PHNX_API
    const subUrl = payload.isAuth ? '/v2/user/feedback' : '/v2/user/contact-us'
    const url = baseUrl + subUrl
    const interactionId = sessionService
      .getFromCache(tracking.clarity.identifiers['custom-session-id'], '')
      ?.toString()
    const additionalHeader = {
      Authorization: `Bearer ${authToken.access}`
    }
    const result = yield call(
      [apiService, 'postWithHeaders'],
      url,
      {
        message: payload.message,
        brandCode: payload.brandCode,
        fields: {
          email: payload.fields.email,
          firstName: payload.fields.firstName,
          lastName: ' '
        },
        brandType: payload.isAuth ? 'feedback' : 'contactUs',
        interactionId,
        idhBrandId: payload.idhBrandId,
        piwikSiteId: payload.piwikSiteId,
        device: payload.device,
        reCaptcha: payload.reCaptcha
      },
      additionalHeader
    )
    yield put(actions.submitContactUsFormSuccess(result))
  } catch (e) {
    yield put(actions.submitContactUsFormFail())
  }
}

export default function* watchContactUs(services) {
  yield all([
    takeEvery(actions.submitContactUsForm, postContactUsform, services)
  ])
}
