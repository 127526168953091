import { getSiteConfig } from 'utils'

/**
 * ClarityService, handles Microsoft Clarity tracking and analytics functionality.
 * It provides methods for initializing tracking variables
 *
 * @param {SessionService} SessionService - The session service used for caching values.
 * @returns {Object} - An object containing the TrackingService methods.
 */
const ClarityService = SessionService => {
  const clarityConfig = getSiteConfig('configuration.tracking.clarity', {})
  return {
    /**
     * Sets custom IDs for Clarity if identifiers are defined in site.config.json.
     * Extracts the values from cache and calls the Clarity 'identify' API with customId and customSessionId.
     *
     * Docs: https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api#customizing-custom-id
     *
     * @returns {boolean|Promise<*>}
     */

    setCustomIds() {
      if (window.clarity === undefined) {
        console.warn('clarity not found')
        return
      }

      if (clarityConfig?.identifiers) {
        const customId = SessionService.getFromCache(
          clarityConfig.identifiers['custom-id'],
          ''
        )?.toString()
        const customSessionId = SessionService.getFromCache(
          clarityConfig.identifiers['custom-session-id'],
          ''
        )?.toString()
        return window.clarity('identify', customId, customSessionId)
      }
    },

    /**
     * Resets custom-id and custom-session-id if clarity is found and identifiers are defined.
     *
     * @returns {boolean|Promise<*>}
     */
    resetCustomIds() {
      if (window.clarity === undefined) {
        console.warn('clarity not found')
        return
      }

      if (clarityConfig?.identifiers) return window.clarity('identify', '', '')
    }
  }
}

export default ClarityService
