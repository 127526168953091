import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import LoadingDots from 'components/LoadingDots'
import styles from './Curriculum.module.sass'
import { getLoadingEvents, getEvents } from 'modules/home/HomeSelector'
import { getProfile, getHasPartyId } from 'modules/auth/AuthSelector'

export const getProfileInfo = profile => {
  const partyId = get(profile, 'info.user.partyId', '')
  const partyEmailId = get(profile, 'info.user.partyEmailId', '')
  const professionId = get(profile, 'info.user.professionId', '')
  const specialtyId = get(profile, 'info.user.specialtyId', '')
  return { partyId, partyEmailId, professionId, specialtyId }
}

const Events = props => {
  const loading = useSelector(getLoadingEvents)
  const events = useSelector(getEvents)

  const hasPartyId = useSelector(getHasPartyId)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    '?SpecialtyID=&AudienceID=aicme&PromoCode=796'
  )
  useEffect(() => {
    if (hasPartyId && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    }
  }, [hasPartyId, profile])

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>LIVE EVENTS</h2>
      {!loading &&
        events?.map(
          ({ title, url, dates, format, location, credit, presenters }) => (
            <div key={title} className={styles.span}>
              <a href={url + urlParams} target="_blank" rel="noreferrer">
                <p className={styles.link} style={{ fontWeight: 'bold' }}>
                  {title}
                </p>
              </a>
              <table>
                {dates && (
                  <tr>
                    <td style={{ verticalAlign: 'baseline', width: '100px' }}>
                      <p className={styles.info}>Date:</p>
                    </td>
                    <td>
                      <span style={{ color: '#000', whiteSpace: 'pre-line' }}>
                        {dates.join(`\n`)}
                      </span>
                    </td>
                  </tr>
                )}
                {location && (
                  <tr>
                    <td style={{ verticalAlign: 'baseline' }}>
                      <p className={styles.info}>Location:</p>
                    </td>
                    <td>
                      <span style={{ color: '#000' }}>{location}</span>
                    </td>
                  </tr>
                )}
                {credit && (
                  <tr>
                    <td style={{ verticalAlign: 'baseline' }}>
                      <p className={styles.info}>Credit:</p>
                    </td>
                    <td>
                      <span style={{ color: '#000' }}>{credit}</span>
                    </td>
                  </tr>
                )}
                {presenters && (
                  <tr>
                    <td style={{ verticalAlign: 'baseline' }}>
                      <p className={styles.info}>Presenters:</p>
                    </td>
                    <td>
                      <span style={{ color: '#000' }}>
                        {presenters.join(`\n`)}
                      </span>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          )
        )}
      {loading && <LoadingDots />}
    </div>
  )
}

Events.propTypes = {}

export default Events
