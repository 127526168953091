import { createSelector } from '@reduxjs/toolkit'

export const bootstrapSelector = state => state.bootstrapReducer

export const getConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config
)

export const getPageConfig = createSelector(bootstrapSelector, bootstrap => ({
  siteName: bootstrap.config?.siteName,
  title: bootstrap.config?.title,
  description: bootstrap.config?.description,
  background: bootstrap.config?.background
}))

export const getSpecialtiesConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.specialties
)

export const getFormSectionConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.form
)

export const getPrivacySectionConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.privacy
)

export const getHeaderSectionConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.header
)

export const getMenuSectionConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.menu
)

export const getMenuLinksConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.menu?.links
)

export const getSocialLinksConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.menu?.socialLinks
)

export const getAdditionalLinksConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.menu?.additionalLinks
)

export const getFooterSectionConfig = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.config?.sections?.footer
)

export const getFetchingConfigStatus = createSelector(
  bootstrapSelector,
  bootstrap => bootstrap.configFetching
)

export const getPagination = page =>
  createSelector(
    bootstrapSelector,
    bootstrap => bootstrap.config?.sections[page]?.results?.pagination
  )
