import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styles from './AuthWithEmail.module.sass'
import pwdStyles from './AuthWithPassword.module.sass'
import { actions } from './AuthReducer'
import * as AuthSelector from './AuthSelector'
import cx from 'classnames'
import { CircularProgress } from 'components/imported/Progress'

const { loginWithPasswordRequest } = actions
const { getLoading, getLoginErrMsg } = AuthSelector

const AuthWithPassword = () => {
  const dispatch = useDispatch()
  const refEmail = useRef()
  const refPassword = useRef()
  const history = useHistory()
  const isLoading = useSelector(getLoading)
  const loginErrMsg = useSelector(getLoginErrMsg)

  return (
    <div className={isLoading ? styles.loadingContainer : styles.container}>
      <div className={styles.loginInstrucText}>
        Enter Your Email and Password to Log In
      </div>
      <div className={pwdStyles.loginErrMsg}>{loginErrMsg}</div>
      <form
        onSubmit={e => {
          e.preventDefault()
          if (refEmail.current.value && refPassword.current.value) {
            dispatch(
              loginWithPasswordRequest({
                email: refEmail.current.value,
                password: refPassword.current.value,
                history: history
              })
            )
          }
        }}
      >
        <div style={{ height: '20px' }}></div>
        <span>
          <strong>Email</strong>
        </span>
        <input
          name="email"
          type="email"
          alt="email"
          required
          className={styles.loginInput}
          ref={refEmail}
          disabled={isLoading}
        />
        <span>
          <strong>Password</strong>
        </span>
        <input
          name="password"
          type="password"
          alt="password"
          required
          className={styles.loginInput}
          ref={refPassword}
          disabled={isLoading}
        />
        {!isLoading && (
          <input
            type="submit"
            className={cx(styles.loginBtn)}
            disabled={isLoading}
            value="Log In"
          ></input>
        )}
        {isLoading && (
          <CircularProgress
            strokeColor="#3b78c3"
            strokeBackgroundColor="#CECECE"
            fillColor="transparent"
            className={styles.circularProgress}
            size={38}
          />
        )}
      </form>
      <div style={{ height: '20px' }}></div>
    </div>
  )
}
export default AuthWithPassword
