// import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useHistory } from 'react-router-dom'
import Svg from 'react-inlinesvg'
import DualLayout from 'layouts/DualLayout'
import styles from './AboutUs.module.sass'

const AboutUs = () => {
  const history = useHistory()

  return (
    <DualLayout loading={false} disableLottie={true}>
      <div className={styles.box}>
        <h1>OUR PROMISES</h1>
        <p>
          AiCME is uniquely poised to deliver activities based on the strength
          of our highly-targeted learner engagement model and robust outcomes
          reporting, which are the mainstay of our educational approach.
          Harnessing a deep understanding of the healthcare practice gaps and
          unmet educational needs, our experienced medical direction team
          combines pivotal scientific data with clinical insights from renowned
          faculty to deliver focused content to clinicians in an easily
          digestible format.
        </p>
        <p>
          As the leader in microburst learning, AiCME delivers innovative
          digital CME and live content to targeted HCPs identified through our
          exclusive access to LiMA, the Leaders in Medicine Atlas - a
          proprietary database of 5.7M+ clinicians globally, including 2.6M+ in
          the United States and 1.6M+ in Europe.
        </p>
        <h1 style={{ marginTop: '41px' }}>ABOUT US</h1>
        <p>
          Answers in CME (AiCME) is an independent medical education provider
          dedicated to delivering succinct, short-format educational activities
          that connect healthcare professionals with dynamic clinical content.
          We leverage cognitive learning principles and innovative instructional
          techniques to present science in a manner that is engaging,
          informative, and impactful. Our aim is to be a trusted provider of
          high-quality, evidence-based continuing medical educational activities
          that help healthcare professionals to stay current with the latest
          advances in their fields and improve their skills.
        </p>
        <p>
          At AiCME, we are committed to enhancing the learning experience and
          improving the quality of education across various therapeutic areas
          through microburst learning platforms using proprietary technologies.
          By narrowing the knowledge gap and increasing clinician competence,
          our CME program supports practice improvements that help patients live
          longer, healthier, better-quality lives.
        </p>
        <p>
          AiCME is pleased to hold accreditations from both the Accreditation
          Council for Continuing Medical Education (ACCME) and the European
          Board for Accreditation of Continuing Education for Health
          Professionals (EBAC). Additionally, we are proud to be a member
          organization of the Good CME Practice Group (gCMEp).
        </p>
        <div
          style={{
            marginTop: '47px',
            paddingLeft: '30px',
            paddingRight: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <LazyLoadImage
            src="https://c.answersincme.com/corporate/images/accme.png"
            effect="opacity"
            alt="accme-logo"
          />
          <LazyLoadImage
            src="https://c.answersincme.com/corporate/images/ebac.png"
            effect="opacity"
            alt="ebac-logo"
          />
          <LazyLoadImage
            src="https://c.answersincme.com/corporate/images/gcmep.png"
            effect="opacity"
            alt="gcmep-logo"
          />
        </div>

        <button
          className={styles.back}
          onClick={() => history.goBack()}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') history.goBack()
          }}
        >
          <p
            style={{
              color: '#979797',
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: '17px'
            }}
          >
            <Svg
              src="/img/chevron-left.svg"
              height={12}
              width={7}
              fill={'#979797'}
              style={{ marginRight: '4.3px' }}
            />
            Back
          </p>
        </button>
      </div>
    </DualLayout>
  )
}

export default AboutUs
