import { LazyLoadImage } from 'react-lazy-load-image-component'
import PropTypes from 'prop-types'
import Badge from './Badge'
import styles from './ActivityCard.module.sass'

const ActivityCard = ({
  thumbnail,
  credit,
  creditType,
  publisher,
  publisherImg,
  date,
  title,
  authors,
  url,
  alt
}) => {
  return (
    <div className={styles.card}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        {publisher && (
          <Badge
            text={publisher}
            icon={publisherImg || require('images/abstract.png')}
          />
        )}
        {date && <p className={styles.credit}>{date}</p>}
        <p className={styles.credit}>{credit + ' ' + creditType}</p>
      </div>

      <div style={{ display: 'flex', marginTop: '10px' }}>
        <div>
          <a href={url} target="_blank" rel="noreferrer">
            <LazyLoadImage
              src={
                thumbnail ||
                'https://c.answersincme.com/assets/images/aicme-default-thumbnail.jpg'
              }
              className={styles.img}
              effect="opacity"
              alt={alt}
            />
          </a>
        </div>
        <div>
          <a href={url} target="_blank" rel="noreferrer">
            <p className={styles.title}>{title}</p>
          </a>
          <p className={styles.author} style={{ whiteSpace: 'pre' }}>
            {authors.join(`\n`)}
          </p>
        </div>
      </div>
    </div>
  )
}

ActivityCard.propTypes = {
  thumbnail: PropTypes.string,
  publisher: PropTypes.string,
  publisherImg: PropTypes.string,
  date: PropTypes.string,
  credit: PropTypes.string,
  creditType: PropTypes.string,
  title: PropTypes.string,
  authors: PropTypes.array,
  url: PropTypes.string,
  alt: PropTypes.string
}

export default ActivityCard
