import { createSelector } from '@reduxjs/toolkit'
import { get } from 'lodash'
export const authSelector = state => state.authReducer

export const getAuthToken = createSelector(authSelector, auth => auth.authtoken)

export const getAuthVerified = createSelector(
  authSelector,
  auth => auth.verified
)

export const getAuthVerifying = createSelector(
  authSelector,
  auth => auth.verifying
)

export const getAuthError = createSelector(authSelector, auth => auth.error)

export const getProfile = createSelector(authSelector, auth => auth.profile)

export const getProfileInfo = createSelector(authSelector, auth =>
  get(auth, 'profile.info.user', {})
)

export const getIsLogin = createSelector(
  authSelector,
  auth => get(auth, 'profile.info.user.loggedIn', false) === true
)

export const getHasPartyId = createSelector(
  authSelector,
  auth => get(auth, 'profile.info.user.partyId', '') !== ''
)

export const getPartyId = createSelector(authSelector, auth =>
  get(auth, 'profile.info.user.partyId', '')
)

export const getShouldOpenModal = createSelector(
  authSelector,
  res => res.openModal
)

export const getWaitingOTPModal = createSelector(
  authSelector,
  res => res.pendingOtp
)

export const getTemporaryToken = createSelector(
  authSelector,
  res => res.temporaryToken
)

export const getLoading = createSelector(authSelector, res => res.loading)

export const getRedirectRoute = createSelector(
  authSelector,
  res => res.redirectRoute
)

export const getIsVerifyingOtp = createSelector(
  authSelector,
  res => res.verifyingOtp
)

export const getNemlToken = createSelector(authSelector, res => res.nemlToken)

export const getLoginErrMsg = createSelector(
  authSelector,
  res => res.loginErrMsg
)
