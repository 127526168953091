import 'url-search-params-polyfill'
import {
  urlParamsPersistent,
  urlParamsNonPersistent,
  urlParamsSynonyms,
  urlParamsNonPersistentDuration
} from '../constants'
// import queryString from 'query-string'
import { getValue, isDefined, isEmpty } from 'utils'
import Session from './SessionService'

let SessionService = new Session()
let urlParamsOriginal, urlParamsFormatted
let paramsNav = {}

// this.buildRedirectLink = this.buildRedirectLink.bind(this)

/**
 * Service that handle url parameters for the entire app.
 */
const ParamsService = () => ({
  // Setters and getters
  setUrlParamsOriginal(params) {
    urlParamsOriginal = params
  },
  getUrlParamsOriginal() {
    return urlParamsOriginal
  },
  // setParamsNav(params) {
  //   paramsNav = params
  // },
  getParamsNav() {
    return paramsNav
  },
  // setUrlParamsFormatted(params) {
  //   urlParamsFormatted = params
  // },
  getUrlParamsFormatted() {
    return urlParamsFormatted
  },

  /**
   * Build navigation parameters to appear in the URL.
   *
   * It considers in order of importance:
   *    * URL parameters
   *    * Global cache parameters
   *
   * @param urlParams (object)
   * @param cache (object)
   * @return Object
   */
  buildNavigationParams(cache, urlParams = {}) {
    let mergedParams = {}

    // Get non-persistent params (if any).
    const nonPersistent = {}
    for (const [key, value] of Object.entries(cache)) {
      if (urlParamsNonPersistent.indexOf(key) > -1) {
        nonPersistent[key] = value
      }
    }

    // Get persistent params (if any).
    let persistent = {}
    for (const [key, value] of Object.entries(urlParamsPersistent)) {
      const globalParam = SessionService.getFromCache(key, value)

      // Exclude global empty params.
      if (globalParam !== value) {
        persistent[key] = globalParam
      }
    }

    // Merge nonPersistent & persistent params.
    mergedParams = { ...nonPersistent, ...persistent }

    // Merge cache params with urlParams (urlParams are more important).
    for (const [_key, _val] of Object.entries(urlParams)) {
      if ('' !== getValue(() => _val, '')) {
        mergedParams[_key] = _val
      }
    }

    // Exclude non-relevant params for navigation.
    let paramsNav = {}
    const nonRelevant = [
      'projectid',
      'id.presentation',
      'id.collection',
      'id.project',
      'id.bundle'
    ]
    for (const [key, value] of Object.entries(mergedParams)) {
      if (nonRelevant.indexOf(key) === -1) {
        paramsNav[key] = value
      }
    }

    // Replace synonym params.
    paramsNav = this.replaceSynonym(paramsNav, urlParamsSynonyms, mergedParams)

    return paramsNav
  },

  replaceSynonym(paramsNav, urlParamsSynonyms, mergedParams) {
    for (const [key, value] of Object.entries(urlParamsSynonyms)) {
      for (const [k, v] of Object.entries(mergedParams)) {
        if (value.indexOf(k) !== -1) {
          if (!isDefined(paramsNav[key])) {
            paramsNav[key] = v
          }
          delete paramsNav[k]
        }
      }
    }
    return paramsNav
  },

  /**
   * Format url params by considering synonyms.
   *
   * @param originalParams (object) - The original URL parameters
   * @param allowOthers (boolean) - Flag to allow other parameters not defined in the synonyms
   * @return Object - Formatted course params
   */
  formatUrlParams(originalParams, allowOthers = false) {
    const formatParams = {}

    for (const [key, value] of Object.entries(originalParams)) {
      if (urlParamsSynonyms.hasOwnProperty(key)) {
        formatParams[key] = getValue(() => value, '')
      } else {
        const newKey = this.getSynonymKey(key)
        if (isDefined(newKey)) {
          formatParams[newKey] = getValue(() => value, '')
        } else if (allowOthers) {
          formatParams[key] = getValue(() => value, '')
        }
      }
    }

    return formatParams
  },

  /**
   * Looks in the synonyms for the correct param name.
   *
   * @param paramName (string) - The parameter name
   * @return string - The synonym key
   */
  getSynonymKey(paramName) {
    let cp

    for (const [key, prop] of Object.entries(urlParamsSynonyms)) {
      if (prop.indexOf(paramName) > -1) {
        cp = key
        break
      }
    }

    return cp
  },

  /**
   * Get a value from the urlParamsOriginal object.
   *
   * @param id (string) - Identifier
   * @param def (any) - The default value if no value is found
   * @return any - The url param
   */
  getURLOriginalParam(id, def = null) {
    return getValue(() => urlParamsOriginal[id], def)
  },

  /**
   * Saves params to cache.
   *
   * @param urlParams (object) - URL Params with Object format
   * @return void
   */
  saveToCache(urlParams = {}) {
    urlParamsOriginal = { ...urlParams }

    // Instantiate the paramsNav object
    const cache = SessionService.getCacheComplete()
    paramsNav = this.buildNavigationParams(
      getValue(() => cache['global'], {}),
      urlParams
    )
    urlParamsFormatted = this.formatUrlParams(paramsNav)

    // Save relevant values to persistent cache – if values are not empty.
    for (const [key, value] of Object.entries(urlParamsPersistent)) {
      // eslint-disable-next-line no-loop-func
      if (!isEmpty(getValue(() => urlParamsFormatted[key], value))) {
        SessionService.saveToCache(
          key,
          // eslint-disable-next-line no-loop-func
          getValue(() => urlParamsFormatted[key], value)
        )
      }
    }

    // Save parameters to non-persistent cache (24 hrs)
    for (let [key, value] of Object.entries(urlParamsFormatted)) {
      if (urlParamsNonPersistent.indexOf(key) > -1) {
        SessionService.saveToCache(key, value, urlParamsNonPersistentDuration)
      }
    }

    // Url params have more priority.
    for (let [key, value] of Object.entries(urlParamsOriginal)) {
      if (urlParamsNonPersistent.indexOf(key) > -1) {
        SessionService.saveToCache(key, value, urlParamsNonPersistentDuration)
      }
    }

    // Check if EmailID is valid numeric value
    let _EmailID = getValue(() => urlParamsFormatted['EmailID'], null)
    if (
      !_EmailID ||
      _EmailID === '' ||
      !(!isNaN(parseFloat(_EmailID)) && isFinite(_EmailID))
    ) {
      SessionService.deleteFromCache('EmailID')
    }

    // Check if MemberID is valid numeric value
    let _MemberID = getValue(() => urlParamsFormatted['MemberID'], null)
    if (
      !_MemberID ||
      _MemberID === '' ||
      !(!isNaN(parseFloat(_MemberID)) && isFinite(_MemberID))
    ) {
      SessionService.deleteFromCache('MemberID')
    }
  }
})

export default ParamsService
