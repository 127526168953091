import StaticFileLayout from 'layouts/StaticFileLayout'

const Terms = () => {
  const title = 'Terms of Use'
  const filename = 'Terms'

  return <StaticFileLayout title={title} filename={filename} />
}

export default Terms
