import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingDots from 'components/LoadingDots'
import styles from './Interest.module.sass'
import InterestCard from './InterestCard'
import { actions } from 'modules/home/HomeReducer'
import * as HomeSelector from 'modules/home/HomeSelector'
import { getProfile, getHasPartyId } from 'modules/auth/AuthSelector'
import { getProfileInfo } from 'utils'

const { getSpecialtyInput, getLoadingInterest, getInterest } = HomeSelector

const Interest = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getLoadingInterest)
  const interest = useSelector(getInterest)

  // Specialty
  const specialtyInput = useSelector(getSpecialtyInput)
  useEffect(() => {
    let payload = {
      specialty: specialtyInput?.value2 || 'General Medicine',
      currentPage: 1
    }
    dispatch(actions.requestInterest(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, specialtyInput])

  const hasPartyId = useSelector(getHasPartyId)
  const profile = useSelector(getProfile)
  let [urlParams, setUrlParams] = useState(
    '?SpecialtyID=&AudienceID=aicme&PromoCode=796'
  )
  useEffect(() => {
    if (hasPartyId && profile) {
      const {
        partyId,
        partyEmailId,
        professionId,
        specialtyId
      } = getProfileInfo(profile)
      setUrlParams(
        `?MemberID=${partyId}&EmailID=${partyEmailId}&ProfessionID=${professionId}&SpecialtyID=${specialtyId}&AudienceID=aicme&PromoCode=796`
      )
    }
  }, [hasPartyId, profile])

  return (
    <div className={styles.container}>
      {isLoading && <LoadingDots />}
      {!isLoading &&
        interest?.map(({ id, title, teaser, extra_fields }) => (
          <InterestCard
            key={id}
            thumbnail={
              extra_fields.thumbnail ||
              extra_fields.icon_image ||
              'https://c.answersincme.com/assets/images/aicme-default-thumbnail.jpg'
            }
            credit={Number(extra_fields.dgme_credits).toFixed(1)}
            creditType={extra_fields.dgme_credit_type}
            title={title}
            description={teaser}
            url={extra_fields.article_url + urlParams}
            alt={extra_fields.human_url}
          />
        ))}
    </div>
  )
}

Interest.propTypes = {}

export default Interest
