import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingDots from 'components/LoadingDots'
import styles from './Article.module.sass'
import { actions } from 'modules/home/HomeReducer'
import * as HomeSelector from 'modules/home/HomeSelector'
import { padEnd } from 'lodash'
import { unserializeText, dateFormat } from 'utils'

const { getLoadingArticle, getArticle } = HomeSelector

const Article = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getLoadingArticle)
  const article = useSelector(getArticle)

  const startP = article.body?.startsWith('<p>')
  const startDiv = article.body?.startsWith('<div')
  const isHTML = startP || startDiv
  let textHTML = ''
  if (isHTML) {
    if (startP) {
      textHTML = article.body.substring(3).split('<p>')
      // textHTML = textHTML.toSpliced(
      //   2,
      //   0,
      //   '<p style="text-align:center;margin:11px 0 10px;line-height:20px;"><a class="article_link" href="#continue">click or scroll down to continue reading the article</a></p>'
      // )
      // textHTML = textHTML.toSpliced(
      //   3,
      //   0,
      //   '<div class="article_adbox"><img style="width:100%;" src="/img/aimatch_ad2.png" /></div>'
      // )
      // textHTML = textHTML.toSpliced(
      //   4,
      //   0,
      //   '<p style="text-align:center;margin:7px 0 11px;line-height:20px;"><a class="article_anchor" id="continue" style="height:0;">a</a><a class="article_link">article continues here</a></p>'
      // )
      textHTML =
        '<p class="article_p">' +
        textHTML
          .join('<p>')
          .replaceAll(
            '<p><p style="text-align:center;',
            '<p style="text-align:center;'
          )
          .replace(/<p>Reference:/i, '<p class="article_reference">REFERENCE:')
          .replace(/<p>Source:/i, '<p class="article_source">SOURCE:')
          .replaceAll('<p>', '<p class="article_p">')
    }
    if (startDiv) {
      const date =
        dateFormat(
          Number(padEnd(String(article.publication_date), 13, 0)),
          'MMM D, YYYY'
        ) + ' - '
      textHTML = article.body.substring(4).split('<div>')
      textHTML = date + '<div' + textHTML.join('<div>')
    }
  }

  useEffect(() => {
    dispatch(actions.setSearchTerm(''))
    dispatch(actions.setSearchPanel(false))

    let payload = {
      human_url: window.location.pathname.split('/article/')[1]
    }
    dispatch(actions.requestArticle(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  return (
    <div className={styles.container}>
      {!isLoading && (
        <>
          <h2 className={styles.h2}>{article.title}</h2>
          <p className={styles.author}>
            {(unserializeText(article.extra_fields?.authors) || [])
              .slice(0, 4)
              .map(author =>
                author.LastName?.length
                  ? `${author.ForeName} ${author.LastName}`
                  : `${author.ForeName}`
              )
              .join(', ')}
          </p>
          <p className={styles.citation}>{article.extra_fields?.citation}</p>
          {!isHTML && (
            <p className={styles.body}>
              {dateFormat(
                Number(padEnd(String(article.publication_date), 13, 0)),
                'MMM D, YYYY'
              )}
              {' - '}
              {article.body}
            </p>
          )}
          {isHTML && (
            <p
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: textHTML
              }}
            ></p>
          )}
          {!isHTML && (
            <p className="article_source">
              SOURCE: {article.extra_fields?.credit}
            </p>
          )}
          {startDiv && article.tags.journal?.name && (
            <p className="article_source">
              SOURCE: {article.tags.journal?.name}
            </p>
          )}
        </>
      )}
      {isLoading && <LoadingDots />}
    </div>
  )
}

Article.propTypes = {}

export default Article
