import { createReducer, createAction } from '@reduxjs/toolkit'
import { get } from 'lodash'
const actionPerformUnsubscribe = createAction('PERFORM_UNSUBSCRIBE')
const actionUnsubscribedSuccess = createAction('UNSUBSCRIBE_SUCCESS')
const actionUnsubscribedFailed = createAction('UNSUBSCRIBE_FAIL')
const actionGetObfuscatedEmail = createAction('GET_OBFUSCATED_EMAIL')
const actionGetObfuscatedEmailSuccess = createAction(
  'GET_OBFUSCATED_EMAIL_SUCCESS'
)
const actionGetObfuscatedEmailFailed = createAction('GET_OBFUSCATED_EMAIL_FAIL')

export const actions = {
  actionPerformUnsubscribe,
  actionUnsubscribedSuccess,
  actionUnsubscribedFailed,
  actionGetObfuscatedEmail,
  actionGetObfuscatedEmailSuccess,
  actionGetObfuscatedEmailFailed
}

const unsubscribeReducer = createReducer(
  {
    loading: false,
    unsubscribed: false,
    unsubscribedSuccess: false,
    unsubscribedFailed: false,
    obfuscatedEmail: '',
    getObfuscatedEmailSuccess: false,
    getObfuscatedEmailFailed: false,
    authToken: ''
  },
  {
    [actionPerformUnsubscribe]: state => {
      state.loading = true
      state.unsubscribedSuccess = false
      state.unsubscribedFailed = false
    },
    [actionUnsubscribedSuccess]: state => {
      state.loading = false
      state.unsubscribedSuccess = true
      state.unsubscribedFailed = false
    },
    [actionUnsubscribedFailed]: state => {
      state.loading = false
      state.unsubscribedSuccess = false
      state.unsubscribedFailed = true
    },
    [actionGetObfuscatedEmail]: state => {
      state.loading = true
      state.obfuscatedEmail = ''
      state.getObfuscatedEmailSuccess = false
      state.getObfuscatedEmailFailed = false
    },
    [actionGetObfuscatedEmailSuccess]: (state, { payload }) => {
      state.loading = false
      state.obfuscatedEmail = payload?.obfuscatedEmail || ''
      state.getObfuscatedEmailSuccess = true
      state.getObfuscatedEmailFailed = false
      state.authToken = get(payload, 'authToken', '')
    },
    [actionGetObfuscatedEmailFailed]: state => {
      state.loading = false
      state.obfuscatedEmail = ''
      state.getObfuscatedEmailSuccess = false
      state.getObfuscatedEmailFailed = true
    }
  }
)

export default unsubscribeReducer
