import styles from './LoadingDots.module.sass'

const LoadingDots = () => {
  return (
    <div className={styles.stage}>
      <div className={styles.dot}></div>
    </div>
  )
}

export default LoadingDots
