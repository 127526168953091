import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import MainLayout from 'layouts/MainLayout'
import Rail from 'components/Rail'
import CreditEarn from './CreditEarn'
import CreditList from './CreditList'
import SearchResult from 'components/SearchResult'
import SuggestedProgram from './SuggestedProgram'
import * as creditSelector from './CMETrackerSelector'
import * as AuthSelector from 'modules/auth/AuthSelector'
import { requestNemlToken } from 'modules/auth/AuthReducer'
import { getSearchTerm } from 'modules/home/HomeSelector'

import stylesRC from 'components/RailCenter.module.sass'
import stylesRR from 'components/RailRight.module.sass'
import config from 'site.config'

const {
  configuration: { envVars }
} = config

const { getNemlToken, getProfile, getIsLogin } = AuthSelector
const { getLoading } = creditSelector

export const getProfileInfo = profile => {
  const partyId = get(profile, 'info.user.partyId', '')
  const partyEmailId = get(profile, 'info.user.partyEmailId', '')
  return { partyId, partyEmailId }
}

const CMETracker = () => {
  const dispatch = useDispatch()

  const searchTerm = useSelector(getSearchTerm)
  const isUserLoggedIn = useSelector(getIsLogin)
  const isCertListLoading = useSelector(getLoading)

  const nemlToken = useSelector(getNemlToken)
  const profile = useSelector(getProfile)

  useEffect(() => {
    if (!nemlToken && profile) {
      const siteId = parseInt(
        envVars?.REACT_APP_PHNX_SITEID ?? process.env.REACT_APP_PHNX_SITEID
      )
      const { partyId, partyEmailId } = getProfileInfo(profile)
      dispatch(
        requestNemlToken({
          siteId: siteId,
          partyId: partyId,
          owner: partyId,
          partyEmailId: partyEmailId
        })
      )
    }
  }, [dispatch, nemlToken, profile])

  const [year, setYear] = useState({
    label: 'All',
    value: 'all'
  })
  const handleSelectYear = selectedYear => {
    setYear(selectedYear)
  }

  return (
    <MainLayout>
      {!searchTerm?.length && (
        <Rail styles={stylesRC}>
          <CreditEarn
            nemlToken={nemlToken}
            shouldLoadData={isUserLoggedIn}
            onSelectYear={handleSelectYear}
          />
          <CreditList
            loading={isCertListLoading}
            nemlToken={nemlToken}
            shouldLoadData={isUserLoggedIn}
            year={year}
          />
        </Rail>
      )}
      {searchTerm?.length > 0 && (
        <Rail styles={stylesRC}>
          <SearchResult />
        </Rail>
      )}
      <Rail styles={stylesRR}>
        <SuggestedProgram shouldLoadData={isUserLoggedIn} />
      </Rail>
    </MainLayout>
  )
}

export default CMETracker
