const DeviceIdService = () => ({
  initialize({ deviceIdEndpoint = '' }) {
    return new Promise(resolve => {
      loadScript(deviceIdEndpoint, e => {
        if (e) {
          console.error(`DEVICE ID ${e}`)
        } else {
          resolve(true)
        }
      })
    })
  },
  async getDeviceId({
    useCache = true,
    timeout = 10000,
    onlineId = '',
    idk = '',
    tenantId = '',
    serviceId = '',
    ruleset = '',
    rulesetAnon = '',
    onlineIdType = '',
    initOverride = false,
    apiRvIdURL = '',
    rvidURL = ''
  }) {
    const config = !initOverride && {
      useCache: useCache,
      timeout: timeout,
      onlineId: onlineId,
      apiRvIdURL,
      rvidURL,
      version: 2,
      profile: {
        idk: idk,
        tenantId: tenantId,
        serviceId: serviceId,
        ruleset: ruleset,
        rulesetAnon: rulesetAnon,
        onlineIdType: onlineIdType
      }
    }
    return await getDeviceID(config).then(r => r)
  }
})
export default DeviceIdService

export const getDeviceID = config => {
  return new Promise(resolve => {
    window.frames['NEML'].Device.init(
      config,
      deviceId => {
        resolve(deviceId)
      },
      err => {
        if (err.section === 'post') {
          if (err.type === 'then') {
            if (err.result.status === 400 || err.result.status === 502) {
              throw new Error(err.result.data)
            }
          }
          if (err.type === 'catch') {
            throw new Error(err.result.message)
          }
        }
      }
    )
  })
}

export const defaultValues = (opts, cb) => {
  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  opts = opts || {}
  cb = cb || function() {}

  return { opts, cb }
}
export const loadScript = (src, opts, cb) => {
  const setAttributes = (script, attrs) => {
    for (const attr in attrs) {
      script.setAttribute(attr, attrs[attr])
    }
  }
  const stdOnEnd = (script, cb) => {
    script.onload = function() {
      this.onerror = this.onload = null
      cb(null, script)
    }
    script.onerror = function() {
      // this.onload = null here is necessary
      // because even IE9 works not like others
      this.onerror = this.onload = null
      cb(new Error('Failed to load ' + this.src), script)
    }
  }
  const ieOnEnd = (script, cb) => {
    script.onreadystatechange = function() {
      if (this.readyState !== 'complete' && this.readyState !== 'loaded') return
      this.onreadystatechange = null
      cb(null, script) // there is no way to catch loading errors in IE8
    }
  }
  const head = document.head || document.getElementsByTagName('head')[0]
  const script = document.createElement('script')

  let values = defaultValues(opts, cb)
  opts = values.opts
  cb = values.cb

  script.type = opts.type || 'text/javascript'
  script.async = 'async' in opts ? !!opts.async : true
  script.src = src

  if (opts.attrs) {
    setAttributes(script, opts.attrs)
  }

  if (opts.text) {
    script.text = '' + opts.text
  }

  const onend = 'onload' in script ? stdOnEnd : ieOnEnd
  onend(script, cb)

  // some good legacy browsers (firefox) fail the 'in' detection above
  // so as a fallback we always set onload
  // old IE will ignore this and new IE will set onload
  if (!script.onload) {
    stdOnEnd(script, cb)
  }

  head.appendChild(script)
}
