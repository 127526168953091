import Cookies from 'js-cookie'

const CookieService = () => ({
  get(key) {
    return Cookies.get(key)
  },
  set(key, value, extra = null) {
    return Cookies.set(key, value, extra)
  },
  remove(key) {
    return Cookies.remove(key)
  }
})

export default CookieService
