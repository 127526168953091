import { createContainer, asValue, Lifetime, InjectionMode } from 'awilix'

import Services from 'services/Services'
import ClarityService from 'services/ClarityService'
import ConfigService from 'services/ConfigService'
import ConfigParser from 'services/ConfigParser'
import CookieService from 'services/CookieService'
import ParamsService from 'services/ParamsService'
import TrackingService from 'services/TrackingService'
import SessionService from 'services/SessionService'
import APIService from 'services/APIService'
import AuthService from 'services/AuthService'
import DeviceIdService from 'services/DeviceIdService'

const services = {
  ClarityService,
  ConfigService,
  ConfigParser,
  CookieService,
  ParamsService,
  TrackingService,
  SessionService,
  APIService,
  AuthService,
  DeviceIdService
}

let serviceList = {}
Services.forEach(service => {
  serviceList[service.name] = service.registerAs(
    services[service.name],
    service.registerAs.name === 'asClass'
      ? {
          lifetime: Lifetime.SINGLETON
        }
      : null
  )
})
const serviceContainer = () => {
  const container = createContainer({ injectionMode: InjectionMode.CLASSIC })
  container.register(serviceList)
  // This provides a way to access dispatch and the store inside
  // connected services
  container.registerStore = store => {
    container.register({
      dispatch: asValue(action => store.dispatch(action)),
      getState: asValue(() => store.getState())
    })
  }
  if (process.env.NODE_ENV !== 'production') {
    window.di = container
  }
  return container
}

export default serviceContainer
