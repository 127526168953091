import axios from 'axios'
import config from 'site.config.json'

const {
  configuration: { assetPath }
} = config

class ConfigService {
  async request() {
    let url =
      (process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_NEML_API
        : process.env.REACT_APP_CDN_URL) + `/${assetPath}/content.json`
    const axiosOptions = {
      url,
      method: 'GET'
    }

    const response = await axios(axiosOptions)
    return response.data
  }
}

export default ConfigService
