import PropTypes from 'prop-types'

const Rail = ({ styles, width, height, position, children }) => {
  return (
    <div className={styles.container} style={{ width, height, position }}>
      {children}
    </div>
  )
}

Rail.propTypes = {
  styles: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.node
}

export default Rail
