import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SingleLayout from 'layouts/SingleLayout'
import * as authSelector from 'modules/auth/AuthSelector'
import config from 'site.config'
import styles from './Unsubscribe.module.sass'
import { actions } from './UnsubscribeReducer'
import {
  getUnsubscribedSuccess,
  getUnsubscribedFailed,
  getObfuscatedEmail,
  getObfuscatedEmailSuccess,
  getObfuscatedEmailFailed,
  getLoading,
  getAuthToken
} from './UnsubscribeSelector'
import { Link } from 'react-router-dom'
const {
  configuration: { envVars }
} = config
const recaptchaKey =
  envVars?.REACT_APP_PHN_RECAPTCHA_KEY ||
  process.env.REACT_APP_PHN_RECAPTCHA_KEY
const idhSiteId =
  envVars?.REACT_APP_IDH_SITEID || process.env.REACT_APP_IDH_SITEID
const idhBrandId =
  envVars?.REACT_APP_IDH_BRANDID || process.env.REACT_APP_IDH_BRANDID
const idhCampaignId =
  envVars?.REACT_APP_IDH_CAMPAIGNID || process.env.REACT_APP_IDH_CAMPAIGNID

const { getIsLogin } = authSelector

const Unsubscribe = () => {
  const isLoading = useSelector(getLoading)
  const obfuscated_email = useSelector(getObfuscatedEmail)
  const getEmailSuccess = useSelector(getObfuscatedEmailSuccess)
  const getEmailFailed = useSelector(getObfuscatedEmailFailed)
  const unsubscribedSuccess = useSelector(getUnsubscribedSuccess)
  const unsubscribedFailed = useSelector(getUnsubscribedFailed)
  const authToken = useSelector(getAuthToken)
  const dispatch = useDispatch()
  const isUserLoggedIn = useSelector(getIsLogin)

  useEffect(() => {
    dispatch(actions.actionGetObfuscatedEmail({ isLoggedIn: isUserLoggedIn }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    const recaptchaId = 'recaptcha-key'

    const loadScriptByURL = (id, url) => {
      const scriptExist = document.getElementById(id)
      if (!scriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        document.body.appendChild(script)
      }
    }

    // load the script by passing the URL
    try {
      loadScriptByURL(
        recaptchaId,
        `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`
      )

      return () => {
        document.getElementById(recaptchaId)?.remove()
      }
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitUnsubscribe = token => {
    try {
      let data = {}
      if (token) {
        data = {
          authToken,
          payload: {
            idhSiteId,
            idhBrandId,
            idhCampaignId
          }
        }
        dispatch(actions.actionPerformUnsubscribe(data))
      } else {
        dispatch(actions.actionUnsubscribedFailed())
      }
    } catch (e) {}
  }

  return (
    <SingleLayout isLoading={isLoading}>
      <div className={styles.box}>
        <h3>We’re sorry you’re leaving us.</h3>
        {
          <>
            {!unsubscribedSuccess && !unsubscribedFailed && !getEmailFailed && (
              <div>
                <p>
                  Please confirm that you are unsubscribing {obfuscated_email}{' '}
                  from Answers in CME emails.
                </p>
                <button
                  className={styles.btn}
                  type="button"
                  onClick={() => {
                    window.grecaptcha.ready(() => {
                      try {
                        window.grecaptcha
                          .execute(recaptchaKey, { action: 'unsubscribe' })
                          .then(token => submitUnsubscribe(token))
                      } catch (e) {}
                    })
                  }}
                  disabled={
                    isLoading ||
                    getEmailFailed ||
                    !getEmailSuccess ||
                    unsubscribedSuccess
                  }
                >
                  Submit
                </button>
              </div>
            )}
            {unsubscribedSuccess && (
              <div className={styles.submittedSuccessBox}>
                Thank you. Your email address has been removed from Answers in
                CME mailing list.
                <br />
                To re-subscribe, or if you have any further questions, please
                contact us&nbsp;
                <Link to="/contactus" style={{ color: 'inherit' }}>
                  here
                </Link>
                .
              </div>
            )}
            {(unsubscribedFailed || getEmailFailed) && (
              <div className={styles.submittedFailedBox}>
                We are unable to automatically unsubscribe you. Please contact
                us&nbsp;
                <Link to="/contactus" style={{ color: 'inherit' }}>
                  here
                </Link>
                &nbsp;to unsubscribe.
              </div>
            )}
          </>
        }
      </div>
    </SingleLayout>
  )
}

export default Unsubscribe
