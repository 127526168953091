import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AuthWithEmail.module.sass'
import { actions } from './AuthReducer'
import * as AuthSelector from './AuthSelector'
import cx from 'classnames'
import { CircularProgress } from 'components/imported/Progress'

const { loginWithEmailRequest } = actions
const { getLoading } = AuthSelector

const AuthWithEmail = () => {
  const dispatch = useDispatch()
  const ref = useRef()
  const isLoading = useSelector(getLoading)

  return (
    <div className={isLoading ? styles.loadingContainer : styles.container}>
      <div className={styles.loginInstrucText}>Login with Your Email</div>
      <form
        onSubmit={e => {
          e.preventDefault()
          if (ref.current.value) {
            dispatch(loginWithEmailRequest({ email: ref.current.value }))
          }
        }}
      >
        <div style={{ height: '20px' }}></div>
        <span>
          <strong>Email</strong>
        </span>
        <input
          name="email"
          type="email"
          alt="email"
          required
          className={styles.loginInput}
          ref={ref}
          disabled={isLoading}
          // onInvalid={e => e.target.setCustomValidity('invalid Email')}
        />
        {!isLoading && (
          <input
            type="submit"
            className={cx(styles.loginBtn)}
            disabled={isLoading}
            value="Enter"
          ></input>
        )}
        {isLoading && (
          <CircularProgress
            strokeColor="#3b78c3"
            strokeBackgroundColor="#CECECE"
            fillColor="transparent"
            className={styles.circularProgress}
            size={38}
          />
        )}
      </form>
      <div style={{ height: '20px' }}></div>
      <div className={styles.loginCaptionText}>
        A log-in link will be sent to the email provided above.
      </div>
    </div>
  )
}
export default AuthWithEmail
